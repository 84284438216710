export const hoursOptions = [
    { heureLabel: "0h", heureNombre: 0 },
    { heureLabel: "1h", heureNombre: 1 },
    { heureLabel: "2h", heureNombre: 2 },
    { heureLabel: "3h", heureNombre: 3 },
    { heureLabel: "4h", heureNombre: 4 },
    { heureLabel: "5h", heureNombre: 5 },
    { heureLabel: "6h", heureNombre: 6 },
    { heureLabel: "7h", heureNombre: 7 },
    { heureLabel: "8h", heureNombre: 8 },
    { heureLabel: "9h", heureNombre: 9 },
    { heureLabel: "10h", heureNombre: 10 },
    { heureLabel: "11h", heureNombre: 11 },
    { heureLabel: "12h", heureNombre: 12 },
    { heureLabel: "13h", heureNombre: 13 },
    { heureLabel: "14h", heureNombre: 14 },
    { heureLabel: "15h", heureNombre: 15 },
    { heureLabel: "16h", heureNombre: 16 },
    { heureLabel: "17h", heureNombre: 17 },
    { heureLabel: "18h", heureNombre: 18 },
    { heureLabel: "19h", heureNombre: 19 },
    { heureLabel: "20h", heureNombre: 20 },
    { heureLabel: "21h", heureNombre: 21 },
    { heureLabel: "22h", heureNombre: 22 },
    { heureLabel: "23h", heureNombre: 23 }
]