import { Fragment } from "react";

export const ConsoCustomTooltip = ({ active, payload, datakey, noTitle }) => {
  if (active && payload && payload.length) {
    return (
      <div className="conso--tooltip__container">
        {!noTitle ? <p className="conso--tooltip__title">{payload[0]?.payload?.[`${datakey}`]}</p> : ""}
        <ul className="conso--tooltip__list">
          {payload.map(((element, index) => {
            if (element.value) {
              return (
                <Fragment key={`tooltip-data-${index}`}>
                  <li className="conso--tooltip__listitem" style={{ color: element.color }}>{element.name} : {element.payload?.isCamembert ? <><br /><br /></> : ""}{new Intl.NumberFormat('fr-FR').format(element.value)} {element.unit ? element.unit : element.payload?.unit}</li>
                  {element.payload?.percent ? <li className="conso--tooltip__listitem" style={{ color: element.color }}>{new Intl.NumberFormat('fr-FR', { style: 'percent', maximumFractionDigits: 2 }).format(element.payload.percent)}</li> : ""}
                </Fragment>
              )
            }
            return null
          }))}
        </ul>
      </div>
    )
  }

  return null;
};