import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontFamily: "Plus Jakarta Sans, sans-serif",
    color: "#18181B",
    lineHeight: "12px",
    border: 0,
    [`&.${tableCellClasses.head}`]: {
        fontSize: "14px",
        fontWeight: "700",
        padding: "18px 10px",
        lineHeight: "14px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontWeight: "400",
        fontSize: "12px",
        padding: "5px 10px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "200px"
    },
}))