import { useCallback, useEffect } from "react"
import SideBar from "../../Components/SideBar/SideBar"
import ImportFile from "./ImportFile"
import Open from '../../img/icons/open.svg'
import Button from "../../Components/Button"
import { useState } from "react"
import InformationPopup from "../../Components/InformationPopup"
import { validEmail } from '../../Utils/Regex/vallidEmail'
import { validateCaptcha } from 'react-simple-captcha'
import axios from 'axios'
import { Link } from "react-router-dom"
import Download from '../../img/icons/download.svg'

const apiUrl = process.env.REACT_APP_API_URL

function Resources() {
    const [showPopup, setShowPopup] = useState(false)
    const [newsletterEmail, setNewsletterEmail] = useState("")
    const [name, setName] = useState("")
    const [isLoadingPopup, setIsLoadingPopup] = useState(false)
    const [popupType, setPopupType] = useState("newsletter-subscription")
    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessagePopup, setShowErrorMessagePopup] = useState(false)
    const [newsletters, setNewsletters] = useState([])

    const downloadFile = (name) => {
        const href = `https://www.wattunity.com/documents/${name}`

        const link = document.createElement('a')
        link.href = href

        link.setAttribute('download', name)
        document.body.appendChild(link)
        window.open(link)
        link.remove()
        window.URL.revokeObjectURL(href)
    }

    const handleNewsletterSubscription = async () => {
        let userCaptcha = document.getElementById("captcha_input").value

        setErrorMessage(false)
        setIsLoadingPopup(false)
        setShowErrorMessagePopup(false)

        if (!validEmail.test(newsletterEmail)) {
            setErrorMessage("Veuillez renseigner une adresse email valide")
            setShowErrorMessagePopup(true)
        }
        else if (!validateCaptcha(userCaptcha, false)) {
            setErrorMessage("Les caractères tapés ne correspondent pas à l'image")
            setShowErrorMessagePopup(true)
        }
        else {
            setIsLoadingPopup(true)
            const user = JSON.stringify({
                "nom": `${name}`,
                "email": `${newsletterEmail}`,
            })
            try {
                await axios.post(
                    `${apiUrl}/newsletter/subscribe`,
                    user,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: true,
                    })
                setPopupType("newsletter-confirmation")
            }
            catch (error) {
                if (error?.response?.data?.message) setErrorMessage(error.response.data.message)
                setIsLoadingPopup(false)
                setShowErrorMessagePopup(true)
            }
        }
    }

    const fetchFluxRSS = useCallback(() => {
        fetch(`https://www.wattvalue.fr/category/newssletter-energie/feed/`)
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then(data => {
                const array = []
                const items = data.querySelectorAll("item");
                items.forEach(item => {
                    array.push({ title: item.querySelector("title").innerHTML, link: item.querySelector("link").innerHTML })
                })
                setNewsletters(array)
            })
    }, [])

    useEffect(() => {
        fetchFluxRSS()
    }, [fetchFluxRSS])

    return (
        <>
            <SideBar active="Ressources" />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Ressources</h3>
                <div className="privatepages--separator"></div>
                <div className="resources--blocks__container">
                    <div className="resources--column">
                        <div className="resources--block">
                            <span className="resources--block__title">Modèles</span>
                            <ImportFile
                                label="Modèle lettre de résiliation du contrat de fourniture de gaz"
                                placeholder="Télécharger le modèle"
                                name="Modele-Lettre-resiliation-Contrat-Offre-de-Marche-Reconductionetacite-RA31-10-2014.docx"
                                handleClick={downloadFile}
                            />
                            <ImportFile
                                label="Modèle lettre de résiliation du contrat électicité"
                                placeholder="Télécharger le modèle"
                                name="Modele-Lettre-Resiliation-Contrat-Fourniture-Electricite.docx"
                                handleClick={downloadFile}
                            />
                        </div>
                        <div className="resources--block">
                            <span className="resources--block__title">Documents utiles</span>
                            <ImportFile
                                label="Information client - équilibrage mensuel Garanties Origine"
                                placeholder="Télécharger le fichier"
                                name="Information-client-equilibrage-mensuel-08012021.pdf"
                                handleClick={downloadFile}
                            />
                            <ImportFile
                                label="Comprendre le commerce du gaz naturel et l'intérêt d'un groupement d'achat"
                                placeholder="Télécharger le fichier"
                                name="Comprendre_commerce_gaz_naturel_et_interet_groupement_achat.pdf"
                                handleClick={downloadFile}
                            />
                            <ImportFile
                                label="Explications détaillées sur le commerce du gaz naturel"
                                placeholder="Télécharger le fichier"
                                name="Explications_detaillees_commerce_gaz_naturel.pdf"
                                handleClick={downloadFile}
                            />
                            <ImportFile
                                label="Ce qu'il faut savoir sur le groupement WattValue"
                                placeholder="Télécharger le fichier"
                                name="Fonctionnement_groupement_wattunity.pdf"
                                handleClick={downloadFile}
                            />
                            <ImportFile
                                label="Revue décryptage de la CRE sur la fin des TRV"
                                placeholder="Télécharger le fichier"
                                name="Revue-Decryptages-de-la-CRE-sur-la-fin-des-TRV.pdf"
                                handleClick={downloadFile}
                            />
                            <ImportFile
                                label="Guide d'utilisation : vérification des factures"
                                placeholder="Télécharger le fichier"
                                name="201012-Guide-verification-factures-elec.pdf"
                                handleClick={downloadFile}
                            />
                            <span className="homepage--popup__inputlabel">Service Courbes de Charge de WattValue</span>
                            <div className="resources--input" onClick={() => window.open('/Service_Courbe_de_charge-v2.pdf')}>
                                Télécharger le fichier
                                <img
                                    src={Download}
                                    alt="Télécharger"
                                    width={15}
                                    height={15}
                                    className="resources--input__icon"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="resources--column">
                        {newsletters.length > 0 && <div className="resources--block">
                            <span className="resources--block__title">Newsletters</span>
                            {newsletters.map((news, index) => {
                                if (index < 4) {
                                    return (
                                        <Link key={`news-list-${index}`} to={news.link} target="_blank">
                                            <span className="resources--block__link">{news.title} <img src={Open} width={12} height={12} alt="Ouvrir" />
                                            </span>
                                        </Link>
                                    )
                                }
                                return null
                            })
                            }
                            <Link to='https://us5.campaign-archive.com/home/?u=deac780a65411d335cfb430f1&id=60ce576aaa' target="_blank">
                                <span className="resources--block__newsletters">{`Voir toutes les newsletters >`}</span>
                            </Link>
                        </div>}
                        <div className="resources--block">
                            <span className="resources--block__title">Abonnements</span>
                            <span className="homepage--popup__inputlabel">Vous voulez être au courant de tout notre actualité ? Rien de plus simple, cliquez ci-dessous pour vous inscrire à notre newsletter&nbsp;!</span>
                            <Button
                                cta="S'abonner"
                                additionnalClassName="global--button__margintop8"
                                handleSubmit={() => {
                                    setPopupType("newsletter-subscription")
                                    setIsLoadingPopup(false)
                                    setName("")
                                    setNewsletterEmail("")
                                    setShowPopup(true)
                                }}
                            />
                        </div>

                    </div>
                    <div className="resources--column">
                        <div className="resources--block">
                            <span className="resources--block__title">Site utiles</span>
                            <span className="resources--block__link" onClick={() => window.open('https://www.wattvalue.fr/')}>WattValue <img src={Open} width={12} height={12} alt="Ouvrir" /></span>
                            <span className="resources--block__link" onClick={() => window.open('http://www.cre.fr/')}>CRE <img src={Open} width={12} height={12} alt="Ouvrir" /></span>
                            <span className="resources--block__link" onClick={() => window.open('http://www.energie-info.fr/')}>Energie-info <img src={Open} width={12} height={12} alt="Ouvrir" /></span>
                        </div>

                    </div>
                </div>
            </div>
            {showPopup &&
                <InformationPopup
                    type={popupType}
                    closeInformationPopup={() => setShowPopup(false)}
                    email={newsletterEmail}
                    setEmail={setNewsletterEmail}
                    name={name}
                    setName={setName}
                    showErrorMessage={showErrorMessagePopup}
                    errorMessage={errorMessage}
                    handleNewsletterSubscription={handleNewsletterSubscription}
                    setShowErrorMessage={setShowErrorMessagePopup}
                    isLoading={isLoadingPopup}
                />}
        </>
    )
}

export default Resources