import SideBar from "../../../Components/SideBar/SideBar"
import { SearchBar } from "../../../Components/SearchBar"
import DownloadXlsx from "../../../Utils/TableUtils/DownloadXlsx"
import { useState, useEffect, useCallback } from "react"
import axios from "axios"
import SepaTable from "./SepaTable"
import { createDataSepa } from "../../../Utils/TableUtils/createData"
import { removeAccents } from "../../../Utils/Regex/removeAccents"

const apiUrl = process.env.REACT_APP_API_URL

function Sepa() {
    const [sepaMandates, setSepaMandates] = useState([])

    const [signedSepaMandates, setSignedSepaMandates] = useState([])
    const [filteredSignedSepaMandates, setFilteredSignedSepaMandates] = useState([])

    const [pendingSepaMandates, setPendingSepaMandates] = useState([])
    const [filteredPendingSepaMandates, setFilteredPendingSepaMandates] = useState([])
    const [page, setPage] = useState(0)
    const [pageSigned, setPageSigned] = useState(0)

    const [isDataLoading, setIsDataLoading] = useState(false)

    const [searchValueACompleter, setSearchValueACompleter] = useState("")
    const [searchValueSigne, setSearchValueSigne] = useState("")

    const fetchSepaMandates = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/Mandat/Sepa`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                const formatedSepa = response.data.map(element => createDataSepa(element.raisonSociale, element.sites.join(' '), `${element.adresse ? element.adresse : ""} ${element.codePostal ? element.codePostal : ""} ${element.ville ? element.ville : ""}`, element.ribFichier, element.mandatFichier, element.dateCreation, element.statut, element.ribID, element.mandatID, element.sepaID))
                setSepaMandates(formatedSepa)

                const signedSepa = formatedSepa.filter((element) => element.statut === "Signé")
                setSignedSepaMandates(signedSepa)
                setFilteredSignedSepaMandates(signedSepa)

                const pendingSepa = formatedSepa.filter((element) => element.statut === "A compléter / signer")
                setPendingSepaMandates(pendingSepa)
                setFilteredPendingSepaMandates(pendingSepa)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [])

    useEffect(() => {
        fetchSepaMandates()
    }, [fetchSepaMandates])


    const filterSepaMandates = (query, table) => {
        const keys = ['raisonSociale', 'sites', 'adresse', 'ribFichier', 'mandatFichier', 'dateCreation']
        if (!query || query === "") {
            if (table === "Signé") setFilteredSignedSepaMandates(signedSepaMandates)
            if (table === "A compléter / signer") setFilteredPendingSepaMandates(pendingSepaMandates)
        }
        else if (table === "A compléter / signer") {
            const results = pendingSepaMandates.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredPendingSepaMandates(results)
            setPage(0)
        }
        else {
            const results = signedSepaMandates.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredSignedSepaMandates(results)
            setPageSigned(0)
        }
    }

    const getDocument = (documentID, ribFichier) => {
        axios.get(`${apiUrl}/document/${documentID}`,
            {
                withCredentials: true,
                ContentType: 'application/json',
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', ribFichier)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    return (
        <>
            <SideBar
                active="Sepa"
                openAccount />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Mes mandats SEPA</h3>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {sepaMandates.length === 0 ?
                                <span>Aucun mandat trouvé</span>
                                :
                                <>
                                    {pendingSepaMandates.length > 0 &&
                                        <>
                                            <h5 className="sepa--table__title">A compléter / signer</h5>
                                            <div className="table--searchbarcontainer sepa--table__searchbarcontainer">
                                                <SearchBar
                                                    id="invoicestable"
                                                    filterData={(query) => filterSepaMandates(query, "A compléter / signer")}
                                                    setValue={setSearchValueACompleter}
                                                    value={searchValueACompleter}
                                                    additionnalFormClassName="searchbar--form table--form"
                                                />
                                            </div>
                                        </>
                                    }
                                    {filteredPendingSepaMandates.length > 0 &&
                                        <>
                                            <SepaTable
                                                getDocument={getDocument}
                                                rows={filteredPendingSepaMandates}
                                                page={page}
                                                setPage={setPage}
                                            />
                                        </>
                                    }
                                    {(filteredPendingSepaMandates.length === 0 && pendingSepaMandates.length > 0) &&
                                        <span>Aucun mandat à compléter trouvé</span>
                                    }
                                    {signedSepaMandates.length > 0 && <h5 className={`sepa--table__title ${pendingSepaMandates.length > 0 ? "sepa--table__searchbarcontainerbottom" : ""}`}>Signé{filteredSignedSepaMandates.length > 1 ? "s" : ""}</h5>}
                                    <div className={`table--searchbarcontainer sepa--table__searchbarcontainer`}>
                                        {signedSepaMandates.length > 0 &&
                                            <>
                                                <SearchBar
                                                    id="invoicestable"
                                                    filterData={(query) => filterSepaMandates(query, "Signé")}
                                                    setValue={setSearchValueSigne}
                                                    value={searchValueSigne}
                                                    additionnalFormClassName="searchbar--form table--form"
                                                />
                                            </>
                                        }
                                        {filteredSignedSepaMandates.length > 0 &&
                                            <DownloadXlsx
                                                data={filteredSignedSepaMandates}
                                                fileName="mandats_sepa.xlsx"
                                                headRow={[{ "A1": 'Raison sociale', "B1": 'Site(s) concerné(s)', "C1": 'Adresse', "D1": 'RIB', "E1": 'Mandat SEPA', "F1": 'Date de création', "E1": "Statut" }]} />}
                                    </div>
                                    {filteredSignedSepaMandates.length > 0 &&
                                        <>
                                            <SepaTable
                                                getDocument={getDocument}
                                                rows={filteredSignedSepaMandates}
                                                page={pageSigned}
                                                setPage={setPageSigned}
                                            />
                                        </>
                                    }
                                    {(filteredSignedSepaMandates.length === 0 && signedSepaMandates.length > 0) &&
                                        <span> Aucun mandat signé trouvé</span>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            </div >
        </>
    )
}

export default Sepa;