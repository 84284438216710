import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(4n-1)': {
        backgroundColor: "#F5F5F5",
    },
    '&:nth-of-type(4n)': {
        backgroundColor: "#F5F5F5",
    },
    '&:nth-of-type(4n+1)': {
        backgroundColor: "#EFEFEF",
    },
    ':nth-of-type(4n+2)': {
        backgroundColor: "#EFEFEF"
    },
}));