import Button from '../Button'
import { useRef } from 'react'
import useOnClickOutside from "../../Utils/ModalUtils/useOnClickOutside"
import { Link } from 'react-router-dom'

import DashboardIcon from '../../img/icons/dashboard.svg'
import ResourcesIcon from '../../img/icons/resources.svg'
import SitesIcon from '../../img/icons/sites.svg'
import AppelsdOffreIcon from '../../img/icons/appeldoffre.svg'
import ContractsIcon from '../../img/icons/contracts.svg'
import ConsoIcon from '../../img/icons/conso.svg'
import VerifyIcon from '../../img/icons/verifyinvoices.svg'
import GreenIcon from '../../img/icons/greenenergy.svg'
import ProfilIcon from '../../img/icons/profil.svg'
import TeamIcon from '../../img/icons/team.svg'
import MandatesIcon from '../../img/icons/mandates.svg'
import SepaIcon from '../../img/icons/sepa.svg'
import InvoiceIcon from '../../img/icons/invoices.svg'
import BudgetIcon from '../../img/icons/budget.svg'
import TurpeIcon from '../../img/icons/turpe.png'

function MobileSideBar(props) {
    const ref = useRef()

    useOnClickOutside(ref, () => props.setShowMobileMenu(false), "close")

    return (
        <>
            <div className="sidebar--container sidebar--containermobile" ref={ref}>
                <Button
                    cta="Ajouter un nouveau site"
                    additionnalClassName="sidebar--addbutton"
                    link='/add-sites-1?type=elec'
                    withPlus />
                <ul>
                    <Link to="/dashboard"><li className="sidebar--listelement">
                        <img
                            src={DashboardIcon}
                            alt="Dashboard Icon"
                            className="sidebar--listicon"
                        />
                        <span className="sidebar--elementtitle" >
                            Tableau de bord</span>
                    </li></Link>
                    <Link to="/ressources"><li className="sidebar--listelement">
                        <img src={ResourcesIcon} alt="Ressources Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Ressources</span>
                    </li></Link>
                </ul>
                <div className="sidebar--listtitle__container">
                    <span className="sidebar--listtitle">GAZ</span>
                </div>
                <ul>
                    <Link to="/gaz-sites"><li className="sidebar--listelement">
                        <img src={SitesIcon} alt="Sites gaz Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes sites</span>
                    </li></Link>
                    <Link to="/appels-offre-gaz"><li className="sidebar--listelement">
                        <img src={AppelsdOffreIcon} alt="Appel d'offres gaz Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes appels d'offres</span>
                    </li></Link>
                    <Link to="/contrats-gaz"><li className="sidebar--listelement">
                        <img src={ContractsIcon} alt="Contrats gaz Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes contrats</span>
                    </li></Link>
                </ul>
                <div className="sidebar--listtitle__container">
                    <span className="sidebar--listtitle">ÉLECTRICITÉ</span>
                </div>
                <ul>
                    <Link to="/elec-sites"><li className="sidebar--listelement">
                        <img src={SitesIcon} alt="Sites elec Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes sites</span>
                    </li></Link>
                    <Link to="/appels-offre-elec"><li className="sidebar--listelement">
                        <img src={AppelsdOffreIcon} alt="Appel d'offres elec Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes appels d'offres</span>
                    </li></Link>
                    <Link to="/contrats-elec"><li className="sidebar--listelement">
                        <img src={ContractsIcon} alt="Contrats elec Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes contrats</span>
                    </li></Link>
                    {/* <Link to="/budget">
                        <li className="sidebar--listelement">
                            <img src={BudgetIcon} alt="Budget Icon" className="sidebar--listicon" />
                            <span className="sidebar--elementtitle">Mon budget</span>
                        </li>
                    </Link> */}
                    <Link to="/conso"><li className="sidebar--listelement">
                        <img src={ConsoIcon} alt="Conso Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes consommations</span>
                    </li></Link>
                    <Link to="/invoice-verification"><li className="sidebar--listelement">
                        <img src={VerifyIcon} alt="Factures Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Vérifier mes factures</span>
                    </li></Link>
                    <Link to="/green-energy"><li className="sidebar--listelement">
                        <img src={GreenIcon} alt="Electricite verte Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mon électricité verte</span>
                    </li></Link>
                    <Link to="/optimisation-turpe"><li className="sidebar--listelement">
                        <img src={TurpeIcon} alt="Electricite verte Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Optimisation TURPE</span>
                    </li></Link>
                </ul>
                <div className="sidebar--listtitle__container">
                    <span className="sidebar--listtitle">COMPTE</span>
                </div>
                <ul>
                    <Link to="/account"><li className="sidebar--listelement">
                        <img src={ProfilIcon} alt="Profil Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mon profil</span>
                    </li></Link>
                    {props.isClient && <Link to="/contributors"><li className="sidebar--listelement">
                        <img src={TeamIcon} alt="Contributors Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes collaborateurs</span>
                    </li></Link>}
                    <Link to="/mandates"><li className="sidebar--listelement">
                        <img src={MandatesIcon} alt="Mandates Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes mandats</span>
                    </li></Link>
                    <Link to="/sepa"><li className="sidebar--listelement">
                        <img src={SepaIcon} alt="Sepa Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Mes mandats SEPA</span>
                    </li></Link>
                    <Link to="/wattvalue-invoices"><li className="sidebar--listelement">
                        <img src={InvoiceIcon} alt="Billing Icon" className="sidebar--listicon" />
                        <span className="sidebar--elementtitle">Facturation</span>
                    </li></Link>
                </ul>
            </div>
        </>
    )
}

export default MobileSideBar;