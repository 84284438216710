import SideBar from "../../Components/SideBar/SideBar"
import axios from "axios"
import { useState, useEffect, useCallback } from "react"
import ContractsTable from "./ContractsTable"
import { SearchBar } from "../../Components/SearchBar"
import { removeAccents } from "../../Utils/Regex/removeAccents"

const apiUrl = process.env.REACT_APP_API_URL

function Contracts() {
    const [contracts, setContracts] = useState([])
    const [filteredContracts, setFilteredContracts] = useState([])
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    const isGaz = window.location.pathname.includes('gaz')

    const fetchContract = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Contrat`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                setContracts(response.data)
                setFilteredContracts(response.data)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [isGaz])

    useEffect(() => {
        fetchContract()
    }, [fetchContract])

    const getFile = (documentID, guid, fileName) => {
        axios.get(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Contrat/Export?id=${documentID}&guid=${guid}`,
            {
                withCredentials: true,
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error.response && error.response.status && error.response.status === 401) {
                    window.location.reload()
                }
            })
    }

    const filterContracts = (query) => {
        if (!query || query === "") {
            setFilteredContracts(contracts)
        }
        else {
            const keys = ['pce', 'libelle', 'adresse', 'codePostal', 'ville', 'contrats']
            let contractCopy = JSON.parse(JSON.stringify(contracts))
            let results = contractCopy.filter((row) => (
                keys.some((key) => {
                    if (key === "contrats") {
                        const contratkeys = ['siteID', 'statut', 'fournisseur', 'contratID', 'contratFichier', 'cgvFichier']
                        const filteredRow = row["contrats"].filter((contractRow) => (
                            contratkeys.some((contractKey) => {
                                const k = contractRow?.[contractKey] ?? ''
                                return (removeAccents(k.toString()).includes(removeAccents(query.toString())))
                            }
                            ))
                        )
                        row["contrats"] = filteredRow
                        return filteredRow.length > 0
                    }
                    else {
                        const k = row?.[key] ?? ''
                        return (removeAccents(k.toString()).includes(removeAccents(query)))
                    }
                }
                )))
            setFilteredContracts(results)
        }
    }


    return (
        <>
            <SideBar
                active={isGaz ? "Contrats gaz" : "Contrats elec"}
                openGaz={isGaz}
                openElec={!isGaz} />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Mes contrats de fourniture {isGaz ? "Gaz" : "Électricité"}</h3>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {contracts.length > 0 &&
                                <SearchBar
                                    id="contractspage"
                                    additionnalFormClassName="searchbar--siteslist gaz--searchbar table--form"
                                    filterData={filterContracts}
                                    setValue={setSearchValue}
                                    value={searchValue}
                                />}
                            {filteredContracts.length > 0 ? filteredContracts.map((contract, index) => {
                                if (contract.contrats.some((element) => (element["fournisseur"] !== null))) {
                                    return (
                                        <div className="mandates--container" key={`contracts-${index}`}>
                                            <h4 className="mandates--site__title">Site {contract.libelle ? ` : ${contract.libelle}` : ""}{contract.prm ? ` - PRM ${contract.prm}` : ""}{contract.pce ? ` - PCE ${contract.pce}` : ""}</h4>
                                            <div className="privatepages--separator"></div>
                                            <ContractsTable
                                                rows={contract.contrats}
                                                getFile={getFile}
                                            />
                                        </div>
                                    )
                                }
                                else return null

                            })
                                :
                                <span>Aucun contrat trouvé.</span>}
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Contracts;