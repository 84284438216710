export const optionsMessageObject = [
    { value: "Modification des coordonnées, gestion collaborateurs", label: "Modification des coordonnées, gestion collaborateurs" },
    { value: "Inscription ou retrait d'un Site", label: "Inscription ou retrait d'un Site" },
    { value: "Changement de RIB", label: "Changement de RIB" },
    { value: "Factures et prélèvements WattValue", label: "Factures et prélèvements WattValue" },
    { value: "Appel d'offres (inscription, calendrier, détails du contrat…)", label: "Appel d'offres (inscription, calendrier, détails du contrat…)" },
    { value: "Factures fournisseurs (vérification, disponibilité, autre)", label: "Factures fournisseurs (vérification, disponibilité, autre)" },
    { value: "Souscription à un service (énergie verte, suivi de consommation…)", label: "Souscription à un service (énergie verte, suivi de consommation…)" },
    { value: "CSPE, aspects réglementaires et aides gouvernementales", label: "CSPE, aspects réglementaires et aides gouvernementales" },
    { value: "Prévisionnel budgétaire électricité", label: "Prévisionnel budgétaire électricité" },
    { value: "Optimisation TURPE (Tarif et puissance)", label: "Optimisation TURPE (Tarif et puissance)" },
    { value: "Suivi et analyse de la consommation", label: "Suivi et analyse de la consommation" },
    { value: "Données de suivi de consommation inaccessibles ou incomplètes", label: "Données de suivi de consommation inaccessibles ou incomplètes" },
    { value: "Support technique et Réclamations", label: "Support technique et Réclamations" },
    { value: "Demande d'informations générales, autre", label: "Demande d'informations générales, autre" },
]