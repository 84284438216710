import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { getComparator } from '../../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../../Utils/SortingUtils/stableSort'
import EnhancedTableHead from '../../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../../Components/TableComponents/StyledTableCell'
import { StyledTableRow } from '../../../Components/TableComponents/StyledTableRow'
import { headCellsInvoiceVerificationDetail } from '../../../Utils/TableUtils/headCells'
import { Fragment } from 'react'
import { Tooltip } from '@mui/material'

function InvoiceVerificationDetailTable(props) {
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('id')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [showPlus, setShowPlus] = React.useState(false)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, props.rows],
    )

    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        headCells={headCellsInvoiceVerificationDetail(props.isDetail)}
                        setShowPlus={setShowPlus}
                        showPlus={showPlus}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {

                            return (
                                <Fragment key={`invoice-verification-detail-table-${index}`}>
                                    <StyledTableRow>
                                        <StyledTableCell rowSpan={2}>
                                            <Tooltip title={row.fournisseurNom} enterTouchDelay={0}>
                                                <span>{row.fournisseurNom && row.fournisseurNom}</span>
                                            </Tooltip>
                                        </StyledTableCell>
                                        {props.isDetail &&
                                            <StyledTableCell sx={{ width: "90px" }} rowSpan={2}>{row.reference && row.reference}</StyledTableCell>
                                        }
                                        <StyledTableCell rowSpan={2}>{row.dateDebut && new Date(row.dateDebut).toLocaleDateString()}</StyledTableCell>
                                        <StyledTableCell rowSpan={2}>{row.dateFin && new Date(row.dateFin).toLocaleDateString()}</StyledTableCell>
                                        <StyledTableCell sx={{ width: "100px" }} rowSpan={2}>{row.consommation && row.consommation}</StyledTableCell>

                                        <StyledTableCell>Montants facturés</StyledTableCell>
                                        <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantFacHT && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantFacHT)}</StyledTableCell>
                                        <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantFacTTC && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantFacTTC)}</StyledTableCell>

                                        <StyledTableCell rowSpan={2}>{row.pourcentageEcartHT && row.pourcentageEcartHT}</StyledTableCell>
                                        {props.isDetail &&
                                            <>
                                                <StyledTableCell rowSpan={2}><span className={row.statut.toLowerCase().includes('anomalie') ? "elec--anomalie" : ""}>{row.statut && row.statut}</span></StyledTableCell>
                                                <StyledTableCell rowSpan={2}>{row.statutTraitement && row.statutTraitement}</StyledTableCell>
                                                <StyledTableCell rowSpan={2}>{row.partiesListe && row.partiesListe}</StyledTableCell>
                                                <StyledTableCell rowSpan={2}>
                                                    <Tooltip title={row.erreurListe} enterTouchDelay={0}>
                                                        <span>{row.erreurListe && row.erreurListe}</span>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </>
                                        }
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell>Ecart constaté (€)</StyledTableCell>
                                        <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantEcartHT && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantEcartHT)}</StyledTableCell>
                                        <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantEcartTTC && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantEcartTTC)}</StyledTableCell>
                                    </StyledTableRow>
                                    {showPlus &&
                                        <>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell sx={{ backgroundColor: "white" }} colSpan={6}></StyledTableCell>
                                                <StyledTableCell sx={{ backgroundColor: "white", fontWeight: "700 !important" }}>Coût énergie (1)</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>Ecart en %</StyledTableCell>
                                                {props.isDetail && <StyledTableCell sx={{ fontWeight: "700 !important" }}>Commentaire</StyledTableCell>}
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={5}></StyledTableCell>
                                                <StyledTableCell>Montants facturés</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantFacEnergie && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantFacEnergie)}</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important", textAlign: "left" }} rowSpan={2}>{row.pourcentageEcartEnergie && row.pourcentageEcartEnergie}</StyledTableCell>
                                                {props.isDetail &&
                                                    <StyledTableCell sx={{ textAlign: "left" }} rowSpan={2}>
                                                        <Tooltip title={row.energieCommentaire} enterTouchDelay={0}>
                                                            <span className="table--cell__limited">{row.energieCommentaire ? row.energieCommentaire : "-"}</span>
                                                        </Tooltip>
                                                    </StyledTableCell>}
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={5} ></StyledTableCell>
                                                <StyledTableCell>Ecart constaté (€)</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantEcartEnergie && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantEcartEnergie)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={5}></StyledTableCell>
                                                <StyledTableCell colSpan={3}>(1) Abonnement, kWh, CEE, capacité</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={6}></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>Acheminement (2)</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>Ecart en %</StyledTableCell>
                                                {props.isDetail && <StyledTableCell sx={{ fontWeight: "700 !important" }}>Commentaire</StyledTableCell>}
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={5}></StyledTableCell>
                                                <StyledTableCell>Montants facturés</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantFacAcheminement && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantFacAcheminement)}</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important", textAlign: "left" }} rowSpan={2}>{row.pourcentageEcartAcheminement && row.pourcentageEcartAcheminement}</StyledTableCell>
                                                {props.isDetail &&
                                                    <StyledTableCell sx={{ textAlign: "left" }} rowSpan={2}>
                                                        <Tooltip title={row.turpeCommentaire} enterTouchDelay={0}>
                                                            <span className="table--cell__limited">{row.turpeCommentaire ? row.turpeCommentaire : "-"}</span>
                                                        </Tooltip>
                                                    </StyledTableCell>}
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={5}></StyledTableCell>
                                                <StyledTableCell>Ecart constaté (€)</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantEcartAcheminement && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantEcartAcheminement)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={5}></StyledTableCell>
                                                <StyledTableCell colSpan={3}>(2) TURPE</StyledTableCell>
                                            </StyledTableRow>

                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={6}></StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>Taxes (3)</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>Ecart en %</StyledTableCell>
                                                {props.isDetail && <StyledTableCell sx={{ fontWeight: "700 !important" }}>Commentaire</StyledTableCell>}
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={5}></StyledTableCell>
                                                <StyledTableCell>Montants facturés</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantFacTaxe && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantFacTaxe)}</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important", textAlign: "left" }} rowSpan={2}>{row.pourcentageEcartTaxe && row.pourcentageEcartTaxe}</StyledTableCell>
                                                {props.isDetail &&
                                                    <StyledTableCell sx={{ textAlign: "left" }} rowSpan={2} colSpan={5}>
                                                        <Tooltip title={row.taxesCommentaire} enterTouchDelay={0}>
                                                            <span className="table--cell__limited">{row.taxesCommentaire ? row.taxesCommentaire : "-"}</span>
                                                        </Tooltip>
                                                    </StyledTableCell>}
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={5}></StyledTableCell>
                                                <StyledTableCell>Ecart constaté (€)</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: "700 !important" }}>{row.montantEcartTaxe && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.montantEcartTaxe)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow sx={{ backgroundColor: "white !important" }}>
                                                <StyledTableCell colSpan={5}></StyledTableCell>
                                                <StyledTableCell colSpan={3}>(3) CTA, taxes locales, CSPE</StyledTableCell>
                                            </StyledTableRow>
                                        </>

                                    }
                                </Fragment>

                            )
                        })}
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                props.isDetail &&
                <TablePagination
                    labelRowsPerPage="Lignes par page"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={props.rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({ from, to, count }) =>
                        `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                    sx={{
                        '.MuiTablePagination-toolbar': {
                            color: "#18181b !important",
                        },
                        '.MuiTablePagination-selectLabel': {
                            fontFamily: "Plus Jakarta Sans, sans-serif",
                        },
                        '.MuiTablePagination-select': {
                            fontFamily: "Plus Jakarta Sans, sans-serif",
                        },
                        '.MuiTablePagination-displayedRows': {
                            fontFamily: "Plus Jakarta Sans, sans-serif !important",
                            color: "#18181b !important",
                        },
                    }}
                />
            }
        </Box >
    )

}

export default InvoiceVerificationDetailTable;