import SideBar from "../../Components/SideBar/SideBar"
import { useState, useEffect, useCallback } from "react"
import { SearchBar } from "../../Components/SearchBar"
import axios from "axios"
import SitesTable from "./SitesTable"
import Button from "../../Components/Button"
import { createDataExcelSitesElec, createDataExcelSitesGaz, createDataSites } from "../../Utils/TableUtils/createData"
import InformationPopup from "../../Components/InformationPopup"
import DownloadXlsx from "../../Utils/TableUtils/DownloadXlsx"
import { removeAccents } from "../../Utils/Regex/removeAccents"

const apiUrl = process.env.REACT_APP_API_URL

function Sites() {
    const [isDataLoading, setIsDataLoading] = useState(false)

    const [sites, setSites] = useState([])
    const [filteredSites, setFilteredSites] = useState([])
    const [page, setPage] = useState(0)
    const [showSupprPopup, setShowSupprPopup] = useState(false)
    const [siteID, setSiteID] = useState("")

    const [excelData, setExcelData] = useState([])
    const [filteredeExcelData, setFilteredExcelData] = useState([])
    const [searchValue, setSearchValue] = useState("")

    const isGaz = window.location.pathname.includes('gaz')

    const getSitesData = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                const sites = response.data.map(element => createDataSites(element.appelOffre, element.libelle, `${element.adresse ? element.adresse : ""}${element.codePostal ? ` ${element.codePostal}` : ""}${element.ville ? ` ${element.ville}` : ""}`, element.pce, element.prm, element.fournisseur, element.dateDebutFourniture, element.dateFinNouveauContrat, element.prixParMWh, element.consommation, element.facturation, element.prix, element.car, element.isConfirmed, element.etapeValidation, element.id, element.fromReengagement))
                setFilteredSites(sites)
                setSites(sites)

                let excelData = []
                if (isGaz) {
                    excelData = response.data.map(element => createDataExcelSitesGaz(element.id, element.appelOffre, element.libelle, `${element.adresse ? element.adresse : ""}${element.codePostal ? ` ${element.codePostal}` : ""}${element.ville ? ` ${element.ville}` : ""}`, element.pce, element.fournisseur, element.dateDebutFourniture !== "0001-01-01T00:00:00" ? new Date(element.dateDebutFourniture).toLocaleDateString() : "", element.dateFinNouveauContrat !== "0001-01-01T00:00:00" ? new Date(element.dateFinNouveauContrat).toLocaleDateString() : "", element.prixParMWh ? Math.round(element.prixParMWh * 100) / 100 : "", element.prix ? Math.round(element.prix * 100) / 100 : "", element.car ? Math.round(element.car * 100) / 100 : ""))
                }
                else {
                    excelData = response.data.map(element => createDataExcelSitesElec(element.id, element.appelOffre, element.libelle, `${element.adresse ? element.adresse : ""}${element.codePostal ? ` ${element.codePostal}` : ""}${element.ville ? ` ${element.ville}` : ""}`, element.prm, element.fournisseur, element.dateDebutFourniture !== "0001-01-01T00:00:00" ? new Date(element.dateDebutFourniture).toLocaleDateString() : "", element.dateFinNouveauContrat !== "0001-01-01T00:00:00" ? new Date(element.dateFinNouveauContrat).toLocaleDateString() : "", element.prixParMWh ? Math.round(element.prixParMWh * 100) / 100 : "", element.consommation, element.facturation, element.prix ? Math.round(element.prix * 100) / 100 : "", element.car ? Math.round(element.car * 100) / 100 : ""))
                }
                setExcelData(excelData)
                setFilteredExcelData(excelData)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [isGaz])

    useEffect(() => {
        getSitesData()
        setPage(0)
    }, [getSitesData, setPage])

    const filterSites = (query) => {
        if (!query || query === "") {
            setFilteredSites(sites)
            setPage(0)

            setFilteredExcelData(excelData)
        }
        else {
            const keys = ['appelOffre', 'libelle', 'adresse', 'prm', 'pce', 'fournisseur', 'dateDebutFourniture', 'dateFinNouveauContrat', 'prixParMWh', 'consommation', 'facturation', 'prix', 'car']
            const results = sites.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredSites(results)
            setPage(0)

            const resultExcel = excelData.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredExcelData(resultExcel)
        }
    }

    const removeSite = () => {
        axios.delete(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Site/${siteID}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                closeInformationPopup()
                getSitesData()
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const closeInformationPopup = () => {
        setSiteID("")
        setShowSupprPopup(false)
    }

    const openInformationPopup = (siteId) => {
        setSiteID(siteId)
        setShowSupprPopup(true)
    }

    return (
        <>
            <SideBar
                active={isGaz ? "Sites gaz" : "Sites elec"}
                openGaz={isGaz}
                openElec={!isGaz} />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Mes sites {isGaz ? "Gaz" : "Électricité"}</h3>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            <div className={`table--searchbarcontainer gaz--searchbarcontainer${sites.length > 0 ? "" : " gaz--searchbarcontainer__emptyarray"}`}>
                                {sites.length > 0 && <SearchBar
                                    id="table"
                                    filterData={filterSites}
                                    setValue={setSearchValue}
                                    value={searchValue}
                                    additionnalFormClassName="searchbar--siteslist table--form" />}
                                <div>
                                    <Button
                                        cta="Ajouter un nouveau site"
                                        additionnalClassName="global--button__secondary gaz--addbutton"
                                        link={`/add-sites-1?type=${isGaz ? "gaz" : "elec"}`}
                                    />
                                    {filteredeExcelData.length > 0 &&
                                        <DownloadXlsx
                                            data={filteredeExcelData}
                                            fileName={isGaz ? "sites_gaz.xlsx" : "site_elec.xlsx"}
                                            headRow={isGaz ?
                                                [{ "A1": "SiteID" }, { "B1": "Appel d'offres" }, { "C1": 'Nom du site' }, { "D1": 'Adresse' }, { "E1": 'PCE' }, { "F1": 'Fournisseur' }, { "G1": 'Date de début de fourniture' }, { "H1": 'Échéance' }, { 'I1': 'Prix unitaire HTVA en €/MWh' }, { 'J1': 'Budget annuel moyen estimé HTVA en €' }, { 'K1': 'CAR N-1 en kWh/an' }]
                                                :
                                                [{ "A1": "ElecSiteID" }, { "B1": "Appel d'offres" }, { "C1": 'Nom du site' }, { "D1": 'Adresse' }, { "E1": 'PRM' }, { "F1": 'Fournisseur' }, { "G1": 'Date de début de fourniture' }, { "H1": 'Échéance' }, { 'I1': 'Prix unitaire HTVA en €/MWh' }, { 'J1': 'Consommation actuelle' }, { 'K1': 'Facturation depuis le 01/01' }, { 'L1': 'Budget annuel moyen estimé HTVA en €' }, { 'M1': 'CAR N-1 en kWh/an' }]
                                            }
                                        />
                                    }
                                </div>
                            </div>
                            {filteredSites.length > 0 ?
                                <SitesTable
                                    rows={filteredSites}
                                    removeSite={openInformationPopup}
                                    setId={setSiteID}
                                    page={page}
                                    setPage={setPage}
                                    isGaz={isGaz}
                                />
                                :
                                <span>Aucun site trouvé</span>
                            }
                        </>
                    }
                </div>
            </div>
            {showSupprPopup &&
                <InformationPopup
                    title="Êtes-vous sûr de vouloir supprimer ce site ?"
                    handleSuppr={removeSite}
                    closeInformationPopup={closeInformationPopup}
                    type="suppr"
                />
            }
        </>
    )
}

export default Sites;