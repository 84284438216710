import Close from '../../img/icons/close.png'
import CircleIcon from '@mui/icons-material/Circle'

function CalendarModal(props) {

    return (
        <div className="informationpopup--background">
            <div className="informationpopup--container appeloffre--modal__container">
                <img src={Close} alt="Close popup" className="informationpopup--close" onClick={() => props.closeModal()} />
                <h3 className="appeloffre--modal__title">Calendrier de consultation</h3>
                {props.dateFinSouscription && <p className="appeloffre--modal__text">
                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D" }} />
                    <span className="appeloffre--modal__date">{new Date(props.dateFinSouscription).toLocaleDateString()} :<br /></span>
                    Date limite d'enregistrement des consommateurs sur le site WattValue et de signature des mandats.
                </p>}
                {props.dateB3Diffusion && <p className="appeloffre--modal__text">
                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D" }} />
                    <span className="appeloffre--modal__date">{new Date(props.dateB3Diffusion).toLocaleDateString()} :<br /></span>
                    Publication de la base de données consommateurs aux fournisseurs, sur la plateforme WattValue.
                </p>}
                {props.dateFinRemiseB3Indicatif && <p className="appeloffre--modal__text">
                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D" }} />
                    <span className="appeloffre--modal__date">{new Date(props.dateFinRemiseB3Indicatif).toLocaleDateString()} :<br /></span>
                    Ces prix indicatifs sont communiqués au consommateur
                </p>}
                {props.dateLimiteReport && <p className="appeloffre--modal__text">
                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D" }} />
                    <span className="appeloffre--modal__date">{new Date(props.dateLimiteReport).toLocaleDateString()} :<br /></span>
                    La base de données consommateurs finale à jour est communiquée aux fournisseurs.
                </p>}
                {props.datePrixIndicatif && <p className="appeloffre--modal__text">
                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D" }} />
                    <span className="appeloffre--modal__date">{new Date(props.datePrixIndicatif).toLocaleDateString()} :<br /></span>
                    Remise de l'offre indicative par les fournisseurs. Ces prix indicatifs sont communiqués au consommateur le jour même.
                </p>}
                {props.dateFinRemiseB3 && <p className="appeloffre--modal__text">
                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D" }} />
                    <span className="appeloffre--modal__date">{new Date(props.dateFinRemiseB3).toLocaleDateString()} :<br /></span>
                    Remise de l'offre finale engageante. Réponse de WattValue le jour même avec attribution du portefeuille, pour émission et signature des contrats pour un début de fourniture dans la continuité des contrats en cours.
                </p>}
                {props.dateB3DiffusionDefinitif && <p className="appeloffre--modal__text">
                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D" }} />
                    <span className="appeloffre--modal__date">{new Date(props.dateB3DiffusionDefinitif).toLocaleDateString()} :<br /></span>
                    La base de données consommateurs finale à jour est communiquée aux fournisseurs.
                </p>}
                {props.datePrixDefinitif && <p className="appeloffre--modal__text">
                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D" }} />
                    <span className="appeloffre--modal__date">{new Date(props.datePrixDefinitif).toLocaleDateString()} :<br /></span>
                    Remise de l'offre finale engageante. Réponse de WattValue le jour même avec attribution du portefeuille, pour émission et signature des contrats pour un début de fourniture dans la continuité des contrats en cours.
                </p>}
                {(!props.dateFinSouscription && !props.dateB3Diffusion && !props.datePrixIndicatif && !props.dateB3DiffusionDefinitif && !props.datePrixDefinitif) &&
                    <p className="appeloffre--modal__text">
                        Pas encore de calendrier pour cet appel d'offres
                    </p>}
            </div>
        </div>
    )
}

export default CalendarModal