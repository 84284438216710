import * as React from 'react'
import { Link } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { getComparator } from '../../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../../Utils/SortingUtils/stableSort'
import EnhancedTableHead from '../../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../../Components/TableComponents/StyledTableCell'
import { headCellsMessages } from '../../../Utils/TableUtils/headCells'
import Open from '../../../img/icons/suppr.svg'
import { Tooltip } from '@mui/material'

function MailBoxTable(props) {
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('dateModification')
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        props.setPage(0)
    }

    const emptyRows =
        props.page > 0 ? Math.max(0, (1 + props.page) * rowsPerPage - props.rows.length) : 0

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator(order, orderBy)).slice(
                props.page * rowsPerPage,
                props.page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, props.page, rowsPerPage, props.rows],
    )

    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        headCells={headCellsMessages}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {

                            return (
                                <TableRow key={`mailboxtable-${index}`}>
                                    <StyledTableCell >{row.messageID}</StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title={row.sujet} enterTouchDelay={0}>
                                            <span>{row.sujet}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title={row.message} enterTouchDelay={0}>
                                            <span>{row.message}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>{row.dateCreation && new Date(row.dateCreation).toLocaleString()}</StyledTableCell>
                                    <StyledTableCell>{row.dateModification && new Date(row.dateModification).toLocaleString()}</StyledTableCell>
                                    <Link to={`/chat?id=${row.messageID}`}><StyledTableCell>{<div><span className="table--link">Continuer la discussion</span><img src={Open} alt="Ouvrir" className="table--icon" /></div>}</StyledTableCell></Link>
                                    <StyledTableCell>{row.isRead ? <span className="messagestable--emptycell"></span> : <span className="messagestable--newmessagelink">Nouveau message</span>}</StyledTableCell>
                                </TableRow>
                            )
                        })}
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Lignes par page"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={props.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                sx={{
                    '.MuiTablePagination-toolbar': {
                        color: "#18181b !important",
                    },
                    '.MuiTablePagination-selectLabel': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-select': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-displayedRows': {
                        fontFamily: "Plus Jakarta Sans, sans-serif !important",
                        color: "#18181b !important",
                    },
                }}
            />
        </Box>
    )
}

export default MailBoxTable;