import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams, Link } from 'react-router-dom'

import SideBar from '../../../Components/SideBar/SideBar'
import FormInput from '../../../Components/Form/FormInput'
import { optionsCivility } from '../../../Utils/DropDownOptions/optionsCivility'
import Button from '../../../Components/Button'
import { validEmail } from '../../../Utils/Regex/vallidEmail'
import axios from 'axios'
import { SearchBar } from '../../../Components/SearchBar'
import ContributorsTable from './ContributorsTable'
import { createDataAccess } from '../../../Utils/TableUtils/createData'
import { removeAccents } from '../../../Utils/Regex/removeAccents'

const apiUrl = process.env.REACT_APP_API_URL

function AddContributors() {
    const navigate = useNavigate()
    const location = useLocation()

    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)
    const [isAdded, setIsAdded] = useState(false)

    const [errorMessageAccess, setErrorMessageAccess] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessageAccess, setShowErrorMessageAccess] = useState(false)
    const [isAccessLoading, setisAccessLoading] = useState(false)
    const [showConfirmationMessageAccess, setShowConfirmationMessageAccess] = useState(false)

    const [searchParams] = useSearchParams()
    const [id, setId] = useState(searchParams.get('id'))

    const [email, setEmail] = useState("")
    const [civility, setCivility] = useState("")
    const [lastname, setLastname] = useState("")
    const [firstname, setFirstname] = useState("")

    const [emailInitial, setEmailInitial] = useState("")
    const [civilityInitial, setCivilityInitial] = useState("")
    const [lastnameInitial, setLastnameInitial] = useState("")
    const [firstnameInitial, setFirstnameInitial] = useState("")

    const [showAccessList, setShowAccessList] = useState(false)
    const [accessArray, setAccessArray] = useState([])
    const [formatedAccessArray, setFormatedAccessArray] = useState([])
    const [filteredAccessArray, setFilteredAccessArray] = useState([])

    const [isValidated, setIsValidated] = useState(false)

    const [page, setPage] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const handleAddContributor = async () => {
        hideMessages()
        const user = JSON.stringify({
            "email": `${email}`,
            "civilite": `${civility}`,
            "nom": `${lastname}`,
            "prenom": `${firstname}`,
        })
        if (!validEmail.test(email)) {
            setErrorMessage("Veuillez renseigner une adresse email valide")
            setShowErrorMessage(true)
        }
        else if (window.location.pathname.includes("update")) {
            setIsLoading(true)
            axios.patch(`${apiUrl}/client/contact/${id}`,
                user,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then(() => {
                    setShowConfirmationMessage(true)
                    setShowAccessList(true)
                    getContributor()
                })
                .catch(error => {
                    if (error?.response?.data?.message) setErrorMessage(error.response.data.message)
                    setShowErrorMessage(true)
                    setShowConfirmationMessage(false)
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
        else {
            setIsLoading(true)
            axios.post(`${apiUrl}/client/contact`,
                user,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then((response) => {
                    setId(response.data)
                    setShowConfirmationMessage(true)
                    setShowAccessList(true)
                    setIsAdded(true)
                    getContributor(response.data)
                    getContributorsAccess(response.data)
                })
                .catch(error => {
                    if (error?.response?.data?.message) setErrorMessage(error.response.data.message)
                    setShowErrorMessage(true)
                    setShowConfirmationMessage(false)
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const hideMessages = () => {
        setShowErrorMessage(false)
        setShowConfirmationMessage(false)
    }

    const filterAccess = (query) => {
        if (!query || query === "") {
            setFilteredAccessArray(formatedAccessArray)
            setPage(0)
        }
        else {
            const keys = ['typeLabel', 'pdl', 'label', 'address']
            const results = formatedAccessArray.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredAccessArray(results)
            setPage(0)
        }
    }

    const handleUpdateAccess = async () => {
        setisAccessLoading(true)
        axios.patch(`${apiUrl}/client/contact/${id}/access`,
            accessArray,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                setShowConfirmationMessageAccess(true)
                if (location.pathname === "/contributors-add") {
                    setEmail("")
                    setCivility("")
                    setLastname("")
                    setFirstname("")
                    setIsValidated("")

                    setEmailInitial("")
                    setCivilityInitial("")
                    setLastnameInitial("")
                    setFirstnameInitial("")

                    setId("")

                    setShowAccessList(false)
                    setAccessArray([])
                    setFormatedAccessArray([])
                    setFilteredAccessArray([])

                    setIsAdded(false)
                }
            })
            .catch(error => {
                if (error?.response?.data?.message) setErrorMessageAccess(error.response.data.message)
                setShowErrorMessageAccess(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => {
                setisAccessLoading(false)
            })
    }

    const getContributor = useCallback((responseId) => {
        const contributorId = id ? id : responseId
        axios.get(`${apiUrl}/client/contact/${contributorId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                setEmail(response.data.email)
                setCivility(response.data.civilite)
                setLastname(response.data.nom)
                setFirstname(response.data.prenom)
                setIsValidated(response.data.isValidated)

                setEmailInitial(response.data.email)
                setCivilityInitial(response.data.civilite)
                setLastnameInitial(response.data.nom)
                setFirstnameInitial(response.data.prenom)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
                if (error && error.response.status === 403) {
                    navigate('/')
                }
            })
    }, [id, navigate])

    const getContributorsAccess = useCallback((responseId) => {
        const contributorId = id ? id : responseId
        axios.get(`${apiUrl}/client/contact/${contributorId}/access`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                const access = response.data.map(element => createDataAccess(element.type, element.type, element.pdl, element.libelle, `${element.adresse ? element.adresse : ""}, ${element.codePostal ? element.codePostal : ""} ${element.ville ? element.ville : ""}`, element.hasAccess))
                setFormatedAccessArray(access)
                setFilteredAccessArray(access)
                setAccessArray(response.data)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [id])

    const handleCheckboxClick = (event, index) => {
        setShowErrorMessageAccess(false)
        setShowConfirmationMessageAccess(false)

        const duplicateAccessArray = [...accessArray]
        duplicateAccessArray[index]['hasAccess'] = event.target.checked

        const duplicateFormatedAccessArray = [...formatedAccessArray]
        duplicateFormatedAccessArray[index]['access'] = event.target.checked

        setAccessArray(duplicateAccessArray)
        setFormatedAccessArray(duplicateFormatedAccessArray)
        setFilteredAccessArray(duplicateFormatedAccessArray)
    }

    const checkIsClient = useCallback(() => {
        axios.get(`${apiUrl}/auth/info`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                if (!response.data.isClient) navigate('/')
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            }
            )
    }, [navigate])

    useEffect(() => {
        checkIsClient()
        if (window.location.pathname.includes("update")) {
            getContributor()
            getContributorsAccess()
            setShowAccessList(true)
        }
    }, [getContributor, getContributorsAccess, checkIsClient])

    return (
        <>
            <SideBar
                openAccount
                active="Contributors" />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">{window.location.pathname.includes("update") ? "Modifier un collaborateur" : "Ajouter un collaborateur"}</h3>
                    <Link to={'/contributors'}><span className="privatepages--header__return">{`< Retour à la liste des collaborateurs`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="privatepages--columnscontainer privatepages--columnscontainer__contributors">
                    <div className="privatepages--twocolumns privatepages--twocolumns__left">
                        <div>
                            <span className="privatepages--asterisk privatepages--text">*champ obligatoire</span>
                            <form className="privatepages--form__block">
                                <h4 className="privatepages--form__title">Informations personnelles</h4>
                                <FormInput
                                    label="Email*"
                                    type="email"
                                    id="email"
                                    onChangeInput={value => {
                                        hideMessages()
                                        setEmail(value)
                                    }}
                                    placeholder="emilie.sardou@gmail.com"
                                    value={email}
                                    maxLength={255}
                                    disabled={isValidated}
                                />
                                <FormInput
                                    type="dropdown"
                                    inputLabel="Civilité*"
                                    id="civility"
                                    onChangeInput={e => {
                                        hideMessages()
                                        setCivility(e.value)
                                    }}
                                    placeholder="Choisir..."
                                    value={civility}
                                    isSearchable={false}
                                    options={optionsCivility}
                                />
                                <FormInput
                                    type="text"
                                    label="Nom*"
                                    id="lastname"
                                    onChangeInput={value => {
                                        hideMessages()
                                        setLastname(value)
                                    }}
                                    placeholder="Dupont"
                                    value={lastname}
                                    maxLength={255}
                                />
                                <FormInput
                                    type="text"
                                    label="Prénom*"
                                    id="firstname"
                                    onChangeInput={value => {
                                        hideMessages()
                                        setFirstname(value)
                                    }}
                                    placeholder="Xavier"
                                    value={firstname}
                                    maxLength={255}
                                    additionnalClassName="homepage--popup__inputmarginbottom14"
                                />
                                {showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessage}</span>}
                                {showConfirmationMessage && <span className="homepage--popup__message homepage--popup__messageconfirmation">{`Le collaborateur a bien été ${window.location.pathname.includes("update") ? "modifié" : "ajouté"}`}</span>}
                                <Button
                                    cta={window.location.pathname.includes("update") ? "Modifier" : "Ajouter"}
                                    disabled={
                                        (civility === "" || !civility || lastname === "" || !lastname || firstname === "" || !firstname || email === "" || !email) || isAdded ||
                                        ((civility === civilityInitial) && (lastname === lastnameInitial) && (firstname === firstnameInitial) && (email === emailInitial))
                                    }
                                    handleSubmit={() => handleAddContributor()}
                                    isLoading={isLoading}
                                />
                            </form>
                        </div>
                    </div>
                    {showAccessList &&
                        <div className="privatepages--twocolumns privatepages--twocolumns__right privatepages--twocolumns__siteslist">
                            <div className="privatepages--form__block privatepages--form__blocksiteslist privatepages--twocolumns__rightblock">
                                <h4 className="privatepages--form__title">Liste des accès</h4>
                                {accessArray.length > 0 &&
                                    <div className="table--searchbarcontainer table--searchbarcontainer__siteslist">
                                        <SearchBar
                                            id="table"
                                            filterData={filterAccess}
                                            setValue={setSearchValue}
                                            value={searchValue}
                                            additionnalFormClassName="searchbar--siteslist table--form" />
                                        <Button
                                            cta="Enregistrer"
                                            handleSubmit={handleUpdateAccess}
                                            isLoading={isAccessLoading}
                                            additionnalClassName="table--tablecontainer__button" />
                                    </div>
                                }
                                {showErrorMessageAccess && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageAccess}</span>}
                                {showConfirmationMessageAccess && <span className="homepage--popup__message homepage--popup__messageconfirmation">Les accès ont bien été mis à jour</span>}
                                {filteredAccessArray.length > 0 ?
                                    <ContributorsTable
                                        rows={filteredAccessArray}
                                        type="access"
                                        handleCheckboxClick={handleCheckboxClick}
                                        page={page}
                                        setPage={setPage}
                                    />
                                    :
                                    <span>Aucun site trouvé</span>
                                }
                            </div>
                        </div>}
                </div>
            </div>
        </>
    )
}

export default AddContributors;