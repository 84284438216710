import { useEffect, useState } from "react"
import { useSearchParams, Link } from "react-router-dom"

import FormInput from "../../../Components/Form/FormInput"
import SideBar from "../../../Components/SideBar/SideBar"
import Button from "../../../Components/Button"
import axios from 'axios'
import { optionsMessageObject } from "../../../Utils/DropDownOptions/optionsMessageObject"


const apiUrl = process.env.REACT_APP_API_URL

function NewMessage() {
    const [isLoading, setIsLoading] = useState(false)
    const [searchParams] = useSearchParams()
    const objectSouscription = searchParams.get('object')

    const [object, setObject] = useState("")
    const [message, setMessage] = useState("")
    const [formatedMessage, setFormatedMessage] = useState("")

    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)

    const handleSubmit = () => {
        const messageJson = JSON.stringify({
            "sujet": `${object}`,
            "message": `${formatedMessage}`,
        })
        setIsLoading(true)
        axios.post(`${apiUrl}/message/send`,
            messageJson,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                setShowConfirmationMessage(true)
                setObject(null)
                setMessage("")
            })
            .catch(error => {
                setShowErrorMessage(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsLoading(false))
    }

    const handleObjectChange = (e) => {
        setObject(e.value)
        resetMessages()
    }

    const resetMessages = () => {
        setShowConfirmationMessage(false)
        setShowErrorMessage(false)
    }

    const formatMessage = (value) => {
        const formatedValue = value.split(/\r?\n|\r/).join(' ')
        setMessage(value)
        resetMessages()
        setFormatedMessage(formatedValue)
    }

    useEffect(() => {
        if (objectSouscription === "souscription") setObject("Souscription à un service (énergie verte, suivi de consommation…)")
    }, [objectSouscription])

    return (
        <>
            <SideBar />
            <div className="homepage--container privatepages--container messages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">Nouveau message</h3>
                    <Link to='/messages'><span className="privatepages--header__return">{`< Retour à la liste des messages`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="privatepages--columnscontainer">
                    <form className="privatepages--form__block messages--form__block">
                        <FormInput
                            type="dropdown"
                            inputLabel="Objet"
                            id="object"
                            options={optionsMessageObject}
                            onChangeInput={handleObjectChange}
                            additionnalLabelClassName="homepage--popup__inputlabelmargintop0"
                            placeholder="Choisir un objet"
                            value={object}
                            label={object}
                            maxLength={255}
                            isSearchable={false}
                        />
                        <FormInput
                            type="textarea"
                            label="Message"
                            id="message"
                            onChangeInput={formatMessage}
                            placeholder="Votre message"
                            value={message}
                            additionnalClassName="homepage--popup__inputmarginbottom14 messages--input__textarea"
                        />
                        {showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror">Une erreur est survenur, veuillez réessayer plus tard</span>}
                        {showConfirmationMessage && <span className="homepage--popup__message homepage--popup__messageconfirmation">Le message a bien été envoyé</span>}
                        <Button
                            cta="Envoyer"
                            handleSubmit={handleSubmit}
                            disabled={object === "" || !object || message === "" || !message}
                            isLoading={isLoading}
                        />
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewMessage;