import * as React from 'react'
import { Link } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { getComparator } from '../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../Utils/SortingUtils/stableSort'
import EnhancedTableHead from '../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../Components/TableComponents/StyledTableCell'
import { headCellsSites } from '../../Utils/TableUtils/headCells'
import Open from '../../img/icons/suppr.svg'
import Gaz from '../../img/icons/gaz.svg'
import Elec from '../../img/icons/elec.svg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Tooltip } from '@mui/material'

function SitesTable(props) {
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('appelOffre')
    const [rowsPerPage, setRowsPerPage] = React.useState(props.isDashboard ? 5 : 10)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        props.setPage(0)
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator(order, orderBy)).slice(
                props.page * rowsPerPage,
                props.page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, props.page, rowsPerPage, props.rows],
    )

    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        headCells={headCellsSites(!props.isGaz, props.isDashboard)}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            let step = 1
                            if (row.etapeValidation !== 3 && props.isGaz) step = row.etapeValidation + 1
                            if (row.etapeValidation !== 4 && !props.isGaz) step = row.etapeValidation + 1
                            else step = row.etapeValidation

                            return (
                                <TableRow key={`sites-${index}`}>
                                    <StyledTableCell><img src={props.isGaz ? Gaz : Elec} alt={props.isGaz ? "Gaz" : "Elec"} /></StyledTableCell>
                                    {!props.isDashboard &&
                                        <>
                                            <StyledTableCell>
                                                <Link to={`/modify-sites-${row.fromReengagement ? "1" : step}?type=${props.isGaz ? "gaz" : "elec"}&id=${row.id}`}><div className="table--icon__container">
                                                    <img alt="Modify" src={Open} className="table--icon" />
                                                </div></Link>
                                            </StyledTableCell>
                                            <StyledTableCell>{!row.isConfirmed && <div className="table--icon__container" onClick={() => props.removeSite(row.id)}><HighlightOffIcon alt="Supprimer" sx={{ width: "17px", height: "17px", cursor: "pointer" }} /></div>}</StyledTableCell>
                                        </>}
                                    <StyledTableCell>{row.appelOffre && row.appelOffre}</StyledTableCell>
                                    <StyledTableCell><Tooltip title={row.libelle} enterTouchDelay={0}>
                                        <span>{row.libelle && row.libelle}</span>
                                    </Tooltip></StyledTableCell>
                                    <StyledTableCell><Tooltip title={row.adresse} enterTouchDelay={0}>
                                        <span>{row.adresse && row.adresse}</span>
                                    </Tooltip></StyledTableCell>
                                    {props.isGaz ?
                                        <StyledTableCell>{row.pce && row.pce}</StyledTableCell> :
                                        <StyledTableCell>{row.prm && row.prm}</StyledTableCell>
                                    }
                                    <StyledTableCell><Tooltip title={row.fournisseur} enterTouchDelay={0}>
                                        <span>{row.fournisseur && row.fournisseur}</span>
                                    </Tooltip></StyledTableCell>
                                    <StyledTableCell>{(row.dateDebutFourniture && row.dateDebutFourniture !== "0001-01-01T00:00:00") && new Date(row.dateDebutFourniture).toLocaleDateString()}</StyledTableCell>
                                    <StyledTableCell>{(row.dateFinNouveauContrat && row.dateFinNouveauContrat !== "0001-01-01T00:00:00") && new Date(row.dateFinNouveauContrat).toLocaleDateString()}</StyledTableCell>
                                    <StyledTableCell>{row.prixParMWh && `${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.prixParMWh)}/MWh`}</StyledTableCell>
                                    {!props.isGaz &&
                                        <>
                                            <StyledTableCell>{row.consommation && `${row.consommation.toLocaleString()} kWh`}</StyledTableCell>
                                            <StyledTableCell>{row.facturation && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.facturation)}</StyledTableCell>
                                        </>}
                                    <StyledTableCell>{row.prix && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.prix)}</StyledTableCell>
                                    <StyledTableCell>{row.car && `${row.car.toLocaleString()} kWh`}</StyledTableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell sx={{ padding: "5px" }} colSpan={6} />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {!props.isDashboard && <TablePagination
                labelRowsPerPage="Lignes par page"
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={props.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                sx={{
                    '.MuiTablePagination-toolbar': {
                        color: "#18181b !important",
                    },
                    '.MuiTablePagination-selectLabel': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-select': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-displayedRows': {
                        fontFamily: "Plus Jakarta Sans, sans-serif !important",
                        color: "#18181b !important",
                    },
                }}
            />}
        </Box >
    )

}

export default SitesTable;