import SideBar from '../../Components/SideBar/SideBar'
import FormInput from '../../Components/Form/FormInput'
import { useEffect, useState, useCallback } from 'react'
import { optionsFonction } from '../../Utils/DropDownOptions/optionsFonction'
import { optionsCivility } from '../../Utils/DropDownOptions/optionsCivility'
import Button from '../../Components/Button'
import EyeIcon from '../../img/icons/eye.svg'
import EyeIconCrossedOut from '../../img/icons/eye_crossed_out.svg'
import { validPassword } from '../../Utils/Regex/validPassword'
import { validPhone } from '../../Utils/Regex/validPhone'
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL

function UpdateAccount() {
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [civility, setCivility] = useState("")
    const [lastname, setLastname] = useState("")
    const [firstname, setFirstname] = useState("")
    const [fonction, setFonction] = useState("")
    const [mobile, setMobile] = useState("")
    const [landline, setLandline] = useState("")

    const [companyInitial, setCompanyInitial] = useState("")
    const [civilityInitial, setCivilityInitial] = useState("")
    const [lastnameInitial, setLastnameInitial] = useState("")
    const [firstnameInitial, setFirstnameInitial] = useState("")
    const [fonctionInitial, setFonctionInitial] = useState("")
    const [mobileInitial, setMobileInitial] = useState("")
    const [landlineInitial, setLandlineInitial] = useState("")

    const [isUpdateAccountLoading, setIsUpdateAccountLoading] = useState(false)
    const [showErrorMessageUpdateAccount, setShowErrorMessageUpdateAccount] = useState(false)
    const [errorMessageUpdateAccount, setErrorMessageUpdateAccount] = useState("")
    const [showConfirmationMessageUpdateAccount, setShowConfirmationMessageUpdateAccount] = useState(false)
    const [confirmationMessageUpdateAccount, setConfirmationMessageUpdateAccount] = useState("")

    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [inputType, setInputType] = useState("password")

    const [isUpdatePasswordLoading, setIsUpdatePasswordLoading] = useState(false)
    const [showErrorMessageUpdatePassword, setShowErrorMessageUpdatePassword] = useState(false)
    const [errorMessageUpdatePassword, setErrorMessageUpdatePassword] = useState("")
    const [showConfirmationMessageUpdatePassword, setShowConfirmationMessageUpdatePassword] = useState(false)
    const [confirmationMessageUpdatePassword, setConfirmationMessageUpdatePassword] = useState("")

    const [isClient, setIsClient] = useState(false)

    const checkIsClient = useCallback(() => {
        axios.get(`${apiUrl}/auth/info`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                if (response.data.isClient) {
                    setIsClient(response.data.isClient)
                    getClient()
                }
                else {
                    getCollaborator()
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [])

    useEffect(() => {
        checkIsClient()
    }, [checkIsClient])

    const getClient = async () => {
        axios.get(`${apiUrl}/Client`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                if (response && response.data) {
                    response.data.email && setEmail(response.data.email)
                    response.data.civilite && setCivility(response.data.civilite)
                    response.data.raisonSociale && setCompany(response.data.raisonSociale)
                    response.data.nom && setLastname(response.data.nom)
                    response.data.prenom && setFirstname(response.data.prenom)
                    response.data.fonction && setFonction(response.data.fonction)
                    response.data.mobile && setMobile(response.data.mobile)
                    response.data.telephone && setLandline(response.data.telephone)

                    response.data.civilite && setCivilityInitial(response.data.civilite)
                    response.data.raisonSociale && setCompanyInitial(response.data.raisonSociale)
                    response.data.nom && setLastnameInitial(response.data.nom)
                    response.data.prenom && setFirstnameInitial(response.data.prenom)
                    response.data.fonction && setFonctionInitial(response.data.fonction)
                    response.data.mobile && setMobileInitial(response.data.mobile)
                    response.data.telephone && setLandlineInitial(response.data.telephone)
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getCollaborator = async () => {
        axios.get(`${apiUrl}/Contact`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                if (response && response.data) {
                    response.data[0].email && setEmail(response.data[0].email)
                    response.data[0].civilite && setCivility(response.data[0].civilite)
                    response.data[0].nom && setLastname(response.data[0].nom)
                    response.data[0].prenom && setFirstname(response.data[0].prenom)

                    response.data[0].civilite && setCivilityInitial(response.data[0].civilite)
                    response.data[0].nom && setLastnameInitial(response.data[0].nom)
                    response.data[0].prenom && setFirstnameInitial(response.data[0].prenom)
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const handleUpdateAccount = async () => {
        setShowErrorMessageUpdateAccount(false)
        setShowConfirmationMessageUpdateAccount(false)
        if (!isClient) {
            setIsUpdateAccountLoading(true)
            const user = JSON.stringify({
                "civilite": `${civility}`,
                "nom": `${lastname}`,
                "prenom": `${firstname}`,
            })
            await axios.patch(
                `${apiUrl}/contact`,
                user,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                }).then(response => {
                    setIsUpdateAccountLoading(false)
                    if (response && response.status === 200) {
                        setCivilityInitial(civility)
                        setLastnameInitial(lastname)
                        setFirstnameInitial(firstname)
                        setConfirmationMessageUpdateAccount("Les modifications ont bien été enregistrées")
                        setShowConfirmationMessageUpdateAccount(true)
                        getCollaborator()
                    }
                    else {
                        setErrorMessageUpdateAccount("Une erreur est survenue")
                        setIsUpdateAccountLoading(false)
                        setShowErrorMessageUpdateAccount(true)
                        window.location.reload()
                    }
                }).catch(error => {
                    setErrorMessageUpdateAccount("Une erreur est survenue")
                    setIsUpdateAccountLoading(false)
                    setShowErrorMessageUpdateAccount(true)
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
        }
        else if ((!mobile || mobile === "") && (landline === "" || !landline)) {
            setErrorMessageUpdateAccount("Veuillez renseigner au moins un des deux numéros de téléphone")
            setShowErrorMessageUpdateAccount(true)
        }
        else if ((mobile && !validPhone.test(mobile) && mobile !== "") || (landline && !validPhone.test(landline) && landline !== "")) {
            setErrorMessageUpdateAccount("Veuillez renseigner un numéro de téléphone valide à 10 chiffres")
            setShowErrorMessageUpdateAccount(true)
        }
        else {
            setIsUpdateAccountLoading(true)
            const user = JSON.stringify({
                "raisonSociale": `${company}`,
                "civilite": `${civility}`,
                "nom": `${lastname}`,
                "prenom": `${firstname}`,
                "fonction": `${fonction}`,
                "telephone": `${landline}`,
                "mobile": `${mobile}`,
            })
            await axios.patch(
                `${apiUrl}/client`,
                user,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                }).then(response => {
                    setIsUpdateAccountLoading(false)
                    if (response && response.status === 200) {
                        setCivilityInitial(civility)
                        setCompanyInitial(company)
                        setLastnameInitial(lastname)
                        setFirstnameInitial(firstname)
                        setFonctionInitial(fonction)
                        setMobileInitial(mobile)
                        setLandlineInitial(landline)
                        setConfirmationMessageUpdateAccount("Les modifications ont bien été enregistrées")
                        setShowConfirmationMessageUpdateAccount(true)
                        getClient()
                    }
                    else {
                        setErrorMessageUpdateAccount("Une erreur est survenue")
                        setIsUpdateAccountLoading(false)
                        setShowErrorMessageUpdateAccount(true)
                        window.location.reload()
                    }
                }).catch(error => {
                    setErrorMessageUpdateAccount("Une erreur est survenue")
                    setIsUpdateAccountLoading(false)
                    setShowErrorMessageUpdateAccount(true)
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
        }
    }

    const handleUpdatePassword = async () => {
        const requestUrl = isClient ? "client" : "contact"
        if (password !== passwordConfirmation) {
            setErrorMessageUpdatePassword("Le mot de passe et la confirmation ne sont pas identiques")
            setShowErrorMessageUpdatePassword(true)
        }
        else {
            setIsUpdatePasswordLoading(true)
            setShowErrorMessageUpdatePassword(false)
            const body = JSON.stringify({
                "password": `${password}`,
            })
            try {
                await axios.patch(
                    `${apiUrl}/${requestUrl}`,
                    body,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: true,
                    })
                setIsUpdatePasswordLoading(false)
                setPassword("")
                setPasswordConfirmation("")
                setConfirmationMessageUpdatePassword("Le mot de passe a bien été modifié")
                setShowConfirmationMessageUpdatePassword(true)
            }
            catch (error) {
                setErrorMessageUpdatePassword(error?.response?.data?.message)
                setIsUpdatePasswordLoading(false)
                setShowErrorMessageUpdatePassword(true)
                window.location.reload()
            }
        }
    }

    const onClickEyeIcon = () => {
        inputType === "password" ? setInputType("text") : setInputType("password")
    }

    const handlePasswordClick = (id, value) => {
        setShowConfirmationMessageUpdatePassword(false)
        setShowErrorMessageUpdatePassword(false)
        if (id === "password") {
            setPassword(value)
        }
        if (id === "passwordconfirmation") {
            setPasswordConfirmation(value)
        }
        if (!validPassword.test(value) && value !== "") {
            setErrorMessageUpdatePassword("Le mot de passe doit contenir au moins 8 caractères dont un spécial")
            setShowErrorMessageUpdatePassword(true)
        }
    }

    return (
        <>
            <SideBar
                openAccount
                active="Update"
            />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Modification de votre compte</h3>
                <div className="privatepages--separator"></div>
                <div className="privatepages--columnscontainer">
                    <div className="privatepages--twocolumns privatepages--twocolumns__left">
                        <div>
                            <span className="privatepages--asterisk privatepages--text">*champ obligatoire</span>
                            <form className="privatepages--form__block">
                                <h4 className="privatepages--form__title">Vos coordonnées</h4>
                                <FormInput
                                    label="Email*"
                                    type="email"
                                    id="email"
                                    disabled
                                    value={email}
                                    maxLength={255}
                                />
                                {isClient && <FormInput
                                    type="text"
                                    label="Raison sociale*"
                                    id="company"
                                    onChangeInput={value => {
                                        setShowConfirmationMessageUpdateAccount(false)
                                        setCompany(value)
                                    }}
                                    placeholder="WattUnity"
                                    value={company}
                                    maxLength={255}
                                />}
                                <FormInput
                                    type="dropdown"
                                    inputLabel="Civilité*"
                                    id="civility"
                                    onChangeInput={e => {
                                        setShowConfirmationMessageUpdateAccount(false)
                                        setCivility(e.value)
                                    }}
                                    placeholder="Choisir..."
                                    value={civility}
                                    isSearchable={false}
                                    options={optionsCivility}
                                />
                                <FormInput
                                    type="text"
                                    label="Nom*"
                                    id="lastname"
                                    onChangeInput={value => {
                                        setShowConfirmationMessageUpdateAccount(false)
                                        setLastname(value)
                                    }}
                                    placeholder="Dupont"
                                    value={lastname}
                                    maxLength={255}
                                />
                                <FormInput
                                    type="text"
                                    label="Prénom*"
                                    id="firstname"
                                    onChangeInput={value => {
                                        setShowConfirmationMessageUpdateAccount(false)
                                        setFirstname(value)
                                    }}
                                    placeholder="Xavier"
                                    value={firstname}
                                    maxLength={255}
                                    additionnalClassName={isClient ? "" : "homepage--popup__inputmarginbottom14"}
                                />
                                {isClient &&
                                    <>
                                        <FormInput
                                            inputLabel="Fonction*"
                                            type="dropdown"
                                            id="fonction"
                                            onChangeInput={e => {
                                                setShowConfirmationMessageUpdateAccount(false)
                                                setFonction(e.value)
                                            }}
                                            placeholder="Choisir..."
                                            value={fonction}
                                            isSearchable={false}
                                            options={optionsFonction}
                                        />
                                        <FormInput
                                            label="Téléphone mobile"
                                            type="number"
                                            id="mobile"
                                            onChangeInput={value => {
                                                setShowConfirmationMessageUpdateAccount(false)
                                                setShowErrorMessageUpdateAccount(false)
                                                setMobile(value)
                                            }}
                                            placeholder="06 01 02 03 04"
                                            value={mobile}
                                            maxLength={50}
                                        />
                                        <FormInput
                                            label="Téléphone fixe"
                                            type="number"
                                            id="landline"
                                            onChangeInput={value => {
                                                setShowConfirmationMessageUpdateAccount(false)
                                                setShowErrorMessageUpdateAccount(false)
                                                setLandline(value)
                                            }}
                                            placeholder="02 35 12 65 98"
                                            value={landline}
                                            maxLength={50}
                                            additionnalClassName="homepage--popup__inputmarginbottom14"
                                        />
                                    </>}
                                {showErrorMessageUpdateAccount && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageUpdateAccount}</span>}
                                {showConfirmationMessageUpdateAccount && <span className="homepage--popup__message homepage--popup__messageconfirmation">{confirmationMessageUpdateAccount}</span>}
                                <Button
                                    cta="Modifier"
                                    disabled={isClient ?
                                        (company === "" || !company || civility === "" || !civility || lastname === "" || !lastname || firstname === "" || !firstname || fonction === "" || !fonction) ||
                                        ((company === companyInitial) && (civility === civilityInitial) && (lastname === lastnameInitial) && (firstname === firstnameInitial) && (fonction === fonctionInitial) && (mobile === mobileInitial) && (landline === landlineInitial))
                                        :
                                        (civility === "" || !civility || lastname === "" || !lastname || firstname === "" || !firstname) ||
                                        ((civility === civilityInitial) && (lastname === lastnameInitial) && (firstname === firstnameInitial))
                                    }
                                    handleSubmit={() => handleUpdateAccount()}
                                    isLoading={isUpdateAccountLoading}
                                />
                            </form>
                        </div>
                    </div>
                    <div className="privatepages--twocolumns privatepages--twocolumns__right">
                        <form className="privatepages--form__block">
                            <h4 className="privatepages--form__title">Votre mot de passe</h4>
                            <div className="homepage--popup__passwordinputcontainer">
                                <img src={inputType === "password" ? EyeIconCrossedOut : EyeIcon} alt="" className={inputType === "password" ? "homepage--popup__icon homepage--popup__iconcrossedout privatepages--eyeicon__crossedout" : "homepage--popup__icon privatepages--eyeicon"} onClick={() => onClickEyeIcon()} />
                                <FormInput
                                    label="Mot de passe"
                                    type={inputType}
                                    id="password"
                                    onChangeInput={value => { handlePasswordClick("password", value) }}
                                    placeholder={inputType === "password" ? "∗∗∗∗∗∗∗∗∗∗∗∗∗∗" : "15eegegeàç_àde"}
                                    defaultValue={password}
                                    value={password}
                                />
                            </div>
                            <div className="homepage--popup__passwordinputcontainer">
                                <img src={inputType === "password" ? EyeIconCrossedOut : EyeIcon} alt="" className={inputType === "password" ? "homepage--popup__icon homepage--popup__iconcrossedout privatepages--eyeicon__crossedout" : "homepage--popup__icon privatepages--eyeicon"} onClick={() => onClickEyeIcon()} />
                                <FormInput
                                    label="Mot de passe (confirmation)"
                                    type={inputType}
                                    id="passwordconfirmation"
                                    onChangeInput={value => { handlePasswordClick("passwordconfirmation", value) }}
                                    placeholder={inputType === "password" ? "∗∗∗∗∗∗∗∗∗∗∗∗∗∗" : "15eegegeàç_àde"}
                                    value={passwordConfirmation}
                                    defaultValue={passwordConfirmation}
                                    additionnalClassName="homepage--popup__inputmarginbottom14"
                                />
                            </div>
                            {showErrorMessageUpdatePassword && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageUpdatePassword}</span>}
                            {showConfirmationMessageUpdatePassword && <span className="homepage--popup__message homepage--popup__messageconfirmation">{confirmationMessageUpdatePassword}</span>}
                            <Button
                                cta="Modifier"
                                disabled={!validPassword.test(password) || !validPassword.test(passwordConfirmation) || showErrorMessageUpdatePassword}
                                handleSubmit={() => handleUpdatePassword()}
                                isLoading={isUpdatePasswordLoading}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    )

}

export default UpdateAccount;