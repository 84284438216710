import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import EnhancedTableHead from '../../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../../Components/TableComponents/StyledTableCell'
import { headCellsTurpePuissance } from '../../../Utils/TableUtils/headCells'
import { Tooltip } from '@mui/material'

function TurpeDetailTable(props) {

    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        headCells={headCellsTurpePuissance}
                    />
                    <TableBody>
                        {props.rows.map((row, index) => {
                            return (
                                <TableRow key={`sites-${index}`}>
                                    <StyledTableCell>
                                        <Tooltip title={row.title} enterTouchDelay={0}>
                                            <span className={row.type === "optimisee" ? "turpe--optimisee" : ""}>{row.title && row.title}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span className={row.type === "optimisee" ? "turpe--optimisee" : ""}>{row.pointe && row.pointe}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span className={row.type === "optimisee" ? "turpe--optimisee" : ""}>{row.hph && row.hph}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span className={row.type === "optimisee" ? "turpe--optimisee" : ""}>{row.hch && row.hch}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span className={row.type === "optimisee" ? "turpe--optimisee" : ""}>{row.hpe && row.hpe}</span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span className={row.type === "optimisee" ? "turpe--optimisee" : ""}>{row.hce && row.hce}</span>
                                    </StyledTableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell sx={{ padding: "5px" }} colSpan={6} />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box >
    )

}

export default TurpeDetailTable;