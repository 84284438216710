import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';
import LoadingPage from '../Pages/LoadingPage';

function PublicRoute({ children }) {
    const [isAuthenticated, loading] = useAuth()

    if (loading) {
        return <LoadingPage />
    }

    return isAuthenticated ? <Navigate to={"/dashboard"} /> : <>{children} </>
}

export default PublicRoute;