import * as React from 'react'
import { Link } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { getComparator } from '../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../Utils/SortingUtils/stableSort'
import EnhancedTableHead from '../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../Components/TableComponents/StyledTableCell'
import { headCellsAppelOffre } from '../../Utils/TableUtils/headCells'
import Open from '../../img/icons/suppr.svg'
import OpenRed from '../../img/icons/open-red.svg'
import { Tooltip } from '@mui/material'

function AppelsOffreTable(props) {
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('id')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, props.rows],
    )

    return (
        <Box sx={{
            width: '100%',
            marginBottom: '16px',
            border: props.isReengagement ? '1px solid #48AB4D' : '1px solid #EFEFEF',
            backgroundColor: props.isReengagement ? 'rgba(72, 171, 77, 0.1)' : '#F9F9F9',
            borderRadius: '10px',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        headCells={headCellsAppelOffre(!props.isGaz, props.isReengagement)}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {

                            return (
                                <TableRow key={`appeloffretable-${row.siteID}-${index}`}>
                                    <StyledTableCell>{row.libelle && row.libelle}</StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title={`${row.adresse} ${row.codePostal} ${row.ville}`} enterTouchDelay={0}>
                                            <span>{row.adresse && `${row.adresse} ${row.codePostal} ${row.ville}`}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    {props.isGaz ?
                                        <StyledTableCell>{row.pce && row.pce}</StyledTableCell>
                                        :
                                        <StyledTableCell>{row.prm && row.prm}</StyledTableCell>
                                    }
                                    <StyledTableCell>
                                        <Tooltip title={row.fournisseur} enterTouchDelay={0}>
                                            <span>{row.fournisseur && row.fournisseur}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>{(row.dateDebutFourniture && row.dateDebutFourniture !== "0001-01-01T00:00:00") && new Date(row.dateDebutFourniture).toLocaleDateString()}</StyledTableCell>
                                    <StyledTableCell>{(row.dateFinNouveauContrat && row.dateFinNouveauContrat !== "0001-01-01T00:00:00") && new Date(row.dateFinNouveauContrat).toLocaleDateString()}</StyledTableCell>
                                    <StyledTableCell>
                                        {props.isReengagement
                                            ?
                                            <div className="table--icon__container" onClick={() => props.handleReengagement(row.siteID)}>
                                                <img alt="Modify" src={Open} className="table--icon" />
                                            </div>
                                            :
                                            <Link to={`/modify-sites-1?type=${props.isGaz ? "gaz" : "elec"}&id=${row.siteID}&from=appels-offre`}>
                                                <div className="table--icon__container global--color__green">{row.isConfirmed ? "Visualiser" : "Finaliser l'inscription"}<img alt="Modify" src={Open} className="table--icon" /></div>
                                            </Link>
                                        }

                                    </StyledTableCell>
                                    {!props.isReengagement &&
                                        <StyledTableCell>{row.resultType !== "None" &&
                                            <Link to={`/appels-offre-${props.isGaz ? "gaz" : "elec"}-recap?appelOffresID=${props.appelOffresID}&siteID=${row.siteID}`}>
                                                {(row.resultType === "Indicatif" && !props.isGaz) ?
                                                    <div className="appeloffre--result__indicatif">
                                                        Action attendue
                                                        <img alt="Ouvrir" src={OpenRed} className="table--icon" />
                                                    </div>
                                                    :
                                                    <div className="table--icon__container">
                                                        <img alt="Modifier" src={Open} className="table--icon" />
                                                    </div>
                                                }
                                            </Link>}
                                        </StyledTableCell>
                                    }
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell sx={{ padding: "5px" }} colSpan={6} />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Lignes par page"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                sx={{
                    '.MuiTablePagination-toolbar': {
                        color: "#18181b !important",
                    },
                    '.MuiTablePagination-selectLabel': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-select': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-displayedRows': {
                        fontFamily: "Plus Jakarta Sans, sans-serif !important",
                        color: "#18181b !important",
                    },
                }}
            />
        </Box>
    )
}

export default AppelsOffreTable