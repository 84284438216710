import * as React from 'react'
import { useNavigate } from 'react-router'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { getComparator } from '../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../Utils/SortingUtils/stableSort'
import { StyledTableCell } from '../../Components/TableComponents/StyledTableCell'
import Gaz from '../../img/icons/gaz.svg'
import Elec from '../../img/icons/elec.svg'

function SearchTable(props) {
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const navigate = useNavigate()

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        props.setPage(0)
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator('asc', 'id')).slice(
                props.page * rowsPerPage,
                props.page * rowsPerPage + rowsPerPage,
            ),
        [props.page, rowsPerPage, props.rows],
    )

    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        "& .MuiTableRow-root:hover": {
                            backgroundColor: "#F9F9F9"
                        }
                    }}
                >
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            let link = ""
                            if (props.isAppelOffres) link = `/appels-offre-${row.type === "Gaz" ? "gaz" : "elec"}`
                            if (props.isSites) link = `/modify-sites-1?type=${row.type === "Gaz" ? "gaz" : "elec"}&id=${row.siteID}`
                            if (props.isContracts) link = `/contrats-${row.type === "Gaz" ? "gaz" : "elec"}`
                            if (props.isConso) link = `/conso-detail?type=${row.releveType === "Horaire" ? "CDC" : "Mensuel"}&siteID=${row.siteID}`

                            return (
                                <TableRow
                                    key={`searchtable-${index}`}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate(link)}
                                >
                                    <StyledTableCell><img src={row.type === "Gaz" ? Gaz : Elec} alt="Type du site" /></StyledTableCell>
                                    {props.isConso && <StyledTableCell>{row.siteID}</StyledTableCell>}
                                    {props.isAppelOffres && <StyledTableCell>{row.appelOffre}</StyledTableCell>}
                                    <StyledTableCell>{row.libelle}</StyledTableCell>
                                    <StyledTableCell>{row.adresse}</StyledTableCell>
                                    {props.isContracts && <StyledTableCell>{row.contrats} contrats</StyledTableCell>}
                                    {(props.isAppelOffres || props.isSites) && <StyledTableCell>{row.identifiant}</StyledTableCell>}
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell sx={{ padding: "5px" }} colSpan={6} />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {props.rows.length > 5 && <TablePagination
                labelRowsPerPage="Lignes par page"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={props.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                sx={{
                    '.MuiTablePagination-toolbar': {
                        color: "#18181b !important",
                    },
                    '.MuiTablePagination-selectLabel': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-select': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-displayedRows': {
                        fontFamily: "Plus Jakarta Sans, sans-serif !important",
                        color: "#18181b !important",
                    },
                }}
            />}
        </Box>
    );
}

export default SearchTable;