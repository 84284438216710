import SideBar from "../../../Components/SideBar/SideBar"
import { useState, useEffect, useCallback } from "react"
import axios from "axios"
import MandateTable from "./MandateTable"
import { SearchBar } from "../../../Components/SearchBar"
import { removeAccents } from "../../../Utils/Regex/removeAccents"
import ArrowUp from '../../../img/icons/arrow-up.svg'
import ArrowDown from '../../../img/icons/arrow-down.svg'

const apiUrl = process.env.REACT_APP_API_URL

function Mandates() {
    const [sitesGaz, setSitesGaz] = useState([])
    const [filteredSitesGaz, setFilteredSitesGaz] = useState([])
    const [showGaz, setShowGaz] = useState(false)

    const [sitesElec, setSitesElec] = useState([])
    const [filteredSitesElec, setFilteredSitesElec] = useState([])
    const [showElec, setShowElec] = useState(false)

    const [isDataLoading, setIsDataLoading] = useState(false)

    const [searchValueGaz, setSearchValueGaz] = useState("")
    const [searchValueElec, setSearchValueElec] = useState("")

    const fetchMandates = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/mandat`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                setSitesGaz(response.data.gaz)
                setFilteredSitesGaz(response.data.gaz)

                setSitesElec(response.data.elec)
                setFilteredSitesElec(response.data.elec)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [])

    useEffect(() => {
        fetchMandates()
    }, [fetchMandates])

    const getPdfFile = async (documentID, fileName) => {
        axios.get(`${apiUrl}/document/${documentID}`,
            {
                withCredentials: true,
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error.response && error.response && error.response.status && error.response.status === 401) {
                    window.location.reload()
                }
            })
    }

    const filterContracts = (query, type) => {
        if (!query || query === "") {
            if (type === "gaz") setFilteredSitesGaz(sitesGaz)
            if (type === "elec") setFilteredSitesElec(sitesElec)
        }
        else {
            const keys = ['site', 'mandats']
            let sitesCopy = type === "gaz" ? JSON.parse(JSON.stringify(sitesGaz)) : JSON.parse(JSON.stringify(sitesElec))
            let results = sitesCopy.filter((row, index) => (
                keys.some((key) => {
                    if (key === "mandats") {
                        const sitekeys = ['raisonSociale', 'libelle', 'fichier', 'documentID', 'dateFinNouveauContrat', 'dateDebutFourniture', 'clientDateSignature']
                        const filteredRow = row["mandats"].filter((siteRow) => (
                            sitekeys.some((siteKey) => {
                                const k = siteRow?.[siteKey] ?? ''
                                return (removeAccents(k.toString()).includes(removeAccents(query.toString())))
                            }
                            ))
                        )
                        row["mandats"] = filteredRow
                        return filteredRow.length > 0
                    }
                    if (key === "site") {
                        let containsValue = []
                        for (const [key, value] of Object.entries(row["site"])) {
                            if (value && removeAccents(value.toString()).includes(removeAccents(query.toString()))) {
                                containsValue.push(true)
                            }
                            else containsValue.push(false)
                        }
                        return !(containsValue.every(element => element === false))
                    }
                    return null
                }
                )))
            if (type === "gaz") setFilteredSitesGaz(results)
            if (type === "elec") setFilteredSitesElec(results)
        }
    }

    return (
        <>
            <SideBar
                active="Mandates"
                openAccount />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Mes mandats Wattvalue</h3>
                <div className="privatepages--separator"></div>
                <div className={`table--tablecontainer ${(sitesGaz.length > 0 && sitesElec.length > 0) ? "mandates--block__container" : ""}`}>
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {
                                sitesElec.length > 0 &&
                                <div className="mandates--container">
                                    <div className="mandates--header" onClick={() => {
                                        filterContracts("", "elec")
                                        setShowElec(!showElec)
                                    }}>
                                        <h5 className="sepa--table__title">Sites Électricité</h5>
                                        <img src={showElec ? ArrowUp : ArrowDown} alt="Plier/déplier la catégorie" className="appeloffre--header__dropdownicon mandates--header__arrow" />
                                    </div>
                                    {showElec &&
                                        <>
                                            <SearchBar
                                                id="sitespage"
                                                additionnalFormClassName="searchbar--siteslist gaz--searchbar table--form"
                                                filterData={filterContracts}
                                                setValue={setSearchValueElec}
                                                value={searchValueElec}
                                                type="elec"
                                            />
                                            {(filteredSitesElec.length > 0) ?
                                                filteredSitesElec.map((site, index) => {
                                                    return (
                                                        <div className="mandates--container" key={`mandates-site-${index}`}>
                                                            <h4 className="mandates--site__title">{`Site`}{site.site.libelle ? ` : ${site.site.libelle}${site.site.adresse ? `, ${site.site.adresse}` : ""} (${site.site.codePostal ? site.site.codePostal : ""} ${site.site.ville ? site.site.ville : ""})` : ""}</h4>
                                                            {site.mandats.length > 0 ?
                                                                <MandateTable
                                                                    rows={site.mandats}
                                                                    getPdfFile={getPdfFile} />
                                                                :
                                                                <span>Aucun mandat trouvé.</span>
                                                            }
                                                        </div>
                                                    )
                                                })
                                                :
                                                <span>Aucun site électricité trouvé.</span>}
                                        </>}
                                </div>
                            }
                            {sitesGaz.length > 0 &&
                                <div className="mandates--container">
                                    <div className="mandates--header" onClick={() => {
                                        filterContracts("", "gaz")
                                        setShowGaz(!showGaz)
                                    }}>
                                        <h5 className="sepa--table__title">Sites Gaz</h5>
                                        <img src={showGaz ? ArrowUp : ArrowDown} alt="Plier/déplier la catégorie" className="appeloffre--header__dropdownicon mandates--header__arrow" />
                                    </div>
                                    {showGaz &&
                                        <>
                                            <SearchBar
                                                id="sitespage"
                                                additionnalFormClassName="searchbar--siteslist gaz--searchbar table--form"
                                                filterData={filterContracts}
                                                setValue={setSearchValueGaz}
                                                value={searchValueGaz}
                                                type="gaz"
                                            />
                                            {(filteredSitesGaz.length > 0) ?
                                                filteredSitesGaz.map((site, index) => {
                                                    return (
                                                        <div className="mandates--container" key={`mandates-site-${index}`}>
                                                            <h4 className="mandates--site__title">{`Site`}{site.site.libelle ? ` : ${site.site.libelle}${site.site.adresse ? `, ${site.site.adresse}` : ""} (${site.site.codePostal ? site.site.codePostal : ""} ${site.site.ville ? site.site.ville : ""})` : ""}</h4>
                                                            {site.mandats.length > 0 ?
                                                                <MandateTable
                                                                    rows={site.mandats}
                                                                    getPdfFile={getPdfFile} />
                                                                :
                                                                <span>Aucun mandat trouvé.</span>
                                                            }
                                                        </div>
                                                    )
                                                })
                                                :
                                                <span>Aucun site gaz trouvé.</span>}
                                        </>
                                    }
                                </div >
                            }
                            {
                                (sitesGaz.length === 0 && sitesElec.length === 0) &&
                                <span>Aucun site trouvé.</span>
                            }
                        </>
                    }
                </div >
            </div >
        </>
    )
}

export default Mandates;