export const lineColorsEnergy = [
    "#1B5E20",
    "#1565C0",
    "#FF5722",
    "#8E24AA",
    "#D32F2F",
    "#FBC02D",
    "#00796B",
    "#795548",
    "#C2185B",
    "#5D4037",
]

export const lineColorsEuros = [
    "#8DAF90",
    "#8AB2E0",
    "#FFAB91",
    "#C792D5",
    "#E99797",
    "#FDE096",
    "#80BCB5",
    "#BCAAA4",
    "#E18CAD",
    "#A0A0A0",
]