import Select from 'react-select'
import Upload from '../../img/icons/upload.svg'

const FormInput = (props) => {
    let inputClassName = "homepage--popup__input"
    let labelClassName = "homepage--popup__inputlabel"
    if (props.additionnalClassName) inputClassName = inputClassName + " " + props.additionnalClassName
    if (props.additionnalLabelClassName) labelClassName = labelClassName + " " + props.additionnalLabelClassName

    const dropDownStyles = {
        control: (styles, { isSelected }) => ({
            ...styles,
            backgroundColor: 'white',
            fontFamily: "Arial",
            fontSize: "13px",
            border: isSelected ? "1px solid black" : "1px solid #EFEFEF",
            boxShadow: 'none',
            "&:hover": {
                borderColor: "black",
            }
        }),
        option: (styles, { isFocused }) => {
            return {
                ...styles,
                fontFamily: "Arial",
                fontSize: "13px",
                backgroundColor: isFocused ? "#FAFAFA" : "white",
                color: "#98A1AC",
            }
        },
    }

    if (props.type === "dropdown") {

        return (
            <div className={props.divClassName}>
                {props.inputLabel && <label htmlFor={props.id} className={labelClassName}>{props.inputLabel}</label>}
                <Select
                    id={props.id}
                    placeholder={props.placeholder}
                    options={props.options}
                    styles={dropDownStyles}
                    onChange={(e) => props.onChangeInput(e)}
                    isSearchable={props.isSearchable}
                    value={props.value ? { label: (props.label && props.label.length > 0) ? props.label : props.value, value: props.value } : null}
                    className={props.dropdownClassName ? props.dropdownClassName : ""}
                />
            </div>
        )
    }
    if (props.type === "textarea") {
        return (
            <>
                <label htmlFor={props.id} className={labelClassName}>{props.label}</label>
                <textarea
                    id={props.id}
                    name={props.id}
                    className="homepage--popup__textarea"
                    onChange={(e) => props.onChangeInput(e.target.value)}
                    placeholder={props.placeholder}
                    value={props.value}
                />
            </>
        )
    }
    if (props.type === "file") {

        return (
            <div className="homepage--fileinput__container">
                <label htmlFor={props.id} className={`homepage--fileinput${(props.fileName || props.disabled) ? " homepage--fileinput__selected" : ""} ${props.fileClassName ? props.fileClassName : ""}`}>
                    {props.fileName ? props.fileName : props.placeholder ? props.placeholder : "Importer un fichier*"}
                </label>
                <input
                    disabled={props.disabled}
                    id={props.id} type="file"
                    onClick={e => props.onClick(e)}
                    name={props.id}
                    onChange={e => props.onChangeInput(e.target.files[0])} />
                {!props.noIcon && <img src={Upload} alt="Upload" className="homepage--fileinput__upload" />}
            </div>
        )
    }
    if (props.type === "checkbox") {
        return (
            <div className="homepage--checkbox__container">
                <input
                    className="homepage--checkbox"
                    type="checkbox"
                    id={props.nom}
                    name={props.nom}
                    checked={props.isSelected}
                    onChange={props.selectBox}
                    disabled={props.disabled} />
                <label htmlFor={props.nom} className={labelClassName}>{props.nom}</label>
            </div>
        )
    }
    else {
        return (
            <>
                <label htmlFor={props.id} className={labelClassName}>{props.label}</label>
                <input type={props.type}
                    id={props.id}
                    autoComplete={props.autoComplete}
                    onChange={(e) => props.onChangeInput(e.target.value)}
                    placeholder={props.placeholder}
                    value={props.value ? props.value : ""}
                    htmlFor={props.id}
                    className={inputClassName}
                    maxLength={props.maxLength}
                    pattern={props.pattern}
                    onKeyDown={props.handleSubmit ? (e) => {
                        if (e.key === "Enter")
                            props.handleSubmit()
                    } : null}
                    disabled={props.disabled}
                    name={props.id}
                />
            </>
        )
    }
}

export default FormInput;