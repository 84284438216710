import { useState, useCallback, useEffect, Fragment } from "react"
import { Link } from 'react-router-dom'
import SideBar from "../../../Components/SideBar/SideBar"
import CircleIcon from '@mui/icons-material/Circle'
import Stats from '../../../img/icons/stats.svg'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, PieChart, Pie, Cell } from 'recharts'
import { ConsoCustomTooltip } from "../Conso/ConsoCustomTooltip"
import FormInput from "../../../Components/Form/FormInput"
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import { budgetColors } from '../../../Utils/GraphUtils/budgetColors'
import BudgetSitesTable from "./BudgetSitesTable"
import { SearchBar } from "../../../Components/SearchBar"
import { removeAccents } from "../../../Utils/Regex/removeAccents"
import DownloadXlsx from "../../../Utils/TableUtils/DownloadXlsx"
import axios from "axios"

const apiUrl = process.env.REACT_APP_API_URL

function Budget() {
    const [showMore, setShowMore] = useState(false)
    const [isDataLoading, setIsDataLoading] = useState(false)

    const [data, setData] = useState([])
    const [sites, setSites] = useState([])
    const [filteredSites, setFilteredSites] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [yearsOptions, setYearsOptions] = useState([])
    const [yearsQuery, setYearsQuery] = useState(0)

    const [totalEnergie, setTotalEnergie] = useState(0)
    const [totalAcheminement, setTotalAcheminement] = useState(0)
    const [totalTaxes, setTotalTaxes] = useState(0)
    const [totalHTVA, setTotalHTVA] = useState(0)
    const [previousTotalHTVA, setPreviousTotalHTVA] = useState(0)

    const [showFilters, setShowFiltrers] = useState(false)

    const [dropdownOptions, setDropdownOptions] = useState([])
    const [dropdownValue, setDropdownValue] = useState("cumul")
    const [dropdownLabel, setDropdownLabel] = useState("Cumul")

    const CircleSx = { fontSize: "9px", marginRight: "5px", color: "#48AB4D" }

    const getBudgetInfo = useCallback(() => {
        axios.get(`${apiUrl}/Conso/Budget/Info`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                if (response.data?.annees?.length > 0) {
                    setYearsQuery([response.data.annees[0]])
                    setYearsOptions(response.data.annees)
                }

                if (response.data?.sites?.length > 0) {
                    let sites = []
                    sites.push({ label: "Cumul", value: "cumul" })
                    response.data.sites.forEach(site => {
                        sites.push({ label: `${site.libelle ? site.libelle : ""}${site.siteID ? ` (id ${site.siteID})` : ""}`, value: site.siteID })
                    })

                    setDropdownOptions(sites)
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [])

    const getGraphData = useCallback(() => {
        setPreviousTotalHTVA(0)
        setTotalEnergie(0)
        setTotalAcheminement(0)
        setTotalTaxes(0)
        setTotalHTVA(0)
        if (yearsQuery.length > 0) {
            let dataCopy = [
                { moisLabel: "Janv.", moisNombre: 1, mois: "Janvier" },
                { moisLabel: "Févr.", moisNombre: 2, mois: "Fevrier" },
                { moisLabel: "Mars", moisNombre: 3, mois: "Mars" },
                { moisLabel: "Avr.", moisNombre: 4, mois: "Avril" },
                { moisLabel: "Mai", moisNombre: 5, mois: "Mai" },
                { moisLabel: "Juin", moisNombre: 6, mois: "Juin" },
                { moisLabel: "Juill.", moisNombre: 7, mois: "Juillet" },
                { moisLabel: "Août", moisNombre: 8, mois: "Aout" },
                { moisLabel: "Sept.", moisNombre: 9, mois: "Septembre" },
                { moisLabel: "Oct.", moisNombre: 10, mois: "Octobre" },
                { moisLabel: "Nov.", moisNombre: 11, mois: "Novembre" },
                { moisLabel: "Déc.", moisNombre: 12, mois: "Decembre" }
            ]
            yearsQuery.forEach((year, yearIndex) => {
                axios.get(`${apiUrl}/Conso/Budget/Total?annees=${year}${dropdownValue !== "cumul" ? `&ids=${dropdownValue}` : ""}`,
                    {
                        headers: {
                            'Accept': 'text/plain',
                        },
                        withCredentials: true,
                    })
                    .then((response) => {
                        if (response.data.length > 0) {
                            response.data[0]?.mois?.forEach((mois, index) => {
                                if (dataCopy[index].moisNombre === mois.mois) {
                                    dataCopy[index][`totalConsommation${year}`] = parseFloat(mois.totalConsommation.valeur)
                                    dataCopy[index][`totalEnergie${year}`] = parseFloat(mois.totalEnergie.valeur)
                                    dataCopy[index][`totalAcheminement${year}`] = parseFloat(mois.totalAcheminement.valeur)
                                    dataCopy[index][`totalTaxes${year}`] = parseFloat(mois.totalTaxes.valeur)
                                    dataCopy[index][`totalHTVA${year}`] = parseFloat(mois.totalHTVA.valeur)
                                }
                            })

                            if (yearIndex === 0) {
                                let totalEnergie = 0
                                let totalAcheminement = 0
                                let totalTaxes = 0
                                let totalHTVA = 0

                                dataCopy.forEach(element => {
                                    totalEnergie += parseFloat(element[`totalEnergie${year}`])
                                    totalAcheminement += parseFloat(element[`totalAcheminement${year}`])
                                    totalTaxes += parseFloat(element[`totalTaxes${year}`])
                                    totalHTVA += parseFloat(element[`totalHTVA${year}`])
                                })

                                setTotalEnergie(totalEnergie)
                                setTotalAcheminement(totalAcheminement)
                                setTotalTaxes(totalTaxes)
                                setTotalHTVA(totalHTVA)

                                if (yearsOptions.includes(year - 1)) {
                                    getTotalPreviousYear(year - 1)
                                }
                            }

                            dataCopy.forEach(element => {
                                element.yearLabel = `${element.moisLabel}`
                            })

                            setData(dataCopy)
                        }
                        else {
                            setData([])
                        }
                    })
                    .catch(error => {
                        if (error?.response?.status === 401) {
                            window.location.reload()
                        }
                    })
                    .finally(() => setIsDataLoading(false))
            })
            setIsDataLoading(true)
        }
    }, [yearsQuery, yearsOptions, dropdownValue])

    const getTotalPreviousYear = (previousYear) => {
        setPreviousTotalHTVA(0)
        axios.get(`${apiUrl}/Conso/Budget/Total?annees=${previousYear}`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                let totalHTVA = 0
                response.data[0]?.mois?.forEach((mois) => {
                    totalHTVA += parseFloat(mois.totalHTVA.valeur)
                })
                setPreviousTotalHTVA(totalHTVA)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getTableData = useCallback(() => {
        let siteData = []
        axios.get(`${apiUrl}/Conso/Budget/Detail`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                if (response.data.length > 0) {
                    response.data.forEach((year, yearIndex) => {
                        if (year.sites?.length > 0) {
                            year.sites.forEach((element) => {
                                let site = [
                                    { type: 'Consommation en kWh' },
                                    { type: 'Coût énergie (kWh, CEE, Capacité, Abonnements en €)' },
                                    { type: 'Coût acheminement' },
                                    { type: 'Coût Taxes (CTA, TLCFE, CSPE) en €' },
                                    { type: 'Budget consolidé HTVA' },
                                    { type: 'Budget consolidé TTC' },
                                ]

                                if (element.mois?.length > 0) {
                                    element.mois.forEach(mois => {
                                        site[0][`data${mois.mois}`] = parseFloat(mois.totalConsommation.valeur)
                                        site[1][`data${mois.mois}`] = parseFloat(mois.totalEnergie.valeur)
                                        site[2][`data${mois.mois}`] = parseFloat(mois.totalAcheminement.valeur)
                                        site[3][`data${mois.mois}`] = parseFloat(mois.totalTaxes.valeur)
                                        site[4][`data${mois.mois}`] = parseFloat(mois.totalHTVA.valeur)
                                        site[5][`data${mois.mois}`] = parseFloat(mois.totalTTC.valeur)

                                        site[0][`ecart${mois.mois}`] = parseFloat(mois.totalConsommation.ecart)
                                        site[1][`ecart${mois.mois}`] = parseFloat(mois.totalEnergie.ecart)
                                        site[2][`ecart${mois.mois}`] = parseFloat(mois.totalAcheminement.ecart)
                                        site[3][`ecart${mois.mois}`] = parseFloat(mois.totalTaxes.ecart)
                                        site[4][`ecart${mois.mois}`] = parseFloat(mois.totalHTVA.ecart)
                                        site[5][`ecart${mois.mois}`] = parseFloat(mois.totalTTC.ecart)
                                    })
                                }

                                site.push({ type: 'site data', year: year.annee, adresse: `${element.adresse} ${element.codePostal} ${element.ville}`, prm: element.prm, dateModification: element.dateModification, siteID: element.siteID, label: element.libelle })

                                siteData.push(site)
                            })
                        }
                    })
                }
                siteData.forEach(year => {
                    year.forEach(line => {
                        let dataTotal = 0
                        let ecartTotal = 0
                        for (let i = 0; i < 13; i++) {
                            if (line[`data${i}`]) dataTotal += parseFloat(line[`data${i}`])
                            if (line[`ecart${i}`]) ecartTotal += parseFloat(line[`ecart${i}`])
                        }
                        line.dataTotal = dataTotal
                        line.ecartTotal = ecartTotal
                    })
                })

                setSites(siteData)
                setFilteredSites(siteData)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))

        setIsDataLoading(true)

    }, [yearsOptions])

    useEffect(() => {
        getBudgetInfo()
    }, [getBudgetInfo])

    useEffect(() => {
        getGraphData()
    }, [getGraphData, yearsQuery])

    useEffect(() => {
        getTableData()
    }, [getTableData, yearsOptions])

    const selectAllYears = () => {
        if (yearsOptions.every(year => yearsQuery.includes(year))) {
            setYearsQuery([])
        }
        else {
            let newYearsQuery = []
            yearsOptions.forEach(year => newYearsQuery.push(year))
            setYearsQuery(newYearsQuery)
        }
    }

    const changeYearsQuery = (year) => {
        let yearArray = [...yearsQuery]
        if (yearsQuery.includes(year)) {
            yearArray.splice(yearsQuery.indexOf(year), 1)
        }
        else {
            yearArray.push(year)
        }
        setYearsQuery(yearArray)
    }

    const handleDataExport = () => {
        let dataArray = []
        filteredSites.forEach((site) => {
            site.forEach((element, index) => {
                let line = {}
                if (index === 6) {
                    line.type = `${element.year ? `Année ${element.year} | ` : ""}Site${element.label ? ` ${element.label}` : ""} id ${element.siteID} ${element.prm ? `| PRM : ${element.prm}` : ""} ${element.adresse ? `| Adresse : ${element.adresse}` : ""} ${element.dateModification ? `(màj le ${new Date(element.dateModification).toLocaleDateString()})` : ""}`
                    dataArray.unshift({ "type": '', "data1": 'Janvier', "data2": 'Février', "data3": 'Mars', "data4": 'Avril', "data5": 'Mai', "data6": "Juin", "data7": 'Juillet', "data8": 'Août', "data9": 'Septembre', "data10": 'Octobre', "data11": 'Novembre', "data12": 'Décembre', "dataTotal": 'Total', "ecartTotal": 'Ecart' })
                }
                else {
                    for (const [key, value] of Object.entries(element)) {
                        if (!key.includes("ecart") || key === "ecartTotal") line[`${key}`] = value
                    }
                }
                dataArray.unshift(line)
            })
        })
        return (dataArray)
    }

    const filterSites = (query) => {
        if (!query || query === "") {
            setFilteredSites(sites)
        }
        else {
            let results = sites.filter((row) => {
                let containsValue = []
                for (const [key, value] of Object.entries(row[6])) {
                    if (value && removeAccents(value.toString()).includes(removeAccents(query.toString()))) {
                        containsValue.push(true)
                    }
                    else containsValue.push(false)
                }
                return !(containsValue.every(element => element === false))
            })
            setFilteredSites(results)
        }
    }

    return (
        <>
            <SideBar
                openElec
                active="Budget" />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Budget Annuel Prévisionnel Electricité</h3>
                <div className="privatepages--separator"></div>
                {/* Bloc servant à afficher du texte qui peut être renseigné dans le Back Office par WattValue (valeur à ajouter dans la base de donnée) */}
                {/* <div className="elec--informationblock">
                    <div>
                        <div className="elec--informationblock__title">
                            <img src={Info} alt="Icone information" className="elec--informationblock__img" />Informations
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    </div>
                </div> */}
                <p className="turpe--withouttravaux__paragraph">
                    Cette page présente une estimation du budget annuel pour chacun de vos sites dont le contrat a été négocié par nos services. Les montants sont estimés à partir :
                </p>
                <ul>
                    <li className="turpe--withouttravaux__listitem"><CircleIcon sx={CircleSx} />De vos consommations sur les 12 derniers mois,</li>
                    <li className="turpe--withouttravaux__listitem"><CircleIcon sx={CircleSx} />De la réglementation connue à ce jour,</li>
                    <li className="turpe--withouttravaux__listitem"><CircleIcon sx={CircleSx} />Des derniers prix de l'énergie négociés avec les fournisseurs.</li>
                </ul>

                {showMore &&
                    <p className="turpe--withouttravaux__paragraph">
                        Pour les contrats indexés sur l'Arenh, le budget de l'année suivante est estimé selon le volume d'Arenh demandé l'an passé et les conditions de marché actuelles. Ces conditions seront connues et mise à jour définitivement en fin d'année pour l'année suivante.
                        <br />
                        <br />
                        Ces estimations de budget seront mises à jour en août de chaque année (période d'évolution réglementaire de l'acheminement) pour l'année suivante et entre janvier et février pour l'année en cours (période de confirmation des taxes, coûts de capacité et de l'Arenh).
                        <br />
                        <br />
                        Les budgets prévisionnels ci-dessous sont calculés en fonction de la réglementation connue au jour du calcul (taxes, acheminement, CEE, capacité). Ces données constituent un prévisionnel et non un budget facturé, les montants facturés et vérifiés se trouvent sur la page <Link to='/invoice-verification'><span className="elec--link">Vérification de factures</span></Link>.
                    </p>
                }
                <span className="budget--more turpe--more" onClick={() => setShowMore(!showMore)}>{showMore ? "- Afficher moins" : "+ Afficher plus"}</span>
                <div className="dashboard--indicators__top budget--indicators__top">
                    <div className="dashboard--indicators__left dashboard--indicators__blockcontainer">
                        <div className="table--tablecontainer mandates--block__container dashboard--indicators__righttop">
                            {showFilters &&
                                <div className="conso--filters__container budget--filtres__container">
                                    <h6 className="conso--filters__title">Filtres</h6>
                                    {yearsOptions.length > 0 &&
                                        <>
                                            <span className="conso--filters__subtitle">Années<span className="conso--filters__selectall" onClick={() => selectAllYears()}>Tout {yearsOptions.every(year => yearsQuery.includes(year)) ? "dé" : ""}sélectionner</span></span>
                                            <div className="privatepages--separator"></div>
                                            <div className="conso--filters__valuecontainers">
                                                {yearsOptions.map((year, index) => {
                                                    return (
                                                        <FormInput
                                                            type="checkbox"
                                                            key={`checkbox-yearoption-${index}`}
                                                            nom={year}
                                                            isSelected={yearsQuery.includes(year)}
                                                            selectBox={() => changeYearsQuery(year)}
                                                            additionnalLabelClassName="homepage--checkbox__label conso--filters__label"
                                                        />
                                                    )
                                                }
                                                )}
                                            </div>
                                        </>}
                                </div>
                            }
                            <div className="appeloffre--header__container conso--containers__bottom conso--containers__legend">
                                <span className="privatepages--title">Répartition annuelle par mois</span>
                                <div className="conso--header__periodedropdowns">
                                    {dropdownOptions.length > 1 ?
                                        <FormInput
                                            type="dropdown"
                                            id="site"
                                            placeholder="Choisir un site"
                                            onChangeInput={e => {
                                                setDropdownValue(e.value)
                                                setDropdownLabel(e.label)
                                            }}
                                            value={dropdownLabel}
                                            isSearchable
                                            options={dropdownOptions}
                                            divClassName="budget--dropdown"
                                        />
                                        :
                                        ""}
                                    {yearsOptions.length > 1 ?
                                        <div className="conso--filters__button" onClick={() => setShowFiltrers(!showFilters)}>
                                            Voir les filtres
                                            {showFilters
                                                ?
                                                <CloseIcon style={{ marginLeft: "5px" }} />
                                                :
                                                <MenuIcon style={{ marginLeft: "5px" }} />}
                                        </div>
                                        :
                                        ""}
                                </div>
                            </div>
                            {isDataLoading ?
                                <span className="global--loader"></span>
                                :
                                data.length > 0 ?
                                    <>
                                        <div className="conso--containers__bottom conso--containers__legend budget--graph__container">
                                            {yearsQuery.map((year, index) => {
                                                return (
                                                    <div className="budget--graph__containerbyyear" key={`legend-key-${index}-${year}`}>
                                                        <div className="conso--legend budget--legend"><div className="conso--legend__mark" style={{ backgroundColor: budgetColors[year % budgetColors.length][0] }}></div> Total énergie {year}</div>
                                                        <div className="conso--legend budget--legend"><div className="conso--legend__mark" style={{ backgroundColor: budgetColors[year % budgetColors.length][1] }}></div> Total acheminement {year}</div>
                                                        <div className="conso--legend budget--legend"><div className="conso--legend__mark" style={{ backgroundColor: budgetColors[year % budgetColors.length][2] }}></div> Total taxes {year}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className="conso--chart__container">
                                            {yearsQuery.length > 0 ?
                                                <ResponsiveContainer>
                                                    <BarChart data={data}>
                                                        <CartesianGrid strokeDasharray="2 2" />
                                                        <XAxis
                                                            dataKey="yearLabel"
                                                            style={{
                                                                fontSize: '11px',
                                                                fontFamily: 'Plus Jakarta Sans',
                                                                color: '#52525B'
                                                            }} />
                                                        <YAxis
                                                            style={{
                                                                fontSize: '11px',
                                                                fontFamily: 'Plus Jakarta Sans',
                                                                color: '#52525B'
                                                            }}>
                                                            <Label
                                                                value="Euros HTVA"
                                                                position="insideTopLeft"
                                                                offset={10}
                                                                dx={-15}
                                                                dy={10}
                                                                style={{
                                                                    fontSize: '11px',
                                                                    fontFamily: 'Plus Jakarta Sans',
                                                                    color: '#52525B'
                                                                }}
                                                            />
                                                        </YAxis>
                                                        <Tooltip content={<ConsoCustomTooltip datakey="yearLabel" />} cursor={{ fill: '#EFEFEF' }} />
                                                        {yearsQuery?.map((year, index) => {
                                                            return (
                                                                <Fragment key={`bar-key-${index}-${year}`}>
                                                                    <Bar dataKey={`totalEnergie${year}`} stackId={year} fill={budgetColors[year % budgetColors.length][0]} unit="€" name={`Total énergie ${year}`} />
                                                                    <Bar dataKey={`totalAcheminement${year}`} stackId={year} fill={budgetColors[year % budgetColors.length][1]} unit="€" name={`Total acheminement ${year}`} />
                                                                    <Bar dataKey={`totalTaxes${year}`} stackId={year} fill={budgetColors[year % budgetColors.length][2]} unit="€" name={`Total taxes ${year}`} />
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </BarChart>


                                                </ResponsiveContainer>
                                                :
                                                <span>Pas de donnée</span>
                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                        <span className="budget--nodata">Pas de donnée</span>
                                    </>
                            }
                        </div>
                    </div>
                    <div className="dashboard--indicators__right">
                        {totalHTVA ? <div className="table--tablecontainer budget--indicators__righttop">
                            <span className="dashboard--indicators__blocktitle">Budget annuel {yearsQuery[0] ? yearsQuery[0] : ""}</span>
                            {yearsQuery.length > 0 ?
                                <div className="dashboard--indicators__blocknumber budget--indicators__blocknumbers">
                                    <div>
                                        <img src={Stats} alt="Stats icon" width={19} height={19} className="dashboard--indicators__blockicon" />{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(totalHTVA)}
                                    </div>
                                    {previousTotalHTVA ?
                                        <div className={`dashboard--number ${(totalHTVA - previousTotalHTVA) > 0 ? "dashboard--number__up" : "dashboard--number__down"}`}>{new Intl.NumberFormat('fr-FR', { style: 'percent' }).format((totalHTVA - previousTotalHTVA) / previousTotalHTVA)}
                                            {(totalHTVA - previousTotalHTVA) > 0 ?
                                                <NorthIcon sx={{ color: "#FF0000", height: 15 }} />
                                                :
                                                <SouthIcon sx={{ color: "#48AB4D", height: 15 }} />
                                            }
                                        </div>
                                        : ""}
                                </div>
                                : <span>Pas de donnée</span>
                            }
                        </div>
                            :
                            ""
                        }
                        <div className="table--tablecontainer  budget--indicators__rightbottom">
                            <span className="budget--indicators__title">Répartition annuelle {yearsQuery[0] ? yearsQuery[0] : ""}</span>
                            {isDataLoading ?
                                <span className="global--loader"></span>
                                :
                                ((totalEnergie !== 0 && totalAcheminement !== 0 && totalTaxes !== 0) && yearsQuery.length > 0) ?
                                    <>
                                        <PieChart
                                            width={300}
                                            height={240}
                                        >
                                            <Tooltip content={<ConsoCustomTooltip datakey="value" noTitle />} cursor={{ fill: '#EFEFEF' }} />
                                            <Pie
                                                data={[
                                                    { value: totalEnergie, name: 'Coût énergie', unit: "€", percent: totalEnergie / totalHTVA, isCamembert: true },
                                                    { value: totalAcheminement, name: 'Coût acheminement', unit: "€", percent: totalAcheminement / totalHTVA, isCamembert: true },
                                                    { value: totalTaxes, name: 'Coût taxes', unit: "€", percent: totalTaxes / totalHTVA, isCamembert: true }
                                                ]}
                                                dataKey="value"
                                                nameKey="name"
                                                cx="50%"
                                                cy="50%"
                                                outerRadius={100}
                                            >
                                                {
                                                    [
                                                        { value: totalAcheminement, name: 'Coût acheminement', unit: "€", percent: totalAcheminement / totalHTVA },
                                                        { value: totalTaxes, name: 'Coût taxes', unit: "€", percent: totalTaxes / totalHTVA },
                                                        { value: totalEnergie, name: 'Coût énergie', unit: "€", percent: totalEnergie / totalHTVA },
                                                    ].map((entry, index) => <Cell key={`cell-key-${index}`} fill={budgetColors[yearsQuery[0] % budgetColors.length][index]} />)
                                                }
                                            </Pie>
                                        </PieChart>
                                        <div className="budget--graph__containerbyyear budget--graph__containerbyyearcamembert">
                                            <div className="conso--legend budget--legend budget--legend__camembert"><div className="conso--legend__mark" style={{ backgroundColor: budgetColors[yearsQuery[0] % budgetColors.length][0] }}></div> Coût énergie</div>
                                            <div className="conso--legend budget--legend budget--legend__camembert"><div className="conso--legend__mark" style={{ backgroundColor: budgetColors[yearsQuery[0] % budgetColors.length][1] }}></div> Coût acheminement</div>
                                            <div className="conso--legend budget--legend budget--legend__camembert"><div className="conso--legend__mark" style={{ backgroundColor: budgetColors[yearsQuery[0] % budgetColors.length][2] }}></div> Coût taxes</div>
                                        </div>
                                    </>
                                    : <span>Pas de donnée</span>
                            }
                        </div>
                    </div>
                </div>
                {sites.length > 0 ?
                    <div className="table--tablecontainer">
                        <span className="budget--table__title">Prévisionnel {yearsOptions.join(', ')}</span>
                        <div className="budget--table__header">
                            <SearchBar
                                id="budget"
                                additionnalBarClassName="budget--searchbar"
                                filterData={filterSites}
                                setValue={setSearchValue}
                                value={searchValue} />
                            {filteredSites.length > 0 &&
                                <DownloadXlsx
                                    data={handleDataExport()}
                                    fileName="sites.xlsx"
                                    additionnalClassName="global--button__black conso--export__button"
                                    headRow={[{ "A1": '' }, { "B1": '' }, { "C1": '' }, { "D1": '' }, { "E1": '' }, { "F1": '' }, { "G1": "" }, { "H1": '' }, { "I1": '' }, { "J1": '' }, { "K1": '' }, { "L1": '' }, { "M1": '' }, { "N1": '' }, { "O1": '' }, { "P1": '' }, { "Q1": '' }]}
                                />}
                        </div>
                        {filteredSites.length > 0 ?
                            <div className="budget--sitesarray__container">
                                {filteredSites.map((site, index) => {
                                    return (
                                        <div className="budget--site__container" key={`key-site-table-${index}`}>
                                            <div className="budget--site__header">
                                                <div className="budget--site__headercheckbox">
                                                    <span className="budget--table__data">{site[6].year ? `Année ${site[6].year} | ` : ""}Site{site[6].label ? ` ${site[6].label}` : ""} id {site[6].siteID} {site[6].prm ? `| PRM : ${site[6].prm}` : ""} {site[6].adresse ? `| Adresse : ${site[6].adresse}` : ""} {site[6].dateModification ? `(màj le ${new Date(site[6].dateModification).toLocaleDateString()})` : ""}</span>
                                                </div>
                                                <div className="privatepages--separator"></div>
                                            </div>
                                            <BudgetSitesTable
                                                rows={site}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            : <span>Aucun site trouvé</span>
                        }
                    </div>
                    :
                    ""
                }
            </div>
        </>
    )
}

export default Budget;