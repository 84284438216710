import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { getComparator } from '../../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../../Utils/SortingUtils/stableSort'
import EnhancedTableHead from '../../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../../Components/TableComponents/StyledTableCell'
import { headCellsConsoSites } from '../../../Utils/TableUtils/headCells'
import { Tooltip } from '@mui/material'
import Button from '../../../Components/Button'
import { Checkbox } from '@mui/material'
import Switch, { switchClasses } from '@mui/joy/Switch'

function ConsoSiteTable(props) {
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('appelOffre')
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        props.setPage(0)
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator(order, orderBy)).slice(
                props.page * rowsPerPage,
                props.page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, props.page, rowsPerPage, props.rows],
    )

    return (
        <div className="conso--containers conso--containers__bottom">
            {visibleRows.map((row, index) => {
                return (
                    <hr key={`dashed-line-${index}`} className={`hr--${index + 1}`} />
                )
            })}
            <div className="conso--containers__leftcolumn">
                <Box sx={{
                    width: '100%',
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none"
                    }
                }}>
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={props.rows.length}
                                headCells={headCellsConsoSites}
                                isConso
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {

                                    return (

                                        <TableRow key={`sites-${index}`}>
                                            <StyledTableCell sx={{ padding: "10px !important" }}>{row.siteID && row.siteID}</StyledTableCell>
                                            <StyledTableCell sx={{ maxWidth: "90px !important", padding: "10px !important" }}><Tooltip title={row.libelle} enterTouchDelay={0}>
                                                <span>{row.libelle && row.libelle}</span>
                                            </Tooltip></StyledTableCell>
                                            <StyledTableCell sx={{ maxWidth: "90px !important", padding: "10px !important" }}><Tooltip title={row.raisonSociale} enterTouchDelay={0}>
                                                <span>{row.raisonSociale && row.raisonSociale}</span>
                                            </Tooltip></StyledTableCell>
                                            <StyledTableCell sx={{ maxWidth: "150px !important", padding: "10px !important" }}><Tooltip title={row.adresse} enterTouchDelay={0}>
                                                <span>{row.adresse && row.adresse}</span>
                                            </Tooltip></StyledTableCell>
                                            <StyledTableCell sx={{ padding: "10px !important" }}><Tooltip title={row.periode} enterTouchDelay={0}>
                                                <span>{row.periode && row.periode}</span>
                                            </Tooltip></StyledTableCell>
                                        </TableRow>

                                    )
                                })}
                                <TableRow>
                                    <TableCell sx={{ padding: "5px" }} colSpan={6} />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        labelRowsPerPage="Lignes par page"
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={props.rows.length}
                        rowsPerPage={rowsPerPage}
                        page={props.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={({ from, to, count }) =>
                            `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                        sx={{
                            '.MuiTablePagination-toolbar': {
                                color: "#18181b !important",
                            },
                            '.MuiTablePagination-selectLabel': {
                                fontFamily: "Plus Jakarta Sans, sans-serif",
                            },
                            '.MuiTablePagination-select': {
                                fontFamily: "Plus Jakarta Sans, sans-serif",
                            },
                            '.MuiTablePagination-displayedRows': {
                                fontFamily: "Plus Jakarta Sans, sans-serif !important",
                                color: "#18181b !important",
                            },
                        }}
                    />
                </Box >
            </div>
            <div className="conso--containers__rightcolumns">
                <div className="privatepages--form__block conso--greyblock">
                    <div className="conso--containers__rightcolumns conso--containers__rightcolumnsbottom">
                        <span className="conso--containers__bottomtitle">Type de suivi</span>
                        {visibleRows.map((row, index) => {
                            return (
                                (row.releveType && row.releveType !== "Indisponible") ?
                                    <Button
                                        key={`type-buttons-${index}`}
                                        cta={row.releveType}
                                        additionnalClassName={`global--button__secondary conso--button ${index === 0 ? "conso--button__first" : ""}`}
                                        link={`/conso-detail?type=${(row.releveType === "Mensuel" || row.souscriptionType === "Renouveler") ? "Mensuel" : "CDC"}&siteID=${row.siteID}`}
                                    />
                                    :
                                    <div key={`type-buttons-${index}`} className={`conso--emptyblock conso--button ${index === 0 ? "conso--button__first" : ""}`}> </div>)
                        }
                        )}
                    </div>
                    <div className="conso--containers__rightcolumns conso--containers__rightcolumnsbottom">
                        <span className="conso--containers__bottomtitle">Option heure par heure</span>
                        {visibleRows.map((row, index) => {

                            return (
                                row.souscriptionType ?
                                    <Button
                                        key={`type-buttons-${index}`}
                                        cta={row.souscriptionType === "EnCours" ? "En cours" : row.souscriptionType}
                                        additionnalClassName={`global--button__secondary conso--button conso--buttonoption ${(row.souscriptionType === "Renouveler" || row.souscriptionType === "Souscrire") ? "conso--buttonoption__withborder" : ""} ${index === 0 ? "conso--buttonoption__first" : ""}`}
                                        link={(row.souscriptionType === "Souscrire" || row.souscriptionType === "Renouveler") ? `/${row.souscriptionType === "Souscrire" ? "subscription" : "renouvellement"}?siteId=${row.siteID}` : null}
                                    />
                                    :
                                    <div key={`type-buttons-${index}`} className={`conso--emptyblock conso--button ${index === 0 ? "conso--button__first" : ""}`}> </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="conso--containers__rightcolumns">
                <div className="privatepages--form__block conso--greyblock conso--greyblockright conso--checkbox__container">
                    <div className="conso--checkbox__first">
                        <Checkbox
                            color="primary"
                            checked={props.siteIdArray.length === props.rows.length}
                            inputProps={{
                                'aria-labelledby': "tous",
                            }}
                            onClick={() => props.CheckAll()}
                            sx={{
                                borderColor: "#3F3F46",
                                padding: "10px",
                                '&.Mui-checked': {
                                    padding: "10px",
                                    color: "#48AB4D",
                                },
                                '& .MuiSvgIcon-root': { fontSize: 16 }
                            }}
                        />
                        <span className="conso--export__text">Tout sélectionner</span>
                    </div>
                    {visibleRows.map((row, index) => {
                        return (
                            <div key={`checkbox-${row.siteID}-${index}`} className="conso--checkbox__prm">
                                <Checkbox
                                    color="primary"
                                    checked={props.siteIdArray.indexOf(row.siteID) > -1}
                                    inputProps={{
                                        'aria-labelledby': `checkbox-${row.siteID}`,
                                    }}
                                    onClick={() => props.CheckSite(row.siteID)}
                                    sx={{
                                        borderColor: "#3F3F46",
                                        padding: "8px",
                                        '&.Mui-checked': {
                                            padding: "8px",
                                            color: "#48AB4D",

                                        },
                                        '& .MuiSvgIcon-root': { fontSize: 16 }
                                    }}
                                />
                                <span className="conso--export__text">PRM {row.prm}</span>
                            </div>
                        )
                    }
                    )}
                    <div className="addsites--choosetype__switchcontainer conso--switch__container">
                        <span className={`addsites--choosetype__energy ${props.exportType === "Somme" ? "addsites--choosetype__energyactive" : ""}`}>Consolidé</span>
                        <Switch
                            sx={{
                                margin: '0 12px',
                                '--Switch-thumbSize': '19px',
                                '--Switch-trackWidth': '45px',
                                '--Switch-trackHeight': '21px',
                                '--Switch-trackBackground': '#EFEFEF',
                                '--Switch-thumbBackground': '#48AB4D',
                                '&:hover': {
                                    '--Switch-trackBackground': '#EFEFEF',
                                },
                                [`&.${switchClasses.checked}`]: {
                                    '--Switch-thumbBackground': '#48AB4D',
                                    '--Switch-trackBackground': '#EFEFEF',
                                    '&:hover': {
                                        '--Switch-trackBackground': '#EFEFEF',
                                    },
                                },
                            }}
                            checked={props.exportType === "Detail"}
                            onChange={() => {
                                if (props.exportType === "Detail") {
                                    props.setExportType("Somme")
                                }
                                else {
                                    props.setExportType("Detail")
                                }
                            }} />
                        <span className={`addsites--choosetype__energy ${props.exportType === "Detail" ? "addsites--choosetype__energyactive" : ""}`}>Détaillé</span>
                    </div>
                    <Button
                        cta="Valider"
                        disabled={props.siteIdArray.length === 0}
                        additionnalClassName="table--tablecontainer__button conso--button__send"
                        handleSubmit={() => props.handleDataExport()}
                        isLoading={props.isDataLoadingExport}
                    />
                </div>
            </div>
        </div >
    )

}

export default ConsoSiteTable;