import { Link } from "react-router-dom"

function Button(props) {
    let buttonClassName = "global--button"
    if (props.additionnalClassName && typeof props.additionnalClassName === "string") buttonClassName = `${buttonClassName} ${props.additionnalClassName}`
    if (props.disabled) buttonClassName = `${buttonClassName} global--button__disabled`

    if (props.isLoading) {
        return (
            <span className="global--loader"></span>
        )
    }
    if (props.link) {
        return (
            <Link to={props.link ? props.link : null}>
                <button type="button" className={buttonClassName} disabled={props.disabled}>
                    {props.withPlus && <span className="global--button__plus">+</span>}
                    {props.cta}
                </button>
            </Link>
        )
    }
    else {
        return (
            <button type="button" className={buttonClassName} disabled={props.disabled} onClick={props.handleSubmit}>
                {props.withPlus && <span className="global--button__plus">+</span>}
                {props.cta}
            </button>
        )
    }
}

export default Button;