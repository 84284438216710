import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { getComparator } from '../../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../../Utils/SortingUtils/stableSort'
import EnhancedTableHead from '../../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../../Components/TableComponents/StyledTableCell'
import { headCellsGreenEnergy } from '../../../Utils/TableUtils/headCells'
import Open from '../../../img/icons/suppr.svg'

function GreenEnergyTable(props) {
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('id')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, props.rows],
    )

    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        headCells={headCellsGreenEnergy}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {

                            return (
                                <TableRow key={`greenenergytable-${index}`}>
                                    <StyledTableCell>{row.annee && row.annee}</StyledTableCell>
                                    <StyledTableCell>{row.libelle && row.libelle}</StyledTableCell>
                                    <StyledTableCell>{row.period && row.period}</StyledTableCell>
                                    <StyledTableCell>{row.nombreGO && row.nombreGO}</StyledTableCell>
                                    <StyledTableCell>{row.attestationID && <div className="mandates--link__container" onClick={() => props.getFile(row.attestationID, row.annee)}><span className="table--link mandates--link mandates--linknomargin">Voir l'attestation</span><img src={Open} alt="Ouvrir" className="table--icon" /></div>}</StyledTableCell>
                                </TableRow>
                            )
                        })}
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Lignes par page"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                sx={{
                    '.MuiTablePagination-toolbar': {
                        color: "#18181b !important",
                    },
                    '.MuiTablePagination-selectLabel': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-select': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-displayedRows': {
                        fontFamily: "Plus Jakarta Sans, sans-serif !important",
                        color: "#18181b !important",
                    },
                }}
            />
        </Box>
    )

}

export default GreenEnergyTable;