import SideBar from "../../../Components/SideBar/SideBar"
import { SearchBar } from "../../../Components/SearchBar"
import Button from "../../../Components/Button"
import { useState, useEffect, useCallback } from "react"
import axios from "axios"
import { createDataMessages } from "../../../Utils/TableUtils/createData"
import MailBoxTable from "./MailBoxTable"
import { removeAccents } from "../../../Utils/Regex/removeAccents"

const apiUrl = process.env.REACT_APP_API_URL

function MailBox() {
    const [messages, setMessages] = useState([])
    const [filteredMessages, setFilteredMessages] = useState([])
    const [page, setPage] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const getMessages = useCallback(() => {
        axios.get(`${apiUrl}/Message`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                if (response && response.data) {

                    const messageArray = response.data.map(element => createDataMessages(`${element.messageID}`, element.sujet, element.message, element.dateCreation, element.dateModification, element.isRead))
                    setMessages(messageArray)
                    setFilteredMessages(messageArray)
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [])

    useEffect(() => {
        getMessages()
    }, [getMessages])

    const filterMessages = (query) => {
        if (!query || query === "") {
            setFilteredMessages(messages)
            setPage(0)
        }
        else {
            const keys = ['messageID', 'sujet', 'message', 'dateCreation', 'dateModification']
            const results = messages.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredMessages(results)
            setPage(0)
        }
    }

    return (
        <>
            <SideBar />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Historique de vos messages</h3>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    <div className={`table--searchbarcontainer${messages.length > 0 ? "" : " gaz--searchbarcontainer__emptyarray"}`}>
                        {messages.length > 0 &&
                            <SearchBar
                                id="table"
                                filterData={filterMessages}
                                setValue={setSearchValue}
                                value={searchValue}
                                additionnalFormClassName="searchbar--form table--form"
                            />}
                        <Button
                            cta="Nouveau message"
                            link='/new-message'
                            additionnalClassName="table--tablecontainer__button" />
                    </div>
                    {filteredMessages.length > 0 ?
                        <MailBoxTable
                            rows={filteredMessages}
                            page={page}
                            setPage={setPage}
                        />
                        :
                        <span>Aucun message trouvé.</span>}
                </div>
            </div>
        </>
    )
}

export default MailBox;