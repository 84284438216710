import { useState, useEffect, useCallback } from "react"
import { useSearchParams, Link, useNavigate } from "react-router-dom"

import SideBar from "../../Components/SideBar/SideBar"
import Button from "../../Components/Button"
import InformationPopup from "../../Components/InformationPopup"
import Checkbox from '@mui/material/Checkbox'
import axios from "axios"

const apiUrl = process.env.REACT_APP_API_URL

function AppelsOffreResult() {
    const navigate = useNavigate()
    const isGaz = window.location.pathname.includes('gaz')

    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isDataLoadingGazArray, setIsDataLoadingGazArray] = useState([])

    const [searchParams] = useSearchParams()
    const siteID = searchParams.get('siteID')
    const [appelOffre, setAppelOffre] = useState("")
    const [isDefinitif, setIsDefinitif] = useState(false)
    const [isBis, setIsBis] = useState(false)
    const [isBisVisible, setIsBisVisible] = useState(false)
    const [bisParticipationShow, setBisParticipationShow] = useState(false)
    const [choseBisOffer, setChoseBisOffer] = useState(false)

    const [car, setCar] = useState(0)
    const [dateCotation, setDateCotation] = useState("")
    const [site, setSite] = useState("")
    const [texte, setTexte] = useState("")
    const [fournisseur, setFournisseur] = useState("")
    const [prm, setPrm] = useState("")
    const [pce, setPce] = useState("")
    const [address, setAddress] = useState("")

    const [dateDebutFourniture, setDateDebutFourniture] = useState("")
    const [dateFinNouveauContrat, setDateFinNouveauContrat] = useState("")
    const [coutAnnuelEstimeHTVA, setCoutAnnuelEstimeHTVA] = useState(0)
    const [coutAnnuelEstimeTTC, setCoutAnnuelEstimeTTC] = useState(0)
    const [coutAnnuelEstimeHTVAkWh, setCoutAnnuelEstimeHTVAkWh] = useState(0)
    const [coutAnnuelEstimeTTCkWh, setCoutAnnuelEstimeTTCkWh] = useState(0)

    const [dateDebutFournitureSecond, setDateDebutFournitureSecond] = useState("")
    const [dateFinNouveauContratSecond, setDateFinNouveauContratSecond] = useState("")
    const [coutAnnuelEstimeHTVASecond, setCoutAnnuelEstimeHTVASecond] = useState(0)
    const [coutAnnuelEstimeTTCSecond, setCoutAnnuelEstimeTTCSecond] = useState(0)
    const [coutAnnuelEstimeHTVAkWhSecond, setCoutAnnuelEstimeHTVAkWhSecond] = useState(0)
    const [coutAnnuelEstimeTTCkWhSecond, setCoutAnnuelEstimeTTCkWhSecond] = useState(0)

    const [dateDebutFournitureGazArray] = useState([])
    const [dateFinNouveauContratGazArray] = useState([])
    const [coutAnnuelEstimeHTVAGazArray] = useState([])
    const [coutAnnuelEstimeTTCGazArray] = useState([])
    const [coutAnnuelEstimeHTVAkWhGazArray] = useState([])
    const [coutAnnuelEstimeTTCkWhGazArray] = useState([])

    const [noteClausesContractuelles, setNoteClausesContractuelles] = useState(0)
    const [noteClausesContractuellesSecond, setNoteClausesContractuellesSecond] = useState(0)
    const [noteClausesContractuellesGazArray] = useState([])

    const [noteSuiviCommercial, setNoteSuiviCommercial] = useState(0)
    const [noteEspaceClientInternet, setNoteEspaceClientInternet] = useState(0)
    const [noteServiceClientEtReclamations, setNoteServiceClientEtReclamations] = useState(0)
    const [noteLimiteDeResponsabiliteDuFournisseur, setNoteLimiteDeResponsabiliteDuFournisseur] = useState(0)

    const [noteSuiviCommercialSecond, setNoteSuiviCommercialSecond] = useState(0)
    const [noteEspaceClientInternetSecond, setNoteEspaceClientInternetSecond] = useState(0)
    const [noteServiceClientEtReclamationsSecond, setNoteServiceClientEtReclamationsSecond] = useState(0)
    const [noteLimiteDeResponsabiliteDuFournisseurSecond, setNoteLimiteDeResponsabiliteDuFournisseurSecond] = useState(0)

    const [noteSuiviCommercialGazArray] = useState([])
    const [noteEspaceClientInternetGazArray] = useState([])
    const [noteServiceClientEtReclamationsGazArray] = useState([])
    const [noteLimiteDeResponsabiliteDuFournisseurGazArray] = useState([])

    const [noteTechnique, setNoteTechnique] = useState(0)
    const [noteDureeEtTypeContrat, setNoteDureeEtTypeContrat] = useState(0)
    const [noteModeDeFacturation, setNoteModeDeFacturation] = useState(0)
    const [noteModeDePaiement, setNoteModeDePaiement] = useState(0)

    const [noteTechniqueSecond, setNoteTechniqueSecond] = useState(0)
    const [noteDureeEtTypeContratSecond, setNoteDureeEtTypeContratSecond] = useState(0)
    const [noteModeDeFacturationSecond, setNoteModeDeFacturationSecond] = useState(0)
    const [noteModeDePaiementSecond, setNoteModeDePaiementSecond] = useState(0)

    const [noteTechniqueGazArray] = useState([])
    const [noteDureeEtTypeContratGazArray] = useState([])
    const [noteModeDeFacturationGazArray] = useState([])
    const [noteModeDePaiementGazArray] = useState([])

    const [noteTransparence, setNoteTransparence] = useState(0)
    const [noteInformationTarification, setNoteInformationTarification] = useState(0)
    const [noteConditionRevisionPrix, setNoteConditionRevisionPrix] = useState(0)
    const [noteEngagementDeVolume, setNoteEngagementDeVolume] = useState(0)
    const [noteConditionsResiliationClient, setNoteConditionsResiliationClient] = useState(0)
    const [noteConditionsResiliationFournisseur, setNoteConditionsResiliationFournisseur] = useState(0)
    const [noteClausesSuspension, setNoteClausesSuspension] = useState(0)

    const [noteTransparenceSecond, setNoteTransparenceSecond] = useState(0)
    const [noteInformationTarificationSecond, setNoteInformationTarificationSecond] = useState(0)
    const [noteConditionRevisionPrixSecond, setNoteConditionRevisionPrixSecond] = useState(0)
    const [noteEngagementDeVolumeSecond, setNoteEngagementDeVolumeSecond] = useState(0)
    const [noteConditionsResiliationClientSecond, setNoteConditionsResiliationClientSecond] = useState(0)
    const [noteConditionsResiliationFournisseurSecond, setNoteConditionsResiliationFournisseurSecond] = useState(0)
    const [noteClausesSuspensionSecond, setNoteClausesSuspensionSecond] = useState(0)

    const [noteTransparenceGazArray] = useState([])
    const [noteInformationTarificationGazArray] = useState([])
    const [noteConditionRevisionPrixGazArray] = useState([])
    const [noteEngagementDeVolumeGazArray] = useState([])
    const [noteConditionsResiliationClientGazArray] = useState([])
    const [noteConditionsResiliationFournisseurGazArray] = useState([])
    const [noteClausesSuspensionGazArray] = useState([])

    const [noteService, setNoteService] = useState(0)
    const [noteServiceQualite, setNoteServiceQualite] = useState(0)

    const [noteServiceSecond, setNoteServiceSecond] = useState(0)
    const [noteServiceQualiteSecond, setNoteServiceQualiteSecond] = useState(0)

    const [noteServiceGazArray] = useState([])
    const [noteServiceQualiteGazArray] = useState([])

    const [showPrixAnnuel, setShowPrixAnnuel] = useState(false)
    const [showNoteClauses, setShowNoteClauses] = useState(false)
    const [abonnement, setAbonnement] = useState(0)
    const [terme, setTerme] = useState(0)
    const [termeMWh, setTermeMWh] = useState(0)
    const [taxes, setTaxes] = useState(0)
    const [tvA55, setTvA55] = useState(0)
    const [tvA20, setTvA20] = useState(0)

    const [showPrixAnnuelSecond, setShowPrixAnnuelSecond] = useState(false)
    const [showNoteClausesSecond, setShowNoteClausesSecond] = useState(false)
    const [abonnementSecond, setAbonnementSecond] = useState(0)
    const [termeSecond, setTermeSecond] = useState(0)
    const [termeMWhSecond, setTermeMWhSecond] = useState(0)
    const [taxesSecond, setTaxesSecond] = useState(0)
    const [tvA55Second, setTvA55Second] = useState(0)
    const [tvA20Second, setTvA20Second] = useState(0)

    const [showPrixAnnuelGazArray, setShowPrixAnnuelGazArray] = useState([])
    const [showNoteClausesGazArray, setShowNoteClausesGazArray] = useState([])
    const [abonnementGazArray] = useState([])
    const [termeGazArray] = useState([])
    const [termeMWhGazArray] = useState([])
    const [taxesGazArray] = useState([])
    const [tvA55GazArray] = useState([])
    const [tvA20GazArray] = useState([])

    const [prixFournitureCalcule, setPrixFournitureCalcule] = useState(0)
    const [pointe, setPointe] = useState(0)
    const [hph, setHph] = useState(0)
    const [hpd, setHpd] = useState(0)
    const [hch, setHch] = useState(0)
    const [hcd, setHcd] = useState(0)
    const [hpe, setHpe] = useState(0)
    const [mecaCEECentimeEurosParkWh, setMecaCEECentimeEurosParkWh] = useState(0)
    const [mecaCapaCentimeEurosParkWh, setMecaCapaCentimeEurosParkWh] = useState(0)
    const [hce, setHce] = useState(0)
    const [ja, setJa] = useState(0)
    const [turpeComplet, setTurpeComplet] = useState(0)
    const [composanteGestion, setComposanteGestion] = useState(0)
    const [composanteComptage, setComposanteComptage] = useState(0)
    const [composanteSoutirageFixe, setComposanteSoutirageFixe] = useState(0)
    const [composanteSoutirageVariable, setComposanteSoutirageVariable] = useState(0)
    const [totalTaxes, setTotalTaxes] = useState(0)
    const [cta, setCta] = useState(0)

    const [prixFournitureCalculeSecond, setPrixFournitureCalculeSecond] = useState(0)
    const [pointeSecond, setPointeSecond] = useState(0)
    const [hphSecond, setHphSecond] = useState(0)
    const [hpdSecond, setHpdSecond] = useState(0)
    const [hchSecond, setHchSecond] = useState(0)
    const [hcdSecond, setHcdSecond] = useState(0)
    const [hpeSecond, setHpeSecond] = useState(0)
    const [hceSecond, setHceSecond] = useState(0)
    const [mecaCEECentimeEurosParkWhSecond, setMecaCEECentimeEurosParkWhSecond] = useState(0)
    const [mecaCapaCentimeEurosParkWhSecond, setMecaCapaCentimeEurosParkWhSecond] = useState(0)
    const [jaSecond, setJaSecond] = useState(0)
    const [turpeCompletSecond, setTurpeCompletSecond] = useState(0)
    const [composanteGestionSecond, setComposanteGestionSecond] = useState(0)
    const [composanteComptageSecond, setComposanteComptageSecond] = useState(0)
    const [composanteSoutirageFixeSecond, setComposanteSoutirageFixeSecond] = useState(0)
    const [composanteSoutirageVariableSecond, setComposanteSoutirageVariableSecond] = useState(0)
    const [totalTaxesSecond, setTotalTaxesSecond] = useState(0)
    const [ctaSecond, setCtaSecond] = useState(0)

    const [prixFournitureCalculeGazArray] = useState([])
    const [pointeGazArray] = useState([])
    const [hphGazArray] = useState([])
    const [hpdGazArray] = useState([])
    const [hchGazArray] = useState([])
    const [hcdGazArray] = useState([])
    const [hpeGazArray] = useState([])
    const [hceGazArray] = useState([])
    const [mecaCEECentimeEurosParkWhGazArray] = useState([])
    const [mecaCapaCentimeEurosParkWhGazArray] = useState([])
    const [jaGazArray] = useState([])
    const [turpeCompletGazArray] = useState([])
    const [composanteGestionGazArray] = useState([])
    const [composanteComptageGazArray] = useState([])
    const [composanteSoutirageFixeGazArray] = useState([])
    const [composanteSoutirageVariableGazArray] = useState([])
    const [totalTaxesGazArray] = useState([])
    const [ctaGazArray] = useState([])

    const [totalOffres, setTotalOffres] = useState(0)

    const [reportShow, setReportShow] = useState(false)
    const [reportCheckState, setReportCheckState] = useState(false)
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [popupTitle, setPopupTitle] = useState("")
    const [popupSubtitle, setPopupSubtitle] = useState("")

    const [showContext, setShowContext] = useState(false)

    const fetchAppelsOffreResult = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/AppelOffre/Resultat/${siteID}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                response.data?.appelOffre && setAppelOffre(response.data.appelOffre)
                response.data?.car && setCar(response.data.car)
                response.data?.dateCotation && setDateCotation(response.data.dateCotation)
                response.data?.site && setSite(response.data.site)
                response.data?.texte && setTexte(response.data.texte)
                response.data?.fournisseur && setFournisseur(response.data.fournisseur)
                response.data?.prm && setPrm(response.data.prm)
                response.data?.pce && setPce(response.data.pce)
                response.data?.adresse && setAddress(`${response.data.adresse} ${response.data?.codePostal ? response.data?.codePostal : ""} ${response.data?.ville ? response.data?.ville : ""}`)
                response.data?.dateDebutFourniture && setDateDebutFourniture(response.data.dateDebutFourniture)
                response.data?.dateFinNouveauContrat && setDateFinNouveauContrat(response.data.dateFinNouveauContrat)
                response.data?.coutAnnuelEstimeHTVA && setCoutAnnuelEstimeHTVA(response.data.coutAnnuelEstimeHTVA)
                response.data?.coutAnnuelEstimeTTCkWh && setCoutAnnuelEstimeTTCkWh(response.data.coutAnnuelEstimeTTCkWh)
                response.data?.coutAnnuelEstimeHTVAkWh && setCoutAnnuelEstimeHTVAkWh(response.data.coutAnnuelEstimeHTVAkWh)
                response.data?.coutAnnuelEstimeTTC && setCoutAnnuelEstimeTTC(response.data.coutAnnuelEstimeTTC)
                response.data?.isDefinitif && setIsDefinitif(response.data.isDefinitif)

                response.data?.abonnement && setAbonnement(response.data.abonnement)
                response.data?.terme && setTerme(response.data.terme)
                response.data?.termeMWh && setTermeMWh(response.data.termeMWh)
                response.data?.taxes && setTaxes(response.data.taxes)
                response.data?.tvA55 && setTvA55(response.data.tvA55)
                response.data?.tvA20 && setTvA20(response.data.tvA20)

                response.data?.prixFournitureCalcule && setPrixFournitureCalcule(response.data.prixFournitureCalcule)
                response.data?.tva20 && setTvA20(response.data.tva20)
                response.data?.pointe && setPointe(response.data.pointe.centimeEurosParkWh)
                response.data?.hph && setHph(response.data.hph.centimeEurosParkWh)
                response.data?.hpd && setHpd(response.data.hpd.centimeEurosParkWh)
                response.data?.hch && setHch(response.data.hch.centimeEurosParkWh)
                response.data?.hcd && setHcd(response.data.hcd.centimeEurosParkWh)
                response.data?.hpe && setHpe(response.data.hpe.centimeEurosParkWh)
                response.data?.hce && setHce(response.data.hce.centimeEurosParkWh)
                response.data?.mecaCEECentimeEurosParkWh && setMecaCEECentimeEurosParkWh(response.data.mecaCEECentimeEurosParkWh)
                response.data?.mecaCapaCentimeEurosParkWh && setMecaCapaCentimeEurosParkWh(response.data.mecaCapaCentimeEurosParkWh)
                response.data?.ja && setJa(response.data.ja.centimeEurosParkWh)
                response.data?.turpeComplet && setTurpeComplet(response.data.turpeComplet)
                response.data?.composanteGestion && setComposanteGestion(response.data.composanteGestion)
                response.data?.composanteComptage && setComposanteComptage(response.data.composanteComptage)
                response.data?.composanteSoutirageFixe && setComposanteSoutirageFixe(response.data.composanteSoutirageFixe)
                response.data?.composanteSoutirageVariable && setComposanteSoutirageVariable(response.data.composanteSoutirageVariable)
                response.data?.totalTaxes && setTotalTaxes(response.data.totalTaxes)
                response.data?.cta && setCta(response.data.cta)

                response.data?.totalOffres && setTotalOffres(response.data.totalOffres)

                response.data?.reportShow && setReportShow(response.data.reportShow)
                setReportCheckState(response.data.reportCheckState)
                response.data?.isBis && setIsBis(response.data.isBis)
                response.data?.isBisVisible && setIsBisVisible(response.data.isBisVisible)
                response.data?.bisParticipationShow && setBisParticipationShow(response.data.bisParticipationShow)

                response.data?.clauses && response.data.clauses.forEach((element) => {
                    if (element.libelle === "Note des clauses contractuelles") setNoteClausesContractuelles(element.noteSur10)
                    if (element.libelle === "Suivi Commercial") setNoteSuiviCommercial(element.noteSur10)
                    if (element.libelle === "espace client internet") setNoteEspaceClientInternet(element.noteSur10)
                    if (element.libelle === "service client et réclamations") setNoteServiceClientEtReclamations(element.noteSur10)
                    if (element.libelle === "limite de responsabilitédu fournisseur") setNoteLimiteDeResponsabiliteDuFournisseur(element.noteSur10)

                    if (element.libelle === "Note technique") setNoteTechnique(element.noteSur10)
                    if (element.libelle === "Durée et type du contrat") setNoteDureeEtTypeContrat(element.noteSur10)
                    if (element.libelle === "Mode de facturation") setNoteModeDeFacturation(element.noteSur10)
                    if (element.libelle === "Mode de paiement") setNoteModeDePaiement(element.noteSur10)

                    if (element.libelle === "Transparence") setNoteTransparence(element.noteSur10)
                    if (element.libelle === "Information de tarification") setNoteInformationTarification(element.noteSur10)
                    if (element.libelle === "conditions de révision des prix (actualisation, indexation)") setNoteConditionRevisionPrix(element.noteSur10)
                    if (element.libelle === "engagements de volumes ou prévisions") setNoteEngagementDeVolume(element.noteSur10)
                    if (element.libelle === "condition de résiliation à l'initiative du client") setNoteConditionsResiliationClient(element.noteSur10)
                    if (element.libelle === "conditions de résiliation à l'initiative du fournisseur") setNoteConditionsResiliationFournisseur(element.noteSur10)
                    if (element.libelle === "clause de suspension, inexécution, interruption du contrat de fourniture") setNoteClausesSuspension(element.noteSur10)

                    if (element.libelle === "Services") setNoteService(element.noteSur10)
                    if (element.libelle === "des services de qualité") setNoteServiceQualite(element.noteSur10)
                })
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [isGaz, siteID])

    const fetchSecondElecAppelsOffreResult = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/AppelOffre/Resultat/${siteID}?isBis=true`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                response.data?.dateDebutFourniture && setDateDebutFournitureSecond(response.data.dateDebutFourniture)
                response.data?.dateFinNouveauContrat && setDateFinNouveauContratSecond(response.data.dateFinNouveauContrat)
                response.data?.coutAnnuelEstimeHTVA && setCoutAnnuelEstimeHTVASecond(response.data.coutAnnuelEstimeHTVA)
                response.data?.coutAnnuelEstimeTTCkWh && setCoutAnnuelEstimeTTCkWhSecond(response.data.coutAnnuelEstimeTTCkWh)
                response.data?.coutAnnuelEstimeHTVAkWh && setCoutAnnuelEstimeHTVAkWhSecond(response.data.coutAnnuelEstimeHTVAkWh)
                response.data?.coutAnnuelEstimeTTC && setCoutAnnuelEstimeTTCSecond(response.data.coutAnnuelEstimeTTC)

                response.data?.abonnement && setAbonnementSecond(response.data.abonnement)
                response.data?.terme && setTermeSecond(response.data.terme)
                response.data?.termeMWh && setTermeMWhSecond(response.data.termeMWh)
                response.data?.taxes && setTaxesSecond(response.data.taxes)
                response.data?.tvA55 && setTvA55Second(response.data.tvA55)
                response.data?.tvA20 && setTvA20Second(response.data.tvA20)

                response.data?.prixFournitureCalcule && setPrixFournitureCalculeSecond(response.data.prixFournitureCalcule)
                response.data?.tva20 && setTvA20Second(response.data.tva20)
                response.data?.pointe && setPointeSecond(response.data.pointe.centimeEurosParkWh)
                response.data?.hph && setHphSecond(response.data.hph.centimeEurosParkWh)
                response.data?.hpd && setHpdSecond(response.data.hpd.centimeEurosParkWh)
                response.data?.hch && setHchSecond(response.data.hch.centimeEurosParkWh)
                response.data?.hcd && setHcdSecond(response.data.hcd.centimeEurosParkWh)
                response.data?.hpe && setHpeSecond(response.data.hpe.centimeEurosParkWh)
                response.data?.hce && setHceSecond(response.data.hce.centimeEurosParkWh)
                response.data?.ja && setJaSecond(response.data.ja.centimeEurosParkWh)
                response.data?.turpeComplet && setTurpeCompletSecond(response.data.turpeComplet)
                response.data?.composanteGestion && setComposanteGestionSecond(response.data.composanteGestion)
                response.data?.composanteComptage && setComposanteComptageSecond(response.data.composanteComptage)
                response.data?.composanteSoutirageFixe && setComposanteSoutirageFixeSecond(response.data.composanteSoutirageFixe)
                response.data?.composanteSoutirageVariable && setComposanteSoutirageVariableSecond(response.data.composanteSoutirageVariable)
                response.data?.totalTaxes && setTotalTaxesSecond(response.data.totalTaxes)
                response.data?.cta && setCtaSecond(response.data.cta)

                response.data?.mecaCEECentimeEurosParkWh && setMecaCEECentimeEurosParkWhSecond(response.data.mecaCEECentimeEurosParkWh)
                response.data?.mecaCapaCentimeEurosParkWh && setMecaCapaCentimeEurosParkWhSecond(response.data.mecaCapaCentimeEurosParkWh)

                response.data?.clauses && response.data.clauses.forEach((element) => {
                    if (element.libelle === "Note des clauses contractuelles") setNoteClausesContractuellesSecond(element.noteSur10)
                    if (element.libelle === "Suivi Commercial") setNoteSuiviCommercialSecond(element.noteSur10)
                    if (element.libelle === "espace client internet") setNoteEspaceClientInternetSecond(element.noteSur10)
                    if (element.libelle === "service client et réclamations") setNoteServiceClientEtReclamationsSecond(element.noteSur10)
                    if (element.libelle === "limite de responsabilitédu fournisseur") setNoteLimiteDeResponsabiliteDuFournisseurSecond(element.noteSur10)

                    if (element.libelle === "Note technique") setNoteTechniqueSecond(element.noteSur10)
                    if (element.libelle === "Durée et type du contrat") setNoteDureeEtTypeContratSecond(element.noteSur10)
                    if (element.libelle === "Mode de facturation") setNoteModeDeFacturationSecond(element.noteSur10)
                    if (element.libelle === "Mode de paiement") setNoteModeDePaiementSecond(element.noteSur10)

                    if (element.libelle === "Transparence") setNoteTransparenceSecond(element.noteSur10)
                    if (element.libelle === "Information de tarification") setNoteInformationTarificationSecond(element.noteSur10)
                    if (element.libelle === "conditions de révision des prix (actualisation, indexation)") setNoteConditionRevisionPrixSecond(element.noteSur10)
                    if (element.libelle === "engagements de volumes ou prévisions") setNoteEngagementDeVolumeSecond(element.noteSur10)
                    if (element.libelle === "condition de résiliation à l'initiative du client") setNoteConditionsResiliationClientSecond(element.noteSur10)
                    if (element.libelle === "conditions de résiliation à l'initiative du fournisseur") setNoteConditionsResiliationFournisseurSecond(element.noteSur10)
                    if (element.libelle === "clause de suspension, inexécution, interruption du contrat de fourniture") setNoteClausesSuspensionSecond(element.noteSur10)

                    if (element.libelle === "Services") setNoteServiceSecond(element.noteSur10)
                    if (element.libelle === "des services de qualité") setNoteServiceQualiteSecond(element.noteSur10)
                })
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [isGaz, siteID])

    useEffect(() => {
        fetchAppelsOffreResult()
    }, [fetchAppelsOffreResult])

    useEffect(() => {
        if (isBis && !isGaz) fetchSecondElecAppelsOffreResult()
    }, [fetchSecondElecAppelsOffreResult, isDefinitif, isGaz, isBis])

    const reportAppelOffres = () => {
        axios.post(`${apiUrl}/Elec/AppelOffre/Resultat/${siteID}/Report`,
            '',
            {
                headers: {
                    'accept': '*/*',
                    'content-type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true,
            })
            .then(() => {
                setPopupTitle("Votre participation a été reportée")
                setPopupSubtitle("Vous allez être redirigé vers la page appels d'offres")
                setShowConfirmationPopup(true)
                setReportShow(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const registerSecondOffer = () => {
        axios.post(`${apiUrl}/Elec/AppelOffre/Resultat/${siteID}/ParticipationBis`,
            '',
            {
                headers: {
                    'accept': '*/*',
                    'content-type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true,
            })
            .then(() => {
                setPopupTitle("Votre participation à l'offre alternative a été enregistrée")
                setShowConfirmationPopup(true)
                setBisParticipationShow(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const handleClosePopup = () => {
        setShowConfirmationPopup(false)
        navigate('/appels-offre-elec')
    }

    const fetchAllGazOffers = useCallback((offerNumber) => {
        if (offerNumber) {
            let newArray = Object.assign([], isDataLoadingGazArray)
            newArray[offerNumber - 1] = true
            setIsDataLoadingGazArray(newArray)

            axios.get(`${apiUrl}/Gaz/AppelOffre/Resultat/${siteID}?offre=${offerNumber}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then((response) => {
                    if (response.data?.dateDebutFourniture) dateDebutFournitureGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.dateDebutFourniture }
                    if (response.data?.dateFinNouveauContrat) dateFinNouveauContratGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.dateFinNouveauContrat }
                    if (response.data?.coutAnnuelEstimeHTVA) coutAnnuelEstimeHTVAGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.coutAnnuelEstimeHTVA }
                    if (response.data?.coutAnnuelEstimeTTCkWh) coutAnnuelEstimeTTCkWhGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.coutAnnuelEstimeTTCkWh }
                    if (response.data?.coutAnnuelEstimeHTVAkWh) coutAnnuelEstimeHTVAkWhGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.coutAnnuelEstimeHTVAkWh }
                    if (response.data?.coutAnnuelEstimeTTC) coutAnnuelEstimeTTCGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.coutAnnuelEstimeTTC }

                    if (response.data?.abonnement) abonnementGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.abonnement }
                    if (response.data?.terme) termeGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.terme }
                    if (response.data?.termeMWh) termeMWhGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.termeMWh }
                    if (response.data?.taxes) taxesGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.taxes }
                    if (response.data?.tvA55) tvA55GazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.tvA55 }
                    if (response.data?.tvA20) tvA20GazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.tvA20 }

                    if (response.data?.prixFournitureCalcule) prixFournitureCalculeGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.prixFournitureCalcule }
                    if (response.data?.tva20) tvA20GazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.tva20 }
                    if (response.data?.pointe) pointeGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.pointe }
                    if (response.data?.hph) hphGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.hph }
                    if (response.data?.hpd) hpdGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.hpd }
                    if (response.data?.hch) hchGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.hch }
                    if (response.data?.hcd) hcdGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.hcd }
                    if (response.data?.hpe) hpeGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.hpe }
                    if (response.data?.hce) hceGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.hce }
                    if (response.data?.ja) jaGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.ja }
                    if (response.data?.turpeComplet) turpeCompletGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.turpeComplet }
                    if (response.data?.composanteGestion) composanteGestionGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.composanteGestion }
                    if (response.data?.composanteComptage) composanteComptageGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.composanteComptage }
                    if (response.data?.composanteSoutirageFixe) composanteSoutirageFixeGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.composanteSoutirageFixe }
                    if (response.data?.composanteSoutirageVariable) composanteSoutirageVariableGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.composanteSoutirageVariable }
                    if (response.data?.totalTaxes) totalTaxesGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.totalTaxes }
                    if (response.data?.cta) ctaGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.cta }

                    if (response.data?.mecaCEECentimeEurosParkWh) mecaCEECentimeEurosParkWhGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.mecaCEECentimeEurosParkWh }
                    if (response.data?.mecaCapaCentimeEurosParkWh) mecaCapaCentimeEurosParkWhGazArray[offerNumber] = { offerNumber: offerNumber, data: response.data.mecaCapaCentimeEurosParkWh }

                    response.data?.clauses && response.data.clauses.forEach((element) => {
                        if (element.libelle === "Note des clauses contractuelles") noteClausesContractuellesGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "Suivi Commercial") noteSuiviCommercialGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "espace client internet") noteEspaceClientInternetGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "service client et réclamations") noteServiceClientEtReclamationsGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "limite de responsabilitédu fournisseur") noteLimiteDeResponsabiliteDuFournisseurGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }

                        if (element.libelle === "Note technique") noteTechniqueGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "Durée et type du contrat") noteDureeEtTypeContratGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "Mode de facturation") noteModeDeFacturationGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "Mode de paiement") noteModeDePaiementGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }

                        if (element.libelle === "Transparence") noteTransparenceGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "Information de tarification") noteInformationTarificationGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "conditions de révision des prix (actualisation, indexation)") noteConditionRevisionPrixGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "engagements de volumes ou prévisions") noteEngagementDeVolumeGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "condition de résiliation à l'initiative du client") noteConditionsResiliationClientGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "conditions de résiliation à l'initiative du fournisseur") noteConditionsResiliationFournisseurGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "clause de suspension, inexécution, interruption du contrat de fourniture") noteClausesSuspensionGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }

                        if (element.libelle === "Services") noteServiceGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                        if (element.libelle === "des services de qualité") noteServiceQualiteGazArray[offerNumber] = { offerNumber: offerNumber, data: element.noteSur10 }
                    })

                    setShowPrixAnnuelGazArray(showPrixAnnuelGazArray => [false, ...showPrixAnnuelGazArray])
                    setShowNoteClausesGazArray(showNoteClausesGazArray => [false, ...showNoteClausesGazArray])
                    setIsDataLoadingGazArray(isDataLoadingGazArray => [false, ...isDataLoadingGazArray])
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
                .finally(() => {
                    let newArray = Object.assign([], isDataLoadingGazArray)
                    newArray[offerNumber - 1] = false
                    setIsDataLoadingGazArray(newArray)
                })
        }
    }, [])

    useEffect(() => {
        for (let i = 1; i < totalOffres + 1; i++) {
            fetchAllGazOffers(i)
        }
    }, [fetchAllGazOffers, totalOffres])

    const updateShowPrixAnnuelGazArray = (index, value) => {
        let newArray = Object.assign([], showPrixAnnuelGazArray)
        newArray[index] = value

        setShowPrixAnnuelGazArray(newArray)
    }

    const updateShowNoteClausesGazArray = (index, value) => {
        let newArray = Object.assign([], showNoteClausesGazArray)
        newArray[index] = value

        setShowNoteClausesGazArray(newArray)
    }

    return (
        <>
            <SideBar
                active={isGaz ? "Appels d'offres gaz" : "Appels d'offres elec"}
                openGaz={isGaz}
                openElec={!isGaz} />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">Résultat de l'appel d'offres {isGaz ? "Gaz" : "Électricité"}{isDefinitif ? "" : " (indicatif)"}{appelOffre && ` : ${appelOffre}`}</h3>
                    <Link to={`/appels-offre-${isGaz ? "gaz" : "elec"}`}><span className="privatepages--header__return">{`< Retour à la liste des appels d'offres`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                {isDataLoading ?
                    <span className="global--loader"></span>
                    :
                    <>
                        <div className="appeloffre--result__top">
                            <div className="table--tablecontainer appeloffre--result__left">
                                {dateCotation ? <div className="appeloffre--result__valuecontainer">
                                    <span className="appeloffre--result__type">Cotation : <span className="appeloffre--result__value">{new Date(dateCotation).toLocaleDateString()}</span>
                                    </span>
                                </div>
                                    :
                                    ""
                                }
                                {site ? <div className="appeloffre--result__valuecontainer">
                                    <span className="appeloffre--result__type">Site : <span className="appeloffre--result__value">{site}</span>
                                    </span>
                                </div>
                                    :
                                    ""
                                }
                                {prm ? <div className="appeloffre--result__valuecontainer">
                                    <span className="appeloffre--result__type">PRM : <span className="appeloffre--result__value">{prm}</span>
                                    </span>
                                </div>
                                    :
                                    ""
                                }
                                {pce ? <div className="appeloffre--result__valuecontainer">
                                    <span className="appeloffre--result__type">PCE : <span className="appeloffre--result__value">{pce}</span>
                                    </span>
                                </div>
                                    :
                                    ""
                                }
                                {address ? <div className="appeloffre--result__valuecontainer">
                                    <span className="appeloffre--result__type">Adresse : <span className="appeloffre--result__value">{address}</span>
                                    </span>
                                </div>
                                    :
                                    ""
                                }
                            </div>
                            {car ? <div className="appeloffre--result__right">
                                <span className="appeloffre--result__car">{new Intl.NumberFormat('fr-FR').format(car)} kWh</span>
                                <span>{isGaz ? "Consommation annuelle de référence." : "Consommation annuelle d'électricité"}</span>
                            </div>
                                :
                                <></>
                            }
                        </div>
                        {texte && <div className="table--tablecontainer mandates--block__container">
                            <span className="appeloffre--result__fournisseur">Contexte de l'appel d'offres</span>
                            {!isDefinitif ?
                                <p className="appeloffre--result__texte">Vous trouverez ci-dessous un aperçu du contexte du marché pour la consultation indicative, ainsi que le calendrier de la phase engageante de l'appel d'offres.<br />Nous attirons plus particulièrement votre attention sur les dates limites pour vos décisions, le cas échéant</p>
                                :
                                <p className="appeloffre--result__texte">La consultation finale s'est tenue avec les fournisseurs ayant répondu au tour indicatif. L'appel d'offres est fructueux</p>
                            }
                            {showContext ? <p className="appeloffre--result__texte">{texte}</p> : ""}
                            <span className="greenenergy--showmore" onClick={() => setShowContext(!showContext)}>{showContext ? "Réduire -" : "Lire la suite +"}</span>
                        </div>}
                        {isDefinitif ?
                            <div className="table--tablecontainer mandates--block__container">
                                {fournisseur && <span className="appeloffre--result__fournisseur">Votre fournisseur : {fournisseur}</span>}
                                {(dateDebutFourniture && dateFinNouveauContrat) && <span className="appeloffre--result__date">Le contrat est souscrit pour un début de fourniture au {new Date(dateDebutFourniture).toLocaleDateString()} et une échéance au {new Date(dateFinNouveauContrat).toLocaleDateString()}.</span>}
                                {dateCotation &&
                                    <div className="appeloffre--result__valuecontainer">
                                        <span className="appeloffre--result__type">Prix annuels HTVA : <span className="appeloffre--result__value">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVA)}</span>
                                            {showPrixAnnuel ?
                                                <span className="table--link" onClick={() => setShowPrixAnnuel(false)}>Moins de détails -</span>
                                                :
                                                <span className="table--link" onClick={() => setShowPrixAnnuel(true)}>Plus de détails +</span>
                                            }
                                        </span>
                                    </div>
                                }
                                {showPrixAnnuel &&
                                    <>
                                        <div className="appeloffre--result__texte">Coût annuel :
                                            <ul>
                                                {coutAnnuelEstimeTTC ? <li className="appeloffre--result__list"><span className="appeloffre--result__price">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeTTC)}</span> {`(${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeTTCkWh)} / ${isGaz ? "kWh" : "MWh"}) TTC`}</li> : ""}
                                                {coutAnnuelEstimeHTVA ? <li className="appeloffre--result__list"><span className="appeloffre--result__price">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVA)}</span> {`(${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVAkWh)} / ${isGaz ? "kWh" : "MWh"}) HTVA`}</li> : ""}
                                            </ul>
                                        </div>
                                        <div className="appeloffre--result__texte "><span className="appeloffre--result__price">Dont :</span>
                                            <ul>
                                                {prixFournitureCalcule ? <li className="appeloffre--result__list">Total Electricité : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(prixFournitureCalcule)}</li> : ""}
                                                {abonnement ? <li className={`appeloffre--result__list${!isGaz ? " appeloffre--result__listsecond" : ""}`}>Abonnement : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(abonnement)} / an</li> : ""}
                                                {pointe ? <li className="appeloffre--result__list appeloffre--result__listsecond">Pointe : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(pointe)} / kWh</li> : ""}
                                                {hph ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPH : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hph)} / kWh</li> : ""}
                                                {hpd ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPD : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hpd)} / kWh</li> : ""}
                                                {hch ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCH : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hch)} / kWh</li> : ""}
                                                {hcd ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCD : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hcd)} / kWh</li> : ""}
                                                {hpe ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hpe)} / kWh</li> : ""}
                                                {hce ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hce)} / kWh</li> : ""}
                                                {ja ? <li className="appeloffre--result__list appeloffre--result__listsecond">JA : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(ja)} / kWh</li> : ""}
                                                {turpeComplet ? <li className="appeloffre--result__list">Total TURPE (Coût réglementaire de l'utilisation des réseaux, estimé à date de cotation) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(turpeComplet)}</li> : ""}
                                                {composanteGestion ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Gestion : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteGestion)}</li> : ""}
                                                {composanteComptage ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Comptage : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteComptage)}</li> : ""}
                                                {(composanteSoutirageFixe || composanteSoutirageVariable) ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Comptage : </li> : ""}
                                                {composanteSoutirageFixe ? <li className="appeloffre--result__list appeloffre--result__listthird">Fixe : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteSoutirageFixe)}</li> : ""}
                                                {composanteSoutirageVariable ? <li className="appeloffre--result__list appeloffre--result__listthird">Variable : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteSoutirageVariable)}</li> : ""}
                                                {(totalTaxes && !isGaz) ? <li className="appeloffre--result__list">Total Taxes : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(totalTaxes)} (dont CTA, CSPE, TCCFE, TDCFE)</li> : ""}
                                                {cta ? <li className="appeloffre--result__list appeloffre--result__listsecond">Contribution tarifaire d'acheminement (CTA) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(cta)}</li> : ""}
                                                {taxes ? <li className={`appeloffre--result__list${!isGaz ? " appeloffre--result__listsecond" : ""}`}>{isGaz ? "Taxes (CTA, TICGN + terme fixe)" : "CSPE et taxes locales"} : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(taxes)}</li> : ""}
                                                {tvA55 ? <li className="appeloffre--result__list">TVA 5,5% : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(tvA55)}</li> : ""}
                                                {tvA20 ? <li className="appeloffre--result__list">TVA 20% : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(tvA20)}</li> : ""}
                                                {terme ? <li className="appeloffre--result__list">Molécule + Stockage + CEE + Terme variable de distribution : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(terme)}({new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(termeMWh)} / MWh)</li> : ""}
                                            </ul>
                                            {!isGaz && <span>Toutes les valeurs des taxes, contributions et frais réglementaires sont données à date de cotation et sont susceptibles d'évoluer selon la réglementation en vigueur.</span>}
                                        </div>
                                    </>
                                }
                                {noteClausesContractuelles ?
                                    <div className="appeloffre--result__valuecontainer">
                                        <span className="appeloffre--result__type">Note des clauses contractuelles<span className="appeloffre--result__value"> : {noteClausesContractuelles}/10</span>
                                            {showNoteClauses ?
                                                <span className="table--link" onClick={() => setShowNoteClauses(false)}>Moins de détails -</span>
                                                :
                                                <span className="table--link" onClick={() => setShowNoteClauses(true)}>Plus de détails +</span>
                                            }
                                        </span>
                                    </div>
                                    :
                                    <></>
                                }
                                {showNoteClauses &&
                                    <>
                                        {noteSuiviCommercial ? <div className="appeloffre--result__texte">Suivi Commercial : {noteSuiviCommercial} / 10
                                            <ul>
                                                {noteEspaceClientInternet ? <li className="appeloffre--result__list">Espace client internet : {noteEspaceClientInternet} / 10</li> : ""}
                                                {noteServiceClientEtReclamations ? <li className="appeloffre--result__list">Service client et réclamations : {noteServiceClientEtReclamations} / 10</li> : ""}
                                                {noteLimiteDeResponsabiliteDuFournisseur ? <li className="appeloffre--result__list">Limite de responsabilitédu fournisseur : {noteLimiteDeResponsabiliteDuFournisseur} / 10</li> : ""}
                                            </ul>
                                        </div>
                                            : ""}
                                        {noteTechnique ? <div className="appeloffre--result__texte">Note technique : {noteTechnique} / 10
                                            <ul>
                                                {noteDureeEtTypeContrat ? <li className="appeloffre--result__list">Durée et type du contrat : {noteDureeEtTypeContrat} / 10</li> : ""}
                                                {noteModeDeFacturation ? <li className="appeloffre--result__list">Mode de facturation : {noteModeDeFacturation} / 10</li> : ""}
                                                {noteModeDePaiement ? <li className="appeloffre--result__list">Mode de paiement : {noteModeDePaiement} / 10</li> : ""}
                                            </ul>
                                        </div>
                                            : ""}
                                        {noteTransparence ? <div className="appeloffre--result__texte">Transparence : {noteTransparence} / 10
                                            <ul>
                                                {noteInformationTarification ? <li className="appeloffre--result__list">Information de tarification : {noteInformationTarification} / 10</li> : ""}
                                                {noteConditionRevisionPrix ? <li className="appeloffre--result__list">Conditions de révision des prix (actualisation, indexation) : {noteConditionRevisionPrix} / 10</li> : ""}
                                                {noteEngagementDeVolume ? <li className="appeloffre--result__list">Engagements de volumes ou prévisions : {noteEngagementDeVolume} / 10</li> : ""}
                                                {noteConditionsResiliationClient ? <li className="appeloffre--result__list">Condition de résiliation à l'initiative du client : {noteConditionsResiliationClient} / 10</li> : ""}
                                                {noteConditionsResiliationFournisseur ? <li className="appeloffre--result__list">Conditions de résiliation à l'initiative du fournisseur : {noteConditionsResiliationFournisseur} / 10</li> : ""}
                                                {noteClausesSuspension ? <li className="appeloffre--result__list">Clause de suspension, inexécution, interruption du contrat de fourniture : {noteClausesSuspension} / 10</li> : ""}
                                            </ul>
                                        </div>
                                            : ""}
                                        {noteService ? <div className="appeloffre--result__texte">Services : {noteService} / 10
                                            <ul>
                                                {noteServiceQualite ? <li className="appeloffre--result__list">Des services de qualité : {noteServiceQualite} / 10</li> : ""}
                                            </ul>
                                        </div>
                                            : ""}
                                    </>
                                }
                            </div>
                            :
                            <>
                                <div className={`appeloffre--indicatif__container${isGaz ? "gaz" : ""}`}>
                                    {!isGaz && <div className={`table--tablecontainer mandates--block__container ${isBisVisible ? "appeloffre--indicatif__block" : ""}`}>
                                        <span className="appeloffre--indicatif__subtitle">Offre par défaut</span>
                                        <span className="appeloffre--indicatif__title">Proposition n°1{(dateDebutFourniture && dateFinNouveauContrat) && ` du ${new Date(dateDebutFourniture).toLocaleDateString()} au ${new Date(dateFinNouveauContrat).toLocaleDateString()}`}*</span>
                                        {(dateDebutFourniture && dateFinNouveauContrat) && <span className="appeloffre--result__date">Le contrat est souscrit pour un début de fourniture au {new Date(dateDebutFourniture).toLocaleDateString()} et une échéance au {new Date(dateFinNouveauContrat).toLocaleDateString()}.</span>}
                                        {dateCotation &&
                                            <div className="appeloffre--result__valuecontainer">
                                                <span className="appeloffre--result__type">Prix annuels HTVA : <span className="appeloffre--result__value">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVA)}</span>
                                                    {showPrixAnnuel ?
                                                        <span className="table--link" onClick={() => setShowPrixAnnuel(false)}>Moins de détails -</span>
                                                        :
                                                        <span className="table--link" onClick={() => setShowPrixAnnuel(true)}>Plus de détails +</span>
                                                    }
                                                </span>
                                            </div>
                                        }
                                        {showPrixAnnuel &&
                                            <>
                                                <div className="appeloffre--result__texte">Coût annuel :
                                                    <ul>
                                                        {coutAnnuelEstimeTTC ? <li className="appeloffre--result__list"><span className="appeloffre--result__price">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeTTC)}</span> {`(${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeTTCkWh)} / ${isGaz ? "kWh" : "MWh"}) TTC`}</li> : ""}
                                                        {coutAnnuelEstimeHTVA ? <li className="appeloffre--result__list"><span className="appeloffre--result__price">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVA)}</span> {`(${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVAkWh)} / ${isGaz ? "kWh" : "MWh"}) HTVA`}</li> : ""}
                                                    </ul>
                                                </div>
                                                <div className="appeloffre--result__texte "><span className="appeloffre--result__price">Dont :</span>
                                                    <ul>
                                                        {prixFournitureCalcule ? <li className="appeloffre--result__list">Total Electricité : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(prixFournitureCalcule)}</li> : ""}
                                                        {abonnement ? <li className={`appeloffre--result__list${!isGaz ? " appeloffre--result__listsecond" : ""}`}>Abonnement : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(abonnement)} / an</li> : ""}
                                                        {pointe ? <li className="appeloffre--result__list appeloffre--result__listsecond">Pointe : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(pointe)} / kWh</li> : ""}
                                                        {hph ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPH : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hph)} / kWh</li> : ""}
                                                        {hpd ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPD : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hpd)} / kWh</li> : ""}
                                                        {hch ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCH : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hch)} / kWh</li> : ""}
                                                        {hcd ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCD : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hcd)} / kWh</li> : ""}
                                                        {hpe ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hpe)} / kWh</li> : ""}
                                                        {hce ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hce)} / kWh</li> : ""}
                                                        {(mecaCEECentimeEurosParkWh || mecaCapaCentimeEurosParkWh) ? <li className="appeloffre--result__list appeloffre--result__listsecond">Coûts additionnels réglementaires : </li> : ""}
                                                        {mecaCEECentimeEurosParkWh ? <li className="appeloffre--result__list appeloffre--result__listthird">Mécanisme de soutien aux CEE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(mecaCEECentimeEurosParkWh)}</li> : ""}
                                                        {mecaCapaCentimeEurosParkWh ? <li className="appeloffre--result__list appeloffre--result__listthird">Mécanisme de capacité (coût moyen) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(mecaCapaCentimeEurosParkWh)}</li> : ""}
                                                        {ja ? <li className="appeloffre--result__list appeloffre--result__listsecond">JA : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(ja)} / kWh</li> : ""}
                                                        {turpeComplet ? <li className="appeloffre--result__list">Total TURPE (Coût réglementaire de l'utilisation des réseaux, estimé à date de cotation) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(turpeComplet)}</li> : ""}
                                                        {composanteGestion ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Gestion : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteGestion)}</li> : ""}
                                                        {composanteComptage ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Comptage : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteComptage)}</li> : ""}
                                                        {(composanteSoutirageFixe || composanteSoutirageVariable) ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Comptage : </li> : ""}
                                                        {composanteSoutirageFixe ? <li className="appeloffre--result__list appeloffre--result__listthird">Fixe : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteSoutirageFixe)}</li> : ""}
                                                        {composanteSoutirageVariable ? <li className="appeloffre--result__list appeloffre--result__listthird">Variable : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteSoutirageVariable)}</li> : ""}
                                                        {(totalTaxes && !isGaz) ? <li className="appeloffre--result__list">Total Taxes : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(totalTaxes)} (dont CTA, CSPE, TCCFE, TDCFE)</li> : ""}
                                                        {cta ? <li className="appeloffre--result__list appeloffre--result__listsecond">Contribution tarifaire d'acheminement (CTA) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(cta)}</li> : ""}
                                                        {taxes ? <li className={`appeloffre--result__list${!isGaz ? " appeloffre--result__listsecond" : ""}`}>{isGaz ? "Taxes (CTA, TICGN + terme fixe)" : "CSPE et taxes locales"} : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(taxes)}</li> : ""}
                                                        {tvA55 ? <li className="appeloffre--result__list">TVA 5,5% : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(tvA55)}</li> : ""}
                                                        {tvA20 ? <li className="appeloffre--result__list">TVA 20% : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(tvA20)}</li> : ""}
                                                        {terme ? <li className="appeloffre--result__list">Molécule + Stockage + CEE + Terme variable de distribution : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(terme)}({new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(termeMWh)} / MWh)</li> : ""}
                                                    </ul>
                                                    {!isGaz && <span>Toutes les valeurs des taxes, contributions et frais réglementaires sont données à date de cotation et sont susceptibles d'évoluer selon la réglementation en vigueur.</span>}
                                                </div>
                                            </>
                                        }
                                        {noteClausesContractuelles ?
                                            <div className="appeloffre--result__valuecontainer">
                                                <span className="appeloffre--result__type">Note des clauses contractuelles<span className="appeloffre--result__value"> : {noteClausesContractuelles}/10</span>
                                                    {showNoteClauses ?
                                                        <span className="table--link" onClick={() => setShowNoteClauses(false)}>Moins de détails -</span>
                                                        :
                                                        <span className="table--link" onClick={() => setShowNoteClauses(true)}>Plus de détails +</span>
                                                    }
                                                </span>
                                            </div>
                                            :
                                            <></>
                                        }
                                        {showNoteClauses &&
                                            <>
                                                {noteSuiviCommercial ? <div className="appeloffre--result__texte">Suivi Commercial : {noteSuiviCommercial} / 10
                                                    <ul>
                                                        {noteEspaceClientInternet ? <li className="appeloffre--result__list">Espace client internet : {noteEspaceClientInternet} / 10</li> : ""}
                                                        {noteServiceClientEtReclamations ? <li className="appeloffre--result__list">Service client et réclamations : {noteServiceClientEtReclamations} / 10</li> : ""}
                                                        {noteLimiteDeResponsabiliteDuFournisseur ? <li className="appeloffre--result__list">Limite de responsabilitédu fournisseur : {noteLimiteDeResponsabiliteDuFournisseur} / 10</li> : ""}
                                                    </ul>
                                                </div>
                                                    : ""}
                                                {noteTechnique ? <div className="appeloffre--result__texte">Note technique : {noteTechnique} / 10
                                                    <ul>
                                                        {noteDureeEtTypeContrat ? <li className="appeloffre--result__list">Durée et type du contrat : {noteDureeEtTypeContrat} / 10</li> : ""}
                                                        {noteModeDeFacturation ? <li className="appeloffre--result__list">Mode de facturation : {noteModeDeFacturation} / 10</li> : ""}
                                                        {noteModeDePaiement ? <li className="appeloffre--result__list">Mode de paiement : {noteModeDePaiement} / 10</li> : ""}
                                                    </ul>
                                                </div>
                                                    : ""}
                                                {noteTransparence ? <div className="appeloffre--result__texte">Transparence : {noteTransparence} / 10
                                                    <ul>
                                                        {noteInformationTarification ? <li className="appeloffre--result__list">Information de tarification : {noteInformationTarification} / 10</li> : ""}
                                                        {noteConditionRevisionPrix ? <li className="appeloffre--result__list">Conditions de révision des prix (actualisation, indexation) : {noteConditionRevisionPrix} / 10</li> : ""}
                                                        {noteEngagementDeVolume ? <li className="appeloffre--result__list">Engagements de volumes ou prévisions : {noteEngagementDeVolume} / 10</li> : ""}
                                                        {noteConditionsResiliationClient ? <li className="appeloffre--result__list">Condition de résiliation à l'initiative du client : {noteConditionsResiliationClient} / 10</li> : ""}
                                                        {noteConditionsResiliationFournisseur ? <li className="appeloffre--result__list">Conditions de résiliation à l'initiative du fournisseur : {noteConditionsResiliationFournisseur} / 10</li> : ""}
                                                        {noteClausesSuspension ? <li className="appeloffre--result__list">Clause de suspension, inexécution, interruption du contrat de fourniture : {noteClausesSuspension} / 10</li> : ""}
                                                    </ul>
                                                </div>
                                                    : ""}
                                                {noteService ? <div className="appeloffre--result__texte">Services : {noteService} / 10
                                                    <ul>
                                                        {noteServiceQualite ? <li className="appeloffre--result__list">Des services de qualité : {noteServiceQualite} / 10</li> : ""}
                                                    </ul>
                                                </div>
                                                    : ""}
                                            </>
                                        }
                                        <span className="appeloffre--result__date appeloffre--indicatif__asterisque">*Offre par défaut : sans action de votre part, cette offre sera mise à jour et engagée lors du tour final.</span>
                                    </div>}
                                    {(coutAnnuelEstimeHTVASecond && isBisVisible && !isGaz)
                                        ?
                                        <div className="table--tablecontainer mandates--block__container appeloffre--indicatif__block">
                                            <span className="appeloffre--indicatif__subtitle">Offre alternative</span>
                                            <span className="appeloffre--indicatif__title">Proposition n°2{(dateDebutFournitureSecond && dateFinNouveauContratSecond) && ` du ${new Date(dateDebutFournitureSecond).toLocaleDateString()} au ${new Date(dateFinNouveauContratSecond).toLocaleDateString()}`}</span>
                                            {(dateDebutFournitureSecond && dateFinNouveauContratSecond) && <span className="appeloffre--result__date">Le contrat est souscrit pour un début de fourniture au {new Date(dateDebutFournitureSecond).toLocaleDateString()} et une échéance au {new Date(dateFinNouveauContratSecond).toLocaleDateString()}.</span>}
                                            {coutAnnuelEstimeHTVASecond &&
                                                <div className="appeloffre--result__valuecontainer">
                                                    <span className="appeloffre--result__type">Prix annuels HTVA : <span className="appeloffre--result__value">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVASecond)}</span>
                                                        {showPrixAnnuelSecond ?
                                                            <span className="table--link" onClick={() => setShowPrixAnnuelSecond(false)}>Moins de détails -</span>
                                                            :
                                                            <span className="table--link" onClick={() => setShowPrixAnnuelSecond(true)}>Plus de détails +</span>
                                                        }
                                                    </span>
                                                </div>
                                            }
                                            {showPrixAnnuelSecond &&
                                                <>
                                                    <div className="appeloffre--result__texte">Coût annuel :
                                                        <ul>
                                                            {coutAnnuelEstimeTTCSecond ? <li className="appeloffre--result__list"><span className="appeloffre--result__price">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeTTCSecond)}</span> {`(${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeTTCkWhSecond)} / ${isGaz ? "kWh" : "MWh"}) TTC`}</li> : ""}
                                                            {coutAnnuelEstimeHTVASecond ? <li className="appeloffre--result__list"><span className="appeloffre--result__price">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVASecond)}</span> {`(${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVAkWhSecond)} / ${isGaz ? "kWh" : "MWh"}) HTVA`}</li> : ""}
                                                        </ul>
                                                    </div>
                                                    <div className="appeloffre--result__texte "><span className="appeloffre--result__price">Dont :</span>
                                                        <ul>
                                                            {prixFournitureCalculeSecond ? <li className="appeloffre--result__list">Total Electricité : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(prixFournitureCalculeSecond)}</li> : ""}
                                                            {abonnementSecond ? <li className={`appeloffre--result__list${!isGaz ? " appeloffre--result__listsecond" : ""}`}>Abonnement : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(abonnementSecond)} / an</li> : ""}
                                                            {pointeSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">Pointe : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(pointeSecond)} / kWh</li> : ""}
                                                            {hphSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPH : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hphSecond)} / kWh</li> : ""}
                                                            {hpd ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPD : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hpdSecond)} / kWh</li> : ""}
                                                            {hchSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCH : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hchSecond)} / kWh</li> : ""}
                                                            {hcdSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCD : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hcdSecond)} / kWh</li> : ""}
                                                            {hpeSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hpeSecond)} / kWh</li> : ""}
                                                            {hceSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hceSecond)} / kWh</li> : ""}
                                                            {(mecaCEECentimeEurosParkWhSecond || mecaCapaCentimeEurosParkWhSecond) ? <li className="appeloffre--result__list appeloffre--result__listsecond">Coûts additionnels réglementaires : </li> : ""}
                                                            {mecaCEECentimeEurosParkWhSecond ? <li className="appeloffre--result__list appeloffre--result__listthird">Mécanisme de soutien aux CEE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(mecaCEECentimeEurosParkWhSecond)}</li> : ""}
                                                            {mecaCapaCentimeEurosParkWhSecond ? <li className="appeloffre--result__list appeloffre--result__listthird">Mécanisme de capacité (coût moyen) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(mecaCapaCentimeEurosParkWhSecond)}</li> : ""}
                                                            {jaSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">JA : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(jaSecond)} / kWh</li> : ""}
                                                            {turpeCompletSecond ? <li className="appeloffre--result__list">Total TURPE (Coût réglementaire de l'utilisation des réseaux, estimé à date de cotation) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(turpeCompletSecond)}</li> : ""}
                                                            {composanteGestionSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Gestion : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteGestionSecond)}</li> : ""}
                                                            {composanteComptageSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Comptage : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteComptageSecond)}</li> : ""}
                                                            {(composanteSoutirageFixeSecond || composanteSoutirageVariableSecond) ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Comptage : </li> : ""}
                                                            {composanteSoutirageFixeSecond ? <li className="appeloffre--result__list appeloffre--result__listthird">Fixe : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteSoutirageFixeSecond)}</li> : ""}
                                                            {composanteSoutirageVariableSecond ? <li className="appeloffre--result__list appeloffre--result__listthird">Variable : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteSoutirageVariableSecond)}</li> : ""}
                                                            {(totalTaxesSecond && !isGaz) ? <li className="appeloffre--result__list">Total Taxes : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(totalTaxesSecond)} (dont CTA, CSPE, TCCFE, TDCFE)</li> : ""}
                                                            {ctaSecond ? <li className="appeloffre--result__list appeloffre--result__listsecond">Contribution tarifaire d'acheminement (CTA) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(ctaSecond)}</li> : ""}
                                                            {taxesSecond ? <li className={`appeloffre--result__list${!isGaz ? " appeloffre--result__listsecond" : ""}`}>{isGaz ? "Taxes (CTA, TICGN + terme fixe)" : "CSPE et taxes locales"} : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(taxesSecond)}</li> : ""}
                                                            {tvA55Second ? <li className="appeloffre--result__list">TVA 5,5% : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(tvA55Second)}</li> : ""}
                                                            {tvA20Second ? <li className="appeloffre--result__list">TVA 20% : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(tvA20Second)}</li> : ""}
                                                            {termeSecond ? <li className="appeloffre--result__list">Molécule + Stockage + CEE + Terme variable de distribution : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(termeSecond)}({new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(termeMWhSecond)} / MWh)</li> : ""}
                                                        </ul>
                                                        {!isGaz && <span>Toutes les valeurs des taxes, contributions et frais réglementaires sont données à date de cotation et sont susceptibles d'évoluer selon la réglementation en vigueur.</span>}
                                                    </div>
                                                </>
                                            }
                                            {noteClausesContractuellesSecond ?
                                                <div className="appeloffre--result__valuecontainer">
                                                    <span className="appeloffre--result__type">Note des clauses contractuelles<span className="appeloffre--result__value"> : {noteClausesContractuellesSecond}/10</span>
                                                        {showNoteClausesSecond ?
                                                            <span className="table--link" onClick={() => setShowNoteClausesSecond(false)}>Moins de détails -</span>
                                                            :
                                                            <span className="table--link" onClick={() => setShowNoteClausesSecond(true)}>Plus de détails +</span>
                                                        }
                                                    </span>
                                                </div>
                                                :
                                                <></>
                                            }
                                            {showNoteClausesSecond &&
                                                <>
                                                    {noteSuiviCommercialSecond ? <div className="appeloffre--result__texte">Suivi Commercial : {noteSuiviCommercialSecond} / 10
                                                        <ul>
                                                            {noteEspaceClientInternetSecond ? <li className="appeloffre--result__list">Espace client internet : {noteEspaceClientInternetSecond} / 10</li> : ""}
                                                            {noteServiceClientEtReclamationsSecond ? <li className="appeloffre--result__list">Service client et réclamations : {noteServiceClientEtReclamationsSecond} / 10</li> : ""}
                                                            {noteLimiteDeResponsabiliteDuFournisseurSecond ? <li className="appeloffre--result__list">Limite de responsabilitédu fournisseur : {noteLimiteDeResponsabiliteDuFournisseurSecond} / 10</li> : ""}
                                                        </ul>
                                                    </div>
                                                        : ""}
                                                    {noteTechniqueSecond ? <div className="appeloffre--result__texte">Note technique : {noteTechniqueSecond} / 10
                                                        <ul>
                                                            {noteDureeEtTypeContratSecond ? <li className="appeloffre--result__list">Durée et type du contrat : {noteDureeEtTypeContratSecond} / 10</li> : ""}
                                                            {noteModeDeFacturationSecond ? <li className="appeloffre--result__list">Mode de facturation : {noteModeDeFacturationSecond} / 10</li> : ""}
                                                            {noteModeDePaiementSecond ? <li className="appeloffre--result__list">Mode de paiement : {noteModeDePaiementSecond} / 10</li> : ""}
                                                        </ul>
                                                    </div>
                                                        : ""}
                                                    {noteTransparenceSecond ? <div className="appeloffre--result__texte">Transparence : {noteTransparenceSecond} / 10
                                                        <ul>
                                                            {noteInformationTarificationSecond ? <li className="appeloffre--result__list">Information de tarification : {noteInformationTarificationSecond} / 10</li> : ""}
                                                            {noteConditionRevisionPrixSecond ? <li className="appeloffre--result__list">Conditions de révision des prix (actualisation, indexation) : {noteConditionRevisionPrixSecond} / 10</li> : ""}
                                                            {noteEngagementDeVolumeSecond ? <li className="appeloffre--result__list">Engagements de volumes ou prévisions : {noteEngagementDeVolumeSecond} / 10</li> : ""}
                                                            {noteConditionsResiliationClientSecond ? <li className="appeloffre--result__list">Condition de résiliation à l'initiative du client : {noteConditionsResiliationClientSecond} / 10</li> : ""}
                                                            {noteConditionsResiliationFournisseurSecond ? <li className="appeloffre--result__list">Conditions de résiliation à l'initiative du fournisseur : {noteConditionsResiliationFournisseurSecond} / 10</li> : ""}
                                                            {noteClausesSuspensionSecond ? <li className="appeloffre--result__list">Clause de suspension, inexécution, interruption du contrat de fourniture : {noteClausesSuspensionSecond} / 10</li> : ""}
                                                        </ul>
                                                    </div>
                                                        : ""}
                                                    {noteServiceSecond ? <div className="appeloffre--result__texte">Services : {noteServiceSecond} / 10
                                                        <ul>
                                                            {noteServiceQualiteSecond ? <li className="appeloffre--result__list">Des services de qualité : {noteServiceQualiteSecond} / 10</li> : ""}
                                                        </ul>
                                                    </div>
                                                        : ""}
                                                </>
                                            }
                                            {(bisParticipationShow && !isGaz) ?
                                                <>
                                                    <div className="appeloffre--indicatif__checkcontainer">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={choseBisOffer}
                                                            inputProps={{
                                                                'aria-labelledby': "check-bis-offer",
                                                            }}
                                                            onClick={(event) => setChoseBisOffer(!choseBisOffer)}
                                                            sx={{
                                                                borderColor: "#3F3F46",
                                                                padding: "0",
                                                                '&.Mui-checked': {
                                                                    padding: "0",
                                                                    color: "#48AB4D",

                                                                },
                                                            }}
                                                        />
                                                        <span className="appeloffre--indicatif__checktext">Je souhaite participer au tour final de l'appel d'offres en cours WattValue pour obtenier un contrat avec échéance {new Date(dateFinNouveauContrat).toLocaleDateString()} et je renonace à reporter mon site vers l'appel d'offres suivant</span>
                                                    </div>
                                                    {choseBisOffer && <Button
                                                        cta="Sélectionner la proposition n°2"
                                                        additionnalClassName="appeloffre--indicatif__checkbutton"
                                                        handleSubmit={() => registerSecondOffer()}

                                                    />}
                                                </>
                                                :
                                                ""
                                            }
                                        </div>
                                        :
                                        ""}
                                    {(isGaz && coutAnnuelEstimeHTVAGazArray.length > 0) &&
                                        coutAnnuelEstimeHTVAGazArray.map((element, index) => {
                                            if (element.offerNumber === index) {
                                                return (
                                                    isDataLoadingGazArray[index] ?
                                                        <span key={`block-proposition-gaz-${index}`} className="global--loader"></span>
                                                        :
                                                        <div key={`block-proposition-gaz-${index}`} className="table--tablecontainer mandates--block__container">
                                                            <span className="appeloffre--indicatif__title">Proposition n°{element.offerNumber}{(dateDebutFournitureGazArray[element.offerNumber]?.data && dateFinNouveauContratGazArray[element.offerNumber]?.data) && ` du ${new Date(dateDebutFournitureGazArray[element.offerNumber]?.data).toLocaleDateString()} au ${new Date(dateFinNouveauContratGazArray[element.offerNumber]?.data).toLocaleDateString()}`}</span>
                                                            {(dateDebutFournitureGazArray[element.offerNumber]?.data && dateFinNouveauContratGazArray[element.offerNumber]?.data) && <span className="appeloffre--result__date">Le contrat est souscrit pour un début de fourniture au {new Date(dateDebutFournitureGazArray[element.offerNumber]?.data).toLocaleDateString()} et une échéance au {new Date(dateFinNouveauContratGazArray[element.offerNumber]?.data).toLocaleDateString()}.</span>}
                                                            {coutAnnuelEstimeHTVAGazArray[element.offerNumber]?.data &&
                                                                <div className="appeloffre--result__valuecontainer">
                                                                    <span className="appeloffre--result__type">Prix annuels HTVA : <span className="appeloffre--result__value">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVAGazArray[element.offerNumber]?.data)}</span>
                                                                        {showPrixAnnuelGazArray[element.offerNumber - 1] ?
                                                                            <span className="table--link" onClick={() => updateShowPrixAnnuelGazArray(element.offerNumber - 1, false)}>Moins de détails -</span>
                                                                            :
                                                                            <span className="table--link" onClick={() => updateShowPrixAnnuelGazArray(element.offerNumber - 1, true)}>Plus de détails +</span>
                                                                        }
                                                                    </span>
                                                                </div>
                                                            }
                                                            {showPrixAnnuelGazArray[element.offerNumber - 1] &&
                                                                <>
                                                                    <div className="appeloffre--result__texte">Coût annuel :
                                                                        <ul>
                                                                            {coutAnnuelEstimeTTCGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list"><span className="appeloffre--result__price">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeTTCGazArray[element.offerNumber]?.data)}</span> {`(${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeTTCkWhGazArray[element.offerNumber]?.data)} / ${isGaz ? "kWh" : "MWh"}) TTC`}</li> : ""}
                                                                            {coutAnnuelEstimeHTVAGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list"><span className="appeloffre--result__price">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVAGazArray[element.offerNumber]?.data)}</span> {`(${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutAnnuelEstimeHTVAkWhGazArray[element.offerNumber]?.data)} / ${isGaz ? "kWh" : "MWh"}) HTVA`}</li> : ""}
                                                                        </ul>
                                                                    </div>
                                                                    <div className="appeloffre--result__texte "><span className="appeloffre--result__price">Dont :</span>
                                                                        <ul>
                                                                            {prixFournitureCalculeGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Total Electricité : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(prixFournitureCalculeGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {abonnementGazArray[element.offerNumber]?.data ? <li className={`appeloffre--result__list${!isGaz ? " appeloffre--result__listsecond" : ""}`}>Abonnement : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(abonnementGazArray[element.offerNumber]?.data)} / an</li> : ""}
                                                                            {pointeGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">Pointe : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(pointeGazArray[element.offerNumber]?.data)} / kWh</li> : ""}
                                                                            {hphGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPH : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hphGazArray[element.offerNumber]?.data)} / kWh</li> : ""}
                                                                            {hpd ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPD : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hpdGazArray[element.offerNumber]?.data)} / kWh</li> : ""}
                                                                            {hchGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCH : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hchGazArray[element.offerNumber]?.data)} / kWh</li> : ""}
                                                                            {hcdGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCD : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hcdGazArray[element.offerNumber]?.data)} / kWh</li> : ""}
                                                                            {hpeGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">HPE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hpeGazArray[element.offerNumber]?.data)} / kWh</li> : ""}
                                                                            {hceGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">HCE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(hceGazArray[element.offerNumber]?.data)} / kWh</li> : ""}
                                                                            {(mecaCEECentimeEurosParkWhGazArray[element.offerNumber]?.data || mecaCapaCentimeEurosParkWhGazArray[element.offerNumber]?.data) ? <li className="appeloffre--result__list appeloffre--result__listsecond">Coûts additionnels réglementaires : </li> : ""}
                                                                            {mecaCEECentimeEurosParkWhGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listthird">Mécanisme de soutien aux CEE : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(mecaCEECentimeEurosParkWhGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {mecaCapaCentimeEurosParkWhGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listthird">Mécanisme de capacité (coût moyen) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(mecaCapaCentimeEurosParkWhGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {jaGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">JA : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(jaGazArray[element.offerNumber]?.data)} / kWh</li> : ""}
                                                                            {turpeCompletGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Total TURPE (Coût réglementaire de l'utilisation des réseaux, estimé à date de cotation) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(turpeCompletGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {composanteGestionGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Gestion : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteGestionGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {composanteComptageGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Comptage : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteComptageGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {(composanteSoutirageFixeGazArray[element.offerNumber]?.data || composanteSoutirageVariableGazArray[element.offerNumber]?.data) ? <li className="appeloffre--result__list appeloffre--result__listsecond">Composante de Comptage : </li> : ""}
                                                                            {composanteSoutirageFixeGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listthird">Fixe : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteSoutirageFixeGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {composanteSoutirageVariableGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listthird">Variable : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(composanteSoutirageVariableGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {(totalTaxesGazArray[element.offerNumber]?.data && !isGaz) ? <li className="appeloffre--result__list">Total Taxes : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(totalTaxesGazArray[element.offerNumber]?.data)} (dont CTA, CSPE, TCCFE, TDCFE)</li> : ""}
                                                                            {ctaGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list appeloffre--result__listsecond">Contribution tarifaire d'acheminement (CTA) : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(ctaGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {taxesGazArray[element.offerNumber]?.data ? <li className={`appeloffre--result__list${!isGaz ? " appeloffre--result__listsecond" : ""}`}>{isGaz ? "Taxes (CTA, TICGN + terme fixe)" : "CSPE et taxes locales"} : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(taxesGazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {tvA55GazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">TVA 5,5% : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(tvA55GazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {tvA20GazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">TVA 20% : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(tvA20GazArray[element.offerNumber]?.data)}</li> : ""}
                                                                            {termeGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Molécule + Stockage + CEE + Terme variable de distribution : {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(termeGazArray[element.offerNumber]?.data)}({new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(termeMWhGazArray[element.offerNumber]?.data)} / MWh)</li> : ""}
                                                                        </ul>
                                                                        {!isGaz && <span>Toutes les valeurs des taxes, contributions et frais réglementaires sont données à date de cotation et sont susceptibles d'évoluer selon la réglementation en vigueur.</span>}
                                                                    </div>
                                                                </>
                                                            }
                                                            {noteClausesContractuellesGazArray[element.offerNumber]?.data ?
                                                                <div className="appeloffre--result__valuecontainer">
                                                                    <span className="appeloffre--result__type">Note des clauses contractuelles<span className="appeloffre--result__value"> : {noteClausesContractuellesGazArray[element.offerNumber]?.data}/10</span>
                                                                        {showNoteClausesGazArray[element.offerNumber - 1] ?
                                                                            <span className="table--link" onClick={() => updateShowNoteClausesGazArray(element.offerNumber - 1, false)}>Moins de détails -</span>
                                                                            :
                                                                            <span className="table--link" onClick={() => updateShowNoteClausesGazArray(element.offerNumber - 1, true)}>Plus de détails +</span>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                :
                                                                <></>
                                                            }
                                                            {showNoteClausesGazArray[element.offerNumber - 1]?.data &&
                                                                <>
                                                                    {noteSuiviCommercialGazArray[element.offerNumber]?.data ? <div className="appeloffre--result__texte">Suivi Commercial : {noteSuiviCommercialGazArray[element.offerNumber]?.data} / 10
                                                                        <ul>
                                                                            {noteEspaceClientInternetGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Espace client internet : {noteEspaceClientInternetGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                            {noteServiceClientEtReclamationsGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Service client et réclamations : {noteServiceClientEtReclamationsGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                            {noteLimiteDeResponsabiliteDuFournisseurGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Limite de responsabilitédu fournisseur : {noteLimiteDeResponsabiliteDuFournisseurGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                        </ul>
                                                                    </div>
                                                                        : ""}
                                                                    {noteTechniqueGazArray[element.offerNumber]?.data ? <div className="appeloffre--result__texte">Note technique : {noteTechniqueGazArray[element.offerNumber]?.data} / 10
                                                                        <ul>
                                                                            {noteDureeEtTypeContratGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Durée et type du contrat : {noteDureeEtTypeContratGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                            {noteModeDeFacturationGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Mode de facturation : {noteModeDeFacturationGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                            {noteModeDePaiementGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Mode de paiement : {noteModeDePaiementGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                        </ul>
                                                                    </div>
                                                                        : ""}
                                                                    {noteTransparenceGazArray[element.offerNumber]?.data ? <div className="appeloffre--result__texte">Transparence : {noteTransparenceGazArray[element.offerNumber]?.data} / 10
                                                                        <ul>
                                                                            {noteInformationTarificationGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Information de tarification : {noteInformationTarificationGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                            {noteConditionRevisionPrixGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Conditions de révision des prix (actualisation, element.offerNumberation) : {noteConditionRevisionPrixGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                            {noteEngagementDeVolumeGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Engagements de volumes ou prévisions : {noteEngagementDeVolumeGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                            {noteConditionsResiliationClientGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Condition de résiliation à l'initiative du client : {noteConditionsResiliationClientGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                            {noteConditionsResiliationFournisseurGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Conditions de résiliation à l'initiative du fournisseur : {noteConditionsResiliationFournisseurGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                            {noteClausesSuspensionGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Clause de suspension, inexécution, interruption du contrat de fourniture : {noteClausesSuspensionGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                        </ul>
                                                                    </div>
                                                                        : ""}
                                                                    {noteServiceGazArray[element.offerNumber]?.data ? <div className="appeloffre--result__texte">Services : {noteServiceGazArray[element.offerNumber]?.data} / 10
                                                                        <ul>
                                                                            {noteServiceQualiteGazArray[element.offerNumber]?.data ? <li className="appeloffre--result__list">Des services de qualité : {noteServiceQualiteGazArray[element.offerNumber]?.data} / 10</li> : ""}
                                                                        </ul>
                                                                    </div>
                                                                        : ""}
                                                                </>
                                                            }
                                                        </div>
                                                )
                                            }
                                            else return null
                                        })}
                                </div>
                            </>}
                        {(!isDefinitif && reportShow && !isGaz && reportCheckState === null) &&
                            <div className="table--tablecontainer mandates--block__container">
                                <span className="appeloffre--indicatif__report">Je souhaite reporter la participation de mon site au prochain appel d'offres WattValue.</span>
                                <Button
                                    cta="Reporter ma participation"
                                    handleSubmit={() => reportAppelOffres()}
                                    additionnalClassName="appeloffre--indicatif__reportbutton"
                                />
                            </div>}
                    </>
                }
            </div>
            {showConfirmationPopup &&
                <InformationPopup
                    closeInformationPopup={() => handleClosePopup(false)}
                    title={popupTitle}
                    subtitle={popupSubtitle}
                    cta="Retourner aux appels d'offres"
                    handleSubmit={() => handleClosePopup(false)}
                />
            }
        </>
    )
}

export default AppelsOffreResult;