import * as React from 'react'
import { Link } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { getComparator } from '../../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../../Utils/SortingUtils/stableSort'
import EnhancedTableHead from '../../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../../Components/TableComponents/StyledTableCell'
import { headCellsTurpeSites } from '../../../Utils/TableUtils/headCells'
import Open from '../../../img/icons/suppr.svg'
import { Tooltip } from '@mui/material'
import Elec from '../../../img/icons/elec.svg'

function TurpeTable(props) {
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('datePublication')
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        props.setPage(0)
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator(order, orderBy)).slice(
                props.page * rowsPerPage,
                props.page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, props.page, rowsPerPage, props.rows],
    )

    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        headCells={headCellsTurpeSites}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            return (
                                <TableRow key={`sites-${index}`}>
                                    <StyledTableCell><img src={Elec} alt="Elec" /></StyledTableCell>
                                    <StyledTableCell>
                                        <Link to={`/optimisation-turpe-detail?prm=${row.prm}`}><div className="elec--center">
                                            <img alt="Modify" src={Open} className="table--icon" />
                                        </div></Link>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title={row.libelle} enterTouchDelay={0}>
                                            <span>{row.libelle && row.libelle}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title={row.adresse} enterTouchDelay={0}>
                                            <span>{row.adresse && row.adresse}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title={row.prm} enterTouchDelay={0}>
                                            <span>{row.prm && row.prm}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.totalEconomie && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(row.totalEconomie)}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {(row.datePublication && row.datePublication !== "0001-01-01T00:00:00") && new Date(row.datePublication).toLocaleDateString()}
                                    </StyledTableCell>
                                </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell sx={{ padding: "5px" }} colSpan={6} />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Lignes par page"
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={props.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                sx={{
                    '.MuiTablePagination-toolbar': {
                        color: "#18181b !important",
                    },
                    '.MuiTablePagination-selectLabel': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-select': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-displayedRows': {
                        fontFamily: "Plus Jakarta Sans, sans-serif !important",
                        color: "#18181b !important",
                    },
                }}
            />
        </Box >
    )

}

export default TurpeTable;