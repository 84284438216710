import { useState } from "react"

export default function ToolTip({ children, text, marginTop, position, type, top, left, ...rest }) {
    const [show, setShow] = useState(false)

    return (
        <>
            <div
                className={`tooltip--container
                    ${type !== "headcell" ? " tooltip--container__form" : ""}
                    ${position === "right" ? " tooltip--container__right" : ""}
                    ${position === "left" ? " tooltip--container__left" : ""}
                    ${position === "top" ? " tooltip--container__top" : ""}`}
                style={{ display: show ? "flex" : "none", marginTop: marginTop ? `${marginTop}px` : "22px", top: top ? `${top}px` : "", left: left ? `${left}px` : "" }}
            >
                {position === "right" && <div className="tooltip--container__triangle tooltip--container__triangleright"></div>}
                {type !== "headcell" && <div className="tooltip--container__triangle tooltip--container__triangletop"></div>}
                <div className={`tooltip--container__text ${type === "headcell" ? "tooltip--container__white" : ""}`}>{text}</div>
                {position === "left" && <div className="tooltip--container__triangle tooltip--container__triangleleft"></div>}
            </div>
            <div
                {...rest}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
            >
                {children}
            </div>
        </>
    )
}