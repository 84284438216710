import MagnifierIcon from '../img/icons/magnifier.svg'
import CloseIcon from '@mui/icons-material/Close'

export function SearchBar(props) {
    let barClassName = "searchbar--bar"
    if (props.additionnalBarClassName && typeof props.additionnalBarClassName === "string") barClassName = `${barClassName} ${props.additionnalBarClassName}`
    let formClassName = ""
    if (props.additionnalFormClassName && typeof props.additionnalFormClassName === "string") formClassName = `${formClassName} ${props.additionnalFormClassName}`

    return (
        <form className={formClassName}>
            <div className="searchbar--container">
                <img
                    src={MagnifierIcon}
                    alt="rechercher"
                    className="searchbar--magnifiericon" />
                <input
                    id={`search-bar-${props.id}`}
                    type="text"
                    value={props.value}
                    className={barClassName}
                    placeholder="Rechercher"
                    onChange={(e) => {
                        props.filterData(e.target.value, props.type)
                        props.setValue(e.target.value)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            e.preventDefault()
                    }}
                />
                {props.value?.length > 0 && <CloseIcon
                    sx={{ color: "#A1A1AA", position: "absolute", top: props.closerIcon ? "6px" : "4px", right: props.closerIcon ? "40px" : "-25px", cursor: "pointer" }}
                    onClick={() => {
                        props.filterData("", props.type)
                        props.setValue("")
                    }}
                />}
            </div>
        </form>
    )
}