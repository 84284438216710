export const removeAccents = (text) => {
    let result = text.toLowerCase();
    result = result.replace(new RegExp("[àáâãäå]", 'g'), "a");
    result = result.replace(new RegExp("æ", 'g'), "ae");
    result = result.replace(new RegExp("ç", 'g'), "c");
    result = result.replace(new RegExp("[èéêë]", 'g'), "e");
    result = result.replace(new RegExp("[ìíîï]", 'g'), "i");
    result = result.replace(new RegExp("ñ", 'g'), "n");
    result = result.replace(new RegExp("[òóôõö]", 'g'), "o");
    result = result.replace(new RegExp("œ", 'g'), "oe");
    result = result.replace(new RegExp("[ùúûü]", 'g'), "u");
    result = result.replace(new RegExp("[ýÿ]", 'g'), "y");
    return result;
}; 