import Download from '../../img/icons/download.svg'

function ImportFile(props) {

    return (
        <>
            <span className="homepage--popup__inputlabel">{props.label}</span>
            <div className="resources--input" onClick={() => props.handleClick(props.name)}>
                {props.placeholder}
                <img
                    src={Download}
                    alt="Télécharger"
                    width={15}
                    height={15}
                    className="resources--input__icon"
                />
            </div>
        </>
    )
}

export default ImportFile;