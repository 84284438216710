function descendingComparator(a, b, orderBy) {
    if ((!a[orderBy] && a[orderBy] !== 0) || (!b[orderBy] && b[orderBy] !== 0)) {
        if (!a[orderBy] == !b[orderBy]) return 0
        if (a[orderBy] == null) return -1
        else return 1
    }
    if (typeof a[orderBy] === "string") {
        if (b[orderBy].toLocaleLowerCase() < a[orderBy].toLocaleLowerCase()) {
            return -1;
        }
        if (b[orderBy].toLocaleLowerCase() > a[orderBy].toLocaleLowerCase()) {
            return 1;
        }
        return 0;
    }
    if (typeof a[orderBy] === "number") {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}