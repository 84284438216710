import { useCallback, useEffect, useState } from "react"
import { useSearchParams, Link } from 'react-router-dom'

import SideBar from "../../../Components/SideBar/SideBar"
import axios from "axios"
import CircleIcon from '@mui/icons-material/Circle'
import FormInput from "../../../Components/Form/FormInput"
import Button from "../../../Components/Button"
import InformationPopup from "../../../Components/InformationPopup"

const apiUrl = process.env.REACT_APP_API_URL

function Subscription() {
    const [searchParams] = useSearchParams()
    const siteID = searchParams.get('siteId')
    const isRenewall = window.location.pathname.includes('renouvellement')

    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isSubmitDataLoading, setIsSubmitDataLoading] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard")

    const [price, setPrice] = useState("")
    const [libelle, setLibelle] = useState("")
    const [prm, setPrm] = useState("")
    const [address, setAdress] = useState("")
    const [souscriptionID, setSouscriptionID] = useState("")

    const [checkCGV, setCheckCGV] = useState(false)
    const [checkResponsible, setCheckResponsible] = useState(false)
    const [showPopup, setShowPopup] = useState(false)

    const getSiteData = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/Conso/Site/${siteID}/Abonnement`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                setLibelle(response.data.libelle)
                setPrm(response.data.prm)
                setAdress(`${response.data.adresse} ${response.data.codePostal} ${response.data.ville}`)
                setPrice(response.data.cout)
                setSouscriptionID(response.data.souscriptionID)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [siteID])

    useEffect(() => {
        getSiteData()
    }, [getSiteData])

    const validateSubmit = () => {
        setShowErrorMessage(false)
        setIsSubmitDataLoading(true)
        axios.post(`${apiUrl}/Conso/Site/${siteID}/${isRenewall ? `Renouvellement/${souscriptionID}` : "Souscription"}`,
            '',
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then(() => {
                setShowPopup(true)
            })
            .catch(error => {
                if (error?.response?.data?.message) setErrorMessage(error.response.data.message)
                setShowErrorMessage(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsSubmitDataLoading(false))
    }

    const circleSx = { fontSize: "9px", color: "#48AB4D", marginRight: "4px", marginTop: "5px" }

    return (
        <>
            <SideBar
                active="Conso"
                openElec />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">{isRenewall ? "Renouvellez" : "Souscrivez"} dès aujourd'hui {isRenewall ? "à notre" : "votre"} service de suivi des consommations par courbes de charges</h3>
                    <Link to="/conso"><span className="privatepages--header__return">{`< Retour à la liste des sites`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                {
                    isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <div className="subscription--container">
                            <div className="subscription--container__block">
                                <span className="subscription--left__title">Profitez de tous les avantages en vous abonnant :</span>
                                <ul>
                                    <li className="conso--detail__list subscription--left__list">
                                        <CircleIcon sx={circleSx} />
                                        <p className="conso--detail__linklistparagraph">Suivez en temps réel vos consommations d'électricité (en kWh) à l'heure, au jour, et au mois pour garder le contrôle.</p>
                                    </li>
                                    <li className="conso--detail__list subscription--left__list">
                                        <CircleIcon sx={circleSx} />
                                        <p className="conso--detail__linklistparagraph">Maîtrisez votre budget en surveillant vos dépenses électriques (en €) à tout moment, et exportez facilement vos données.</p>
                                    </li>
                                    <li className="conso--detail__list subscription--left__list">
                                        <CircleIcon sx={circleSx} />
                                        <p className="conso--detail__linklistparagraph">Évaluez l'impact de vos actions d'économie d'énergie en comparant les résultats entre deux périodes.</p>
                                    </li>
                                    <li className="conso--detail__list subscription--left__list">
                                        <CircleIcon sx={circleSx} />
                                        <p className="conso--detail__linklistparagraph subscription--link" onClick={() => window.open('/Service_Courbe_de_charge-v2.pdf')}>En savoir plus</p>
                                    </li>
                                </ul>
                                {prm ? <span className="subscription--left__title subscription--left__prm">PRM : <span className="subscription--left__value">{prm}</span></span> : ""}
                                {address ? <span className="subscription--left__title">Nom et adresse du site : <span className="subscription--left__value">{libelle} {address}</span></span> : ""}
                                <p className="subscription--left__paragraph">
                                    <span className="subscription--left__bold">Pourquoi souscrire ?</span>
                                </p>
                                <ul>
                                    <li className="conso--detail__list subscription--left__list">
                                        <CircleIcon sx={circleSx} />
                                        <p className="conso--detail__linklistparagraph">Des données mises à jour quotidiennement pour un suivi précis de vos consommations.</p>
                                    </li>
                                    <li className="conso--detail__list subscription--left__list">
                                        <CircleIcon sx={circleSx} />
                                        <p className="conso--detail__linklistparagraph">Un historique détaillé de vos consommations pour analyser vos usages sur la durée.</p>
                                    </li>
                                    <li className="conso--detail__list subscription--left__list">
                                        <CircleIcon sx={circleSx} />
                                        <p className="conso--detail__linklistparagraph">Activation rapide, sous 4 à 5 jours après validation.</p>
                                    </li>
                                </ul>
                                <p className="subscription--left__paragraph subscription--left__prm">
                                    Ne manquez pas cette opportunité de reprendre le contrôle de votre consommation d'électricité et d'<span className="subscription--left__bold">optimiser votre budget</span>.
                                    <br /><br />
                                    Souscrivez maintenant et commencez à économiser dès aujourd'hui !
                                </p>
                            </div>
                            {price ?
                                <div className="subscription--container__block subscription--right">
                                    <span className="subscription--left__title">Résumé de votre abonnement</span>
                                    <span className="subscription--right__price">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price)} <span className="subscription--right__pricetext">HT pour 12 mois</span></span>
                                    <span className="subscription--right__subtitle">Prélevés le mois suivant l'activation du service</span>
                                    <div className="conso--detail__list">
                                        <FormInput
                                            type="checkbox"
                                            key={`checkbox-CGV-1`}

                                            isSelected={checkCGV}
                                            selectBox={() => setCheckCGV(!checkCGV)}
                                            additionnalLabelClassName="homepage--checkbox__label conso--filters__label"
                                        />
                                        <span className="subscription--right__check">Je certifie avoir pris connaissance des <span className="subscription--link" onClick={() => window.open('https://www.wattunity.com/documents/CG_CDC_12mois_VF.pdf')}>conditions générales d'abonnement</span> au service de suivi des consomations par courbes de charges et les accepte sans réserve</span>
                                    </div>
                                    <div className="conso--detail__list">
                                        <FormInput
                                            type="checkbox"
                                            key={`checkbox-CGV-2`}

                                            isSelected={checkResponsible}
                                            selectBox={() => setCheckResponsible(!checkResponsible)}
                                            additionnalLabelClassName="homepage--checkbox__label conso--filters__label"
                                        />
                                        <span className="subscription--right__check">Je suis dûment habilité à souscrire au service pour la société</span>
                                    </div>
                                    <Button
                                        cta="Je souscris"
                                        additionnalClassName="subscription--right__button"
                                        disabled={!checkResponsible || !checkCGV}
                                        handleSubmit={validateSubmit}
                                        isLoading={isSubmitDataLoading}
                                    />
                                    {showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessage}</span>}
                                </div>
                                :
                                <div className="subscription--right subscription--container__emptystate">
                                    Merci de nous contacter directement pour connaître le prix.
                                    <Button
                                        cta="Nous contacter"
                                        link='/new-message'
                                        additionnalClassName="global--button__margintop33"
                                    />
                                </div>
                            }
                        </div>
                }
                <div className="subscription--video__container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/aSwHRnYkUVQ?si=iHftwM1SrjpGpsK3"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    >
                    </iframe>
                </div>
            </div>
            {showPopup &&
                <InformationPopup
                    title="Votre souscription a bien été prise en compte."
                    text="Suite à cette validation, vous recevrez un mail pour confirmer votre souscription."
                    link="/conso"
                    cta="Retourner à la liste des sites"
                    hideClose />
            }
        </>
    )
}

export default Subscription;