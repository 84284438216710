export const optionsFonction = [
    { value: "Direction d'entreprise", label: "Direction d'entreprise" },
    { value: "Administration, gestion, finance, achats", label: "Administration, gestion, finance, achats" },
    { value: "Ressources humaines", label: "Ressources humaines" },
    { value: "Commercial, marketing, ventes", label: "Commercial, marketing, ventes" },
    { value: "Communication, Développement Durable", label: "Communication, Développement Durable" },
    { value: "Informatique", label: "Informatique" },
    { value: "Études, recherche et développement", label: "Études, recherche et développement" },
    { value: "Production industrielle, travaux et chantiers", label: "Production industrielle, travaux et chantiers" },
    { value: "Services techniques", label: "Services techniques" },
    { value: "Autres", label: "Autres" },
]