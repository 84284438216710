import Logo from '../../img/Logo-wattunity.png'
import Landscape from '../../img/loginpage-landscape.png'
import LoginForm from '../../Components/Form/LoginForm'
import { useState } from 'react'
import InformationPopup from '../../Components/InformationPopup'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { validPassword } from '../../Utils/Regex/validPassword'
import axios from 'axios'
import { useEffect } from 'react'

const apiUrl = process.env.REACT_APP_API_URL

function Password() {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const guid = searchParams.get('guid')

    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showInformationPopup, setShowInformationPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [inputType, setInputType] = useState("password")

    useEffect(() => {
        if (location.pathname === "/recuperation-contributor") {
            axios.post(`${apiUrl}/contact/validate/check`,
                guid,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then()
                .catch(error => {
                    navigate("/password-fail")
                })
        }
        else {
            axios.get(`${apiUrl}/Recuperation/Check/${guid}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then()
                .catch(error => {
                    navigate("/password-fail")
                })
        }
    }, [location.pathname, guid, navigate])

    function closeInformationPopup() {
        setShowInformationPopup(false)
    }

    const handleSubmitPasswordStep2 = async () => {
        if (password !== passwordConfirmation) {
            setErrorMessage("Le mot de passe et la confirmation ne sont pas identiques")
            setShowErrorMessage(true)
        }
        else {
            setIsLoading(true)
            setShowErrorMessage(false)
            const checkUrl = (location.pathname === "/recuperation-contributor") ? "contact/validate" : "recuperation/setpassword"
            const body = (location.pathname === "/recuperation-contributor") ?
                JSON.stringify({
                    "contactID": `${id}`,
                    "guid": `${guid}`,
                    "password": `${password}`,
                })
                :
                JSON.stringify({
                    "guid": `${guid}`,
                    "password": `${password}`,
                })
            try {
                await axios.post(
                    `${apiUrl}/${checkUrl}`,
                    body,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: true,
                    })
                setIsLoading(false)
                return navigate('/password-changed')
            }
            catch (error) {
                if (error?.response?.data?.message) setErrorMessage(error.response.data.message)
                setIsLoading(false)
                setShowErrorMessage(true)
            }
        }

    }

    const handlePasswordClick = (id, value) => {
        setShowErrorMessage(false)
        if (id === "password") {
            setPassword(value)
        }
        if (id === "passwordconfirmation") {
            setPasswordConfirmation(value)
        }
        if (!validPassword.test(value) && value !== "") {
            setErrorMessage("Le mot de passe doit contenir au moins 8 caractères dont un spécial")
            setShowErrorMessage(true)
        }
    }

    const onClickEyeIcon = () => {
        inputType === "password" ? setInputType("text") : setInputType("password")
    }

    return (
        <div className="homepage--container homepage--publiccontainer">
            <div className="homepage--popup">
                <div className="homepage--popup__leftpart">
                    <div className="homepage--popup__logo">
                        <img src={Logo} alt="Logo WattValue" className="homepage--popup__logoimg" />
                        <span className="homepage--popup__baseline">Groupement d'achat & Services à l'énergie</span>
                    </div>
                    <LoginForm
                        formStep="PasswordStep2"
                        password={password}
                        handlePasswordClick={handlePasswordClick}
                        passwordConfirmation={passwordConfirmation}
                        handleSubmitPasswordStep2={handleSubmitPasswordStep2}
                        showErrorMessage={showErrorMessage}
                        errorMessage={errorMessage}
                        isLoading={isLoading}
                        inputType={inputType}
                        setInputType={setInputType}
                        onClickEyeIcon={onClickEyeIcon}
                    />
                </div>
                <img src={Landscape} alt="Landscape" className="homepage--popup__img" />
            </div>
            {showInformationPopup && <InformationPopup
                type="newsletter"
                showInformationPopup={showInformationPopup}
                closeInformationPopup={closeInformationPopup}
            />}
        </div>
    )
}

export default Password;