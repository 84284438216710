import { useState, useEffect, useCallback } from "react"
import { useSearchParams, Link } from 'react-router-dom'

import SideBar from "../../Components/SideBar/SideBar"
import Button from "../../Components/Button"
import InformationPopup from "../../Components/InformationPopup"
import axios from "axios"

const apiUrl = process.env.REACT_APP_API_URL

function AddSitesStep4() {
    const [searchParams] = useSearchParams()
    const [siteID] = useState(searchParams.get('id') || "")
    const [fromAppelOffre] = useState(searchParams.get('from') === "appels-offre")
    const isAdd = window.location.pathname.includes('add')

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [isConfirmed, setIsConfirmed] = useState(false)

    const [forfaitAppelOffre, setForfaitAppelOffre] = useState("")
    const [remunerationMensuelle, setRemunerationMensuelle] = useState("")
    const [partRenouvelable, setPartRenouvelable] = useState(0)
    const [nombreMoisAbonnement, setNombreMoisAbonnement] = useState(0)

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [errorMessagePopup, setErrorMessagePopup] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [isConfirmationLoading, setIsConfirmationLoading] = useState(false)
    const [showErrorMessagePopup, setShowErrorMessagePopup] = useState(false)
    const [popupType, setPopupType] = useState("confirmation-add-site")

    const getSiteData = useCallback(() => {
        axios.get(`${apiUrl}/Elec/Site/${siteID}/4`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                if (response && response.data) {
                    response.data.forfaitAppelOffre && setForfaitAppelOffre(response.data.forfaitAppelOffre)
                    response.data.remunerationMensuelle && setRemunerationMensuelle(response.data.remunerationMensuelle)
                    response.data.partRenouvelable && setPartRenouvelable(response.data.partRenouvelable)
                    response.data.nombreMoisAbonnement && setNombreMoisAbonnement(response.data.nombreMoisAbonnement)

                    response.data.isConfirmed && setIsConfirmed(response.data.isConfirmed)
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [siteID])

    const confirmAddSite = () => {
        setIsConfirmationLoading(true)
        setShowErrorMessagePopup(false)
        axios.post(`${apiUrl}/Elec/Site/${siteID}/4/Confirm`,
            "",
            {
                headers: {
                    'accept': '*/*',
                    'content-type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true,
            })
            .then(() => {
                setPopupType("add-site-confirmed")
                getSiteData()
            })
            .catch(error => {
                if (error?.response?.data?.message) setErrorMessagePopup(error.response.data.message)
                setShowErrorMessagePopup(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsConfirmationLoading(false))
    }

    const handleSubmitStep4 = () => {
        setErrorMessage("Une erreur est survenue, veuillez réessayer plus tard")
        setShowErrorMessage(false)
        setIsLoading(true)

        axios.post(`${apiUrl}/Elec/Site/${siteID}/4`,
            '',
            {
                headers: {
                    'Accept': 'text/plain',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                setShowConfirmationPopup(true)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message)
                }
                setShowErrorMessage(true)
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        getSiteData()
    }, [getSiteData])

    return (
        <>
            <SideBar />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">{isAdd ? "Ajouter un nouveau site à votre compte" : isConfirmed ? "Visualiser le site" : "Modifier le site"}</h3>
                    <Link to={fromAppelOffre ? `/appels-offre-elec` : `/elec-sites`}><span className="privatepages--header__return">{`< Retour à la liste des sites Électricité`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="addsites--step__titlecontainer">
                    <h4 className="addsites--step__title">Confirmation : <span className="addsites--step__titleright">Prix de la prestation WattValue pour votre site</span></h4>
                    <div className="sepa--buttonscontainer">
                        <Button
                            cta="Revenir à l'étape précédente"
                            link={`/${isAdd ? "add" : "modify"}-sites-3?type=elec&id=${siteID}${fromAppelOffre ? "&from=appels-offre" : ""}`}
                            additionnalClassName="global--button__goback" />
                        <Button
                            cta="Étape suivante"
                            additionnalClassName="addsites--step__button"
                            handleSubmit={() => handleSubmitStep4()}
                            disabled={isConfirmed}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                {showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror sepa--messageinfo">{errorMessage}</span>}
                {isConfirmed && <span className="homepage--popup__message homepage--popup__messageconfirmation sepa--messageinfo">Votre site est confirmé. Si vous souhaitez le modifier, merci de contacter l'équipe WattValue.</span>}
                <div className="table--tablecontainer">
                    <span className="addsites--intro">Le prix inclut la négociation de votre contrat de fourniture et le suivi de notre prestation de service sur la durée de ce contrat.</span>
                    <div className="addsites--prices__container">
                        <div className="addsites--prices__block">
                            <span className="addsites--prices__text">Forfait</span>
                            <span className="addsites--prices__price">{forfaitAppelOffre ? `${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(forfaitAppelOffre)} HT` : ""}</span>
                            <span className="addsites--prices__text">Prélevé le mois suivant la négociation du contrat</span>
                        </div>
                        <div className="addsites--prices__block">
                            <span className="addsites--prices__text">Mensualité</span>
                            <span className="addsites--prices__price">{forfaitAppelOffre ? `${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(remunerationMensuelle)} HT` : ""}</span>
                            <span className="addsites--prices__text">{nombreMoisAbonnement ? `À partir du mois de début de fourniture et aligné sur la durée du contrat, soit ${nombreMoisAbonnement} mois` : ""}</span>
                        </div>
                        <div className="addsites--prices__block">
                            <span className="addsites--prices__text">Électricité renouvelable</span>
                            <span className="addsites--prices__price">{partRenouvelable} %</span>
                            <span className="addsites--prices__text">{partRenouvelable > 0 ? `Par garantie d'origine (GO), au prix de 10,00 € HT/GO (une Garantie d'Origine correspond à une quantité d'électricité d'exactement 1 MWh soit 1000 kWh` : ""}</span>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmationPopup && <InformationPopup
                type={popupType}
                isLoading={isConfirmationLoading}
                closeInformationPopup={() => {
                    setShowErrorMessagePopup(false)
                    setShowConfirmationPopup(false)
                }}
                confirm={() => confirmAddSite()}
                errorMessage={errorMessagePopup}
                showErrorMessage={showErrorMessagePopup}
                isElec
            />
            }
        </>
    )
}

export default AddSitesStep4;