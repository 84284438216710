import React from 'react'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import Button from '../../Components/Button'

function DownloadXlsx(props) {

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(props.data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

        props.headRow?.length > 0 && props.headRow.forEach((element) => {
            for (const [key, value] of Object.entries(element)) {
                if (worksheet[key]) worksheet[key].v = value
            }
        })

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetcharset=UTF-8' })

        saveAs(blob, props.fileName)
    }

    return (
        <Button
            handleSubmit={exportToExcel}
            cta="Exporter"
            additionnalClassName={`table--tablecontainer__button ${props.additionnalClassName}`} />
    )
}

export default DownloadXlsx;