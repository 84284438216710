import Logo from '../../img/Logo-wattunity.png'
import Landscape from '../../img/loginpage-landscape.png'
import LoginForm from '../../Components/Form/LoginForm'
import { useState } from 'react'
import InformationPopup from '../../Components/InformationPopup'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { validPassword } from '../../Utils/Regex/validPassword'
import { validEmail } from '../../Utils/Regex/vallidEmail'
import { validPhone } from '../../Utils/Regex/validPhone'
import { validateCaptcha } from 'react-simple-captcha'
import { optionsFonction } from '../../Utils/DropDownOptions/optionsFonction'

const apiUrl = process.env.REACT_APP_API_URL

function Login() {
    const navigate = useNavigate()

    const [formStep, setFormStep] = useState("Login")
    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [civility, setCivility] = useState("Monsieur")
    const [lastname, setLastName] = useState("")
    const [firstname, setFirstName] = useState("")
    const [mobile, setMobile] = useState("")
    const [landline, setLandline] = useState("")
    const [fonction, setFonction] = useState("")
    const [company, setCompany] = useState("")

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")

    const [newsletterEmail, setNewsletterEmail] = useState("")
    const [name, setName] = useState("")
    const [showErrorMessagePopup, setShowErrorMessagePopup] = useState(false)
    const [showInformationPopup, setShowInformationPopup] = useState(false)
    const [isLoadingPopup, setIsLoadingPopup] = useState(false)
    const [popupType, setPopupType] = useState("newsletter-subscription")

    const [inputType, setInputType] = useState("password")

    const handleFormChange = (formStep) => {
        setShowErrorMessage(false)
        setCivility("Monsieur")
        setLastName("")
        setFirstName("")
        setMobile("")
        setLandline("")
        setFonction("")
        setCompany("")
        setEmail("")
        setPassword("")
        setPasswordConfirmation("")
        setNewsletterEmail("")
        setName("")
        setInputType("password")

        setFormStep(formStep)
    }

    const closeInformationPopup = () => {
        setShowInformationPopup(false)
    }

    const handleSubmitLogin = async () => {
        setIsLoading(true)
        setShowErrorMessage(false)
        const user = JSON.stringify({
            "email": `${email}`,
            "password": `${password}`,
        })
        try {
            await axios.post(
                `${apiUrl}/auth/login`,
                user,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
            setIsLoading(false)
            navigate('/dashboard')
        }
        catch (error) {
            if (error?.response?.data?.message) setErrorMessage(error.response.data.message)
            setIsLoading(false)
            setShowErrorMessage(true)
        }
    }

    const handlePasswordClick = (id, value) => {
        setShowErrorMessage(false)
        if (id === "password" || id === "passwordlogin") {
            setPassword(value)
        }
        if (id === "passwordconfirmation") {
            setPasswordConfirmation(value)
        }

        if (!validPassword.test(value) && value !== "" && id !== "passwordlogin") {
            setErrorMessage("Le mot de passe doit contenir au moins 8 caractères dont un spécial")
            setShowErrorMessage(true)
        }
    }

    const handleSubmitSignIn = async () => {
        setShowErrorMessage(false)
        if (!validEmail.test(email)) {
            setErrorMessage("Veuillez renseigner une adresse email valide")
            setShowErrorMessage(true)
        }
        else if ((!mobile || mobile === "") && (landline === "" || !landline)) {
            setErrorMessage("Veuillez renseigner au moins un des deux numéros de téléphone")
            setShowErrorMessage(true)
        }
        else if ((mobile && !validPhone.test(mobile) && mobile !== "") || (landline && !validPhone.test(landline) && landline !== "")) {
            setErrorMessage("Veuillez renseigner un numéro de téléphone valide à 10 chiffres")
            setShowErrorMessage(true)
        }
        else if (password !== passwordConfirmation) {
            setErrorMessage("Le mot de passe et la confirmation ne sont pas identiques")
            setShowErrorMessage(true)
        }
        else {
            setIsLoading(true)
            const user = JSON.stringify({
                "email": `${email}`,
                "raisonSociale": `${company}`,
                "civilite": `${civility}`,
                "nom": `${lastname}`,
                "prenom": `${firstname}`,
                "fonction": `${fonction}`,
                "telephone": `${landline}`,
                "mobile": `${mobile}`,
                "password": `${password}`,
            })
            try {
                await axios.post(
                    `${apiUrl}/client`,
                    user,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: true,
                    })
                setIsLoading(false)
                navigate('/pending-creation')
            }
            catch (error) {
                if (error?.response?.data?.message) setErrorMessage(error.response.data.message)
                setIsLoading(false)
                setShowErrorMessage(true)
            }
        }
    }

    const isSubmitSignInDisabled = () => {
        return (!lastname || lastname === "") || (!firstname || firstname === "") || (!fonction || fonction === "") || (!company || company === "") || (!email || email === "") || (!password || password === "") || (!passwordConfirmation || passwordConfirmation === "")
    }

    const handleSubmitPasswordStep1 = async () => {
        setIsLoading(true)
        setShowErrorMessage(false)
        try {
            await axios.post(
                `${apiUrl}/recuperation/send`,
                email,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
            setIsLoading(false)
            navigate('/confirmation-get-password')
        }
        catch (error) {
            if (error?.response?.data?.message) setErrorMessage(error.response.data.message)
            setIsLoading(false)
            setShowErrorMessage(true)
        }
    }

    const handleNewsletterSubscription = async () => {
        let userCaptcha = document.getElementById("captcha_input").value;

        setShowErrorMessage(false)
        setIsLoadingPopup(false)

        if (!validEmail.test(newsletterEmail)) {
            setErrorMessage("Veuillez renseigner une adresse email valide")
            setShowErrorMessagePopup(true)
        }
        else if (!validateCaptcha(userCaptcha, false)) {
            setErrorMessage("Les caractères tapés ne correspondent pas à l'image")
            setShowErrorMessagePopup(true)
        }
        else {
            setIsLoadingPopup(true)
            const user = JSON.stringify({
                "nom": `${name}`,
                "email": `${newsletterEmail}`,
            })
            try {
                await axios.post(
                    `${apiUrl}/newsletter/subscribe`,
                    user,
                    {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: true,
                    })
                setIsLoading(false)
                setPopupType("newsletter-confirmation")
            }
            catch (error) {
                if (error?.response?.data?.message) setErrorMessage(error.response.data.message)
                setIsLoadingPopup(false)
                setShowErrorMessagePopup(true)
            }
        }
    }

    const onClickEyeIcon = () => {
        inputType === "password" ? setInputType("text") : setInputType("password")
    }

    return (
        <div className="homepage--container homepage--publiccontainer">
            <div className="homepage--popup">
                <div className="homepage--popup__leftpart">
                    <div className="homepage--popup__logo">
                        <img src={Logo} alt="Logo WattValue" className="homepage--popup__logoimg" />
                        <span className="homepage--popup__baseline">Groupement d'achat & Services à l'énergie</span>
                    </div>
                    <LoginForm
                        formStep={formStep}
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        handlePasswordClick={handlePasswordClick}
                        passwordConfirmation={passwordConfirmation}
                        civility={civility}
                        setCivility={setCivility}
                        lastname={lastname}
                        setLastName={setLastName}
                        firstname={firstname}
                        setFirstName={setFirstName}
                        mobile={mobile}
                        setMobile={setMobile}
                        landline={landline}
                        setLandline={setLandline}
                        fonction={fonction}
                        optionsFonction={optionsFonction}
                        setFonction={setFonction}
                        company={company}
                        setCompany={setCompany}
                        isSubmitSignInDisabled={isSubmitSignInDisabled}
                        isLoginButtonDisabled={email === "" ||
                            password === "" ||
                            password === undefined ||
                            email === undefined}
                        handleSubmitLogin={handleSubmitLogin}
                        handleSubmitSignIn={handleSubmitSignIn}
                        handleSubmitPasswordStep1={handleSubmitPasswordStep1}
                        showErrorMessage={showErrorMessage}
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        handleFormChange={handleFormChange}
                        setShowErrorMessage={setShowErrorMessage}
                        onClickEyeIcon={onClickEyeIcon}
                        inputType={inputType} />
                </div>
                <img src={Landscape} alt="Landscape" className="homepage--popup__img" />
                {(formStep === "Login") && <span className="homepage--popup__newsletter" onClick={() => {
                    setNewsletterEmail("")
                    setName("")
                    setIsLoadingPopup(false)
                    setShowInformationPopup(true)
                    setPopupType("newsletter-subscription")
                }}>Abonnez-vous à la newsletter</span>}
            </div>
            {showInformationPopup && <InformationPopup
                type={popupType}
                showInformationPopup={showInformationPopup}
                closeInformationPopup={closeInformationPopup}
                email={newsletterEmail}
                setEmail={setNewsletterEmail}
                name={name}
                setName={setName}
                showErrorMessage={showErrorMessagePopup}
                errorMessage={errorMessage}
                handleNewsletterSubscription={handleNewsletterSubscription}
                setShowErrorMessage={setShowErrorMessagePopup}
                isLoading={isLoadingPopup}
            />}
        </div>
    )
}

export default Login;