import { useNavigate, useLocation } from 'react-router'
import { useSearchParams, Link } from 'react-router-dom'

import Logo from '../../img/Logo-wattunity.png'
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL

function Confirmation() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const guid = searchParams.get('guid')
    const clientID = searchParams.get('id')
    const location = useLocation()
    let Title = ""
    let Subtitle = ""

    const validateClient = async () => {
        const body = JSON.stringify({
            "clientID": parseInt(clientID),
            "guid": `${guid}`,
        })
        axios.post(`${apiUrl}/client/validate`,
            body,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                if (response && response.status === 200) {
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                }
                else {
                    navigate('/creation-confirmed')
                }
            })
            .catch(error => {
                navigate('/creation-confirmed')
            })
    }

    if (location.pathname === "/password-fail") {
        Title = "Modification de mot de passe"
        Subtitle = "Votre modification a déjà été effectuée ou votre demande à expiré ! Merci de renouveler votre demande"
    }
    else if (location.pathname === "/pending-creation") {
        Title = "Votre compte a été créé"
        Subtitle = "Un mail contenant un lien de validation vient de vous être envoyé pour confirmer la création de votre compte. Une fois votre compte validé, vous pourrez accéder aux étapes d'adhésion au groupement."
    }
    else if (location.pathname === "/confirmation-creation") {
        Title = "Votre compte a été validé"
        Subtitle = "Vous allez être redirigé vers votre tableau de bord dans quelques instants. Si la redirection ne fonctionne pas vous pouvez retourner à l'accueil et vous connecter avec vos identifiants."
        validateClient()
    }
    else if (location.pathname === "/creation-confirmed") {
        Title = "Votre compte a déjà été validé"
        Subtitle = "Vous pouvez retourner à l'accueil et vous connecter avec vos identifiants."
    }
    else if (location.pathname === "/password-changed") {
        Title = "Votre mot de passe a été modifié"
        Subtitle = "Vous pouvez retourner à l'accueil et vous connecter avec vos identifiants."
    }
    else {
        Title = "Modification de mot de passe"
        Subtitle = "Un mail vient de vous être envoyé ! Merci de vous rendre dans votre boite mail et de suivre les indications."
    }

    return (
        <div className="homepage--container homepage--publiccontainer">
            <div className="homepage--popup homepage--popup__passwordfail">
                <div className="homepage--popup__logo homepage--popup__logopasswordfail">
                    <img src={Logo} alt="Logo WattValue" className="homepage--popup__logoimg" />
                    <span className="homepage--popup__baseline">Groupement d'achat & Services à l'énergie</span>
                </div>
                <span className="homepage--popup__formtitle homepage--popup__formtitlepasswordfail">{Title}</span>
                <span className="homepage--popup__formsubtitle">{Subtitle}</span>
                <Link to={`/`}><span className="homepage--url homepage--url__createaccount"
                >Retourner à l'accueil</span></Link>
            </div>
        </div>
    )
}

export default Confirmation;