import SideBar from "../Components/SideBar/SideBar";
import Lottie from "lottie-react";
import NotFoundAnimation from '../img/notfound-animation.json'

function NotFound() {
    return (
        <>
            <SideBar />
            <div className="homepage--container notfound--container  privatepages--container">
                <Lottie
                    animationData={NotFoundAnimation}
                    loop
                    style={{ height: '250px', width: '250px' }} />
                <h3 className="notfound--title">Page 404</h3>
                <p className="notfound--paragraph">La page que vous recherchez n'est pas disponible ou n'existe pas.</p>
            </div>
        </>

    )
}

export default NotFound;