export function createDataContributors(contactID, contributor, email, creationDate, isValidated, canBeDeleted) {
    return { contactID, contributor, email, creationDate, isValidated, canBeDeleted }
}

export function createDataAccess(type, typeLabel, pdl, label, address, access) {
    return { type, typeLabel, pdl, label, address, access }
}

export function createDataMessages(messageID, sujet, message, dateCreation, dateModification, isRead) {
    return { messageID, sujet, message, dateCreation, dateModification, isRead }
}

export function createDataSepa(raisonSociale, sites, adresse, ribFichier, mandatFichier, dateCreation, statut, ribID, mandatID, sepaId) {
    return { raisonSociale, sites, adresse, ribFichier, mandatFichier, dateCreation, statut, ribID, mandatID, sepaId }
}

export function createDataGreenEnergy(annee, libelle, period, nombreGO, attestationID) {
    return { annee, libelle, period, nombreGO, attestationID }
}

export function createDataElecInvoices(statut, site, raisonSociale, adresse, prm, derniereFacture, anomalies, siteID) {
    return { statut, site, raisonSociale, adresse, prm, derniereFacture, anomalies, siteID }
}

export function createDataInvoiceSitesVerification(libelle, raisonSociale, prm, adresse, fournisseur, dateDebutFourniture, dateFinNouveauContrat, siteID) {
    return { libelle, raisonSociale, prm, adresse, fournisseur, dateDebutFourniture, dateFinNouveauContrat, siteID }
}

export function createDataSites(appelOffre, libelle, adresse, pce, prm, fournisseur, dateDebutFourniture, dateFinNouveauContrat, prixParMWh, consommation, facturation, prix, car, isConfirmed, etapeValidation, id, fromReengagement) {
    return { appelOffre, libelle, adresse, pce, prm, fournisseur, dateDebutFourniture, dateFinNouveauContrat, prixParMWh, consommation, facturation, prix, car, isConfirmed, etapeValidation, id,fromReengagement }
}

export function createDataExcelSitesGaz(id, appelOffre, libelle, adresse, pce, fournisseur, dateDebutFourniture, dateFinNouveauContrat, prixParMWh, prix, car) {
    return { id, appelOffre, libelle, adresse, pce, fournisseur, dateDebutFourniture, dateFinNouveauContrat, prixParMWh, prix, car }
}

export function createDataExcelSitesElec(id, appelOffre, libelle, adresse, prm, fournisseur, dateDebutFourniture, dateFinNouveauContrat, prixParMWh, consommation, facturation, prix, car) {
    return { id, appelOffre, libelle, adresse, prm, fournisseur, dateDebutFourniture, dateFinNouveauContrat, prixParMWh, consommation, facturation, prix, car }
}

export function createDataExcelWattvalueInvoices(type, periode, libelle, raisonSociale, numFac, fichier, dateSignature, montantTTC) {
    return { type, periode, libelle, raisonSociale, numFac, fichier, dateSignature, montantTTC }

}

export function createDataSitesConso(siteID, libelle, raisonSociale, adresse, prm, periode, releveType, souscriptionType) {
    return { siteID, libelle, raisonSociale, adresse, prm, periode, releveType, souscriptionType }
}

export function createDataTurpeSites(libelle, adresse, prm, totalEconomie, datePublication) {
    return { libelle, adresse, prm, totalEconomie, datePublication }
}