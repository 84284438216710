import { useEffect, useState, useCallback } from "react"
import Button from "../../../Components/Button"
import ContributorsTable from "./ContributorsTable"
import { SearchBar } from "../../../Components/SearchBar"
import SideBar from "../../../Components/SideBar/SideBar"
import axios from "axios"
import { useNavigate } from "react-router"
import { createDataContributors } from '../../../Utils/TableUtils/createData'
import InformationPopup from "../../../Components/InformationPopup"
import { removeAccents } from "../../../Utils/Regex/removeAccents"

const apiUrl = process.env.REACT_APP_API_URL

function Contributors() {
    const [contributors, setContributors] = useState([])
    const [filteredContributors, setFilteredContributors] = useState([])
    const [showSupprPopup, setShowSupprPopup] = useState(false)
    const [supprId, setSupprId] = useState("")
    const navigate = useNavigate()
    const [page, setPage] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const [isDataLoading, setIsDataLoading] = useState(false)

    const fetchContributors = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/client/contact`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                const contributors = response.data.map(element => createDataContributors(element.contactID, `${element.nom} ${element.prenom}`, element.email, element.dateCreation, element.isValidated, element.canBeDeleted))
                setContributors(contributors)
                setFilteredContributors(contributors)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
                if (error && error.response.status === 403) {
                    navigate('/')
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [navigate])

    useEffect(() => {
        fetchContributors()
    }, [fetchContributors])

    const filterContributors = (query) => {
        if (!query || query === "") {
            setFilteredContributors(contributors)
            setPage(0)
        }
        else {
            const keys = ['contactID', 'contributor', 'email', 'creationDate']
            const results = contributors.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredContributors(results)
            setPage(0)
        }
    }

    const handleSupprContributor = async () => {
        axios.delete(`${apiUrl}/client/contact/${supprId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                fetchContributors()
                setSupprId("")
                setShowSupprPopup(false)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const showSupprContributorPopup = (id) => {
        setSupprId(id)
        setShowSupprPopup(true)
    }

    const sendEmailValidation = (id, index) => {
        axios.post(`${apiUrl}/client/contact/${id}/validation/send`,
            '',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                const duplicateContributorsArray = [...contributors]
                duplicateContributorsArray[index]['emailSent'] = true

                const duplicateFilteredContributorsArray = [...filteredContributors]
                duplicateFilteredContributorsArray[index]['emailSent'] = true

                setContributors(duplicateContributorsArray)
                setFilteredContributors(duplicateFilteredContributorsArray)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    return (
        <>
            <SideBar
                openAccount
                active="Contributors" />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Mes collaborateurs</h3>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    <div className={`table--searchbarcontainer ${contributors.length > 0 ? "" : " gaz--searchbarcontainer__emptyarray"}`}>
                        {contributors.length > 0 &&
                            <SearchBar
                                id="table"
                                filterData={filterContributors}
                                setValue={setSearchValue}
                                value={searchValue}
                                additionnalFormClassName="searchbar--form table--form" />
                        }
                        <Button
                            cta="Ajouter un collaborateur"
                            link='/contributors-add'
                            additionnalClassName="table--tablecontainer__button" />
                    </div>
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {filteredContributors.length > 0 ?
                                <ContributorsTable
                                    rows={filteredContributors}
                                    showSupprContributorPopup={showSupprContributorPopup}
                                    sendEmailValidation={sendEmailValidation}
                                    type="contributors"
                                    setPage={setPage}
                                    page={page}
                                />
                                :
                                <span>Aucun collaborateur trouvé.</span>}
                        </>
                    }
                </div>
            </div>
            {showSupprPopup && <InformationPopup
                type="suppr"
                title="Êtes-vous sûr de vouloir supprimer ce collaborateur ?"
                handleSuppr={handleSupprContributor}
                closeInformationPopup={() => {
                    setSupprId("")
                    setShowSupprPopup(false)
                }}
            />
            }
        </>
    )
}

export default Contributors;