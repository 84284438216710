import SideBar from "../../../Components/SideBar/SideBar"
import { useState, useEffect, useCallback } from "react"
import { SearchBar } from "../../../Components/SearchBar"
import axios from "axios"
import { removeAccents } from "../../../Utils/Regex/removeAccents"
import { createDataTurpeSites } from "../../../Utils/TableUtils/createData"
import TurpeTable from "./TurpeTable"

const apiUrl = process.env.REACT_APP_API_URL

function Turpe() {
    const [isDataLoading, setIsDataLoading] = useState(false)

    const [sites, setSites] = useState([])
    const [filteredSites, setFilteredSites] = useState([])
    const [page, setPage] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const getSitesData = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/Elec/Turpe/Optimisation`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                const sites = response.data?.map(element => createDataTurpeSites(element.libelle, `${element.adresse ? element.adresse : ""} ${element.codePostal ? element.codePostal : ""} ${element.ville ? element.ville : ""}`, element.prm, element.totalEconomie, element.datePublication))
                setSites(sites)
                setFilteredSites(sites)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [])

    useEffect(() => {
        getSitesData()
        setPage(0)
    }, [getSitesData, setPage])

    const filterSites = (query) => {
        if (!query || query === "") {
            setFilteredSites(sites)
            setPage(0)
        }
        else {
            const keys = ['libelle', 'adresse', 'prm']
            const results = sites.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredSites(results)
            setPage(0)
        }
    }

    return (
        <>
            <SideBar
                openElec
                active="TURPE"
            />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Optimisation TURPE</h3>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {sites.length > 1 &&
                                <div className={`table--searchbarcontainer gaz--searchbarcontainer${sites.length > 1 ? "" : " gaz--searchbarcontainer__emptyarray"}`}>
                                    <SearchBar
                                        id="table"
                                        filterData={filterSites}
                                        setValue={setSearchValue}
                                        value={searchValue}
                                        additionnalFormClassName="searchbar--siteslist table--form" />
                                </div>
                            }
                            {filteredSites.length > 0 ?
                                <>
                                    <p className="turpe--explanation">Vous pouvez réduire vos frais liés au réseau électrique grâce à l'optimisation du TURPE. Adaptez ce tarif à vos besoins réels en fonction de la puissance souscrite et de votre consommation.
                                        <br /><br />
                                        Dans le tableau ci-dessous, retrouvez tous vos sites éligibles à une optimisation TURPE.
                                        Cliquez sur "Visualiser" pour voir notre proposition.</p>
                                    <TurpeTable
                                        rows={filteredSites}
                                        page={page}
                                        setPage={setPage}
                                    />
                                </>
                                :
                                <span>Aucun site trouvé</span>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Turpe;