import axios from 'axios'
import { useState, useEffect } from "react"
import { useNavigate } from 'react-router'

const apiUrl = process.env.REACT_APP_API_URL

async function checkAuth() {
    try {
        const request = await axios.get(
            `${apiUrl}/auth/check`,
            {
                withCredentials: true
            })
        return request
    }
    catch (error) {
        console.log(error)
    }
};

export default function useAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        checkAuth()
            .then((response) => {
                if (response && response.status) {
                    setIsAuthenticated(response.status === 200)
                }
            })
            .catch(() => {
                navigate('/')
            })
            .finally(() => setLoading(false))
    }, [navigate])
    return [isAuthenticated, loading]
}
