import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import { getComparator } from '../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../Utils/SortingUtils/stableSort'
import EnhancedTableHead from '../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../Components/TableComponents/StyledTableCell'
import { headCellsContracts } from '../../Utils/TableUtils/headCells'
import Open from '../../img/icons/suppr.svg'
import { Tooltip } from '@mui/material'

function ContractsTable(props) {
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('appelOffre')
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [page, setPage] = React.useState(0)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, props.rows],
    )

    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        headCells={headCellsContracts}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            return (
                                <TableRow key={`contract-detail-${index}`}>
                                    <StyledTableCell>{row.statut && row.statut}</StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title={row.fournisseur} enterTouchDelay={0}>
                                            <span>{row.fournisseur && row.fournisseur}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title={row.contratFichier} enterTouchDelay={0}>
                                            <span>{row.contratFichier && <div className="mandates--file" onClick={() => props.getFile(row.contratID, row.contratGuid, row.contratFichier)}>
                                                <span className="table--link mandates--link">{`${row.contratFichier}`}
                                                </span> <img src={Open} alt="Ouvrir le fichier" />
                                            </div>}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Tooltip title={row.cgvFichier} enterTouchDelay={0}>
                                            <span>{row.cgvFichier && <div className="mandates--file" onClick={() => props.getFile(row.cgvID, row.cgvGuid, row.cgvFichier)}>
                                                <span className="table--link mandates--link">{`${row.cgvFichier}`}
                                                </span> <img src={Open} alt="Ouvrir le fichier" />
                                            </div>}</span>
                                        </Tooltip>
                                    </StyledTableCell>
                                    <StyledTableCell>{(row.dateSignatureWattunity && row.dateSignatureWattunity !== "0001-01-01T00:00:00") && new Date(row.dateSignatureWattunity).toLocaleDateString()}</StyledTableCell>
                                    <StyledTableCell>{(row.dateDebutFourniture && row.dateDebutFourniture !== "0001-01-01T00:00:00") && new Date(row.dateDebutFourniture).toLocaleDateString()}</StyledTableCell>
                                    <StyledTableCell>{(row.dateFinNouveauContrat && row.dateFinNouveauContrat !== "0001-01-01T00:00:00") && new Date(row.dateFinNouveauContrat).toLocaleDateString()}</StyledTableCell>
                                </TableRow>
                            )
                        })}
                        {emptyRows > 0 && (
                            <TableRow>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Lignes par page"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                sx={{
                    '.MuiTablePagination-toolbar': {
                        color: "#18181b !important",
                    },
                    '.MuiTablePagination-selectLabel': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-select': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-displayedRows': {
                        fontFamily: "Plus Jakarta Sans, sans-serif !important",
                        color: "#18181b !important",
                    },
                }}
            />
        </Box >
    )

}

export default ContractsTable;