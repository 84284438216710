import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';
import LoadingPage from '../Pages/LoadingPage';

function PrivateRoute({ children }) {
    const [isAuthenticated, loading] = useAuth()

    if (loading) {
        return <LoadingPage />
    }

    return isAuthenticated ? <> {children} </> : <Navigate to={"/"} />;
}

export default PrivateRoute;