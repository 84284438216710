export const monthsOptions = [
    { moisLabel: "Janv.", moisNombre: 1, mois: "Janvier" },
    { moisLabel: "Févr.", moisNombre: 2, mois: "Fevrier" },
    { moisLabel: "Mars", moisNombre: 3, mois: "Mars" },
    { moisLabel: "Avr.", moisNombre: 4, mois: "Avril" },
    { moisLabel: "Mai", moisNombre: 5, mois: "Mai" },
    { moisLabel: "Juin", moisNombre: 6, mois: "Juin" },
    { moisLabel: "Juill.", moisNombre: 7, mois: "Juillet" },
    { moisLabel: "Août", moisNombre: 8, mois: "Aout" },
    { moisLabel: "Sept.", moisNombre: 9, mois: "Septembre" },
    { moisLabel: "Oct.", moisNombre: 10, mois: "Octobre" },
    { moisLabel: "Nov.", moisNombre: 11, mois: "Novembre" },
    { moisLabel: "Déc.", moisNombre: 12, mois: "Decembre" }
]