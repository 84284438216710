import { useCallback, useEffect, useState } from "react"
import { useSearchParams, Link } from 'react-router-dom'

import SideBar from "../../../Components/SideBar/SideBar"
import axios from "axios"
import FormInput from "../../../Components/Form/FormInput"
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Button from "../../../Components/Button"
import InvoiceTable from "./InvoiceTable"

const apiUrl = process.env.REACT_APP_API_URL

function UploadInvoice() {
    const [searchParams] = useSearchParams()

    const [siteData, setSiteData] = useState({})
    const [invoices, setInvoices] = useState([])
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFileName, setSelectedFileName] = useState("")
    const [showConfirmationMessageFile, setShowConfirmationMessageFile] = useState(false)
    const [showErrorMessageFile, setShowErrorMessageFile] = useState(false)
    const [errorMessageFile, setErrorMessageFile] = useState("")

    const [isDataLoading, setIsDataLoading] = useState(false)

    const fetchInvoiceData = useCallback((noLoading) => {
        !noLoading && setIsDataLoading(true)
        axios.get(`${apiUrl}/Facture/Verification/Envoi/${searchParams.get('id')}`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                setSiteData(response.data.site)
                setInvoices(response.data.factures)
            })
            .catch(error => {
                if (error && error.response.status && error.response.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [searchParams])

    useEffect(() => {
        fetchInvoiceData()
    }, [fetchInvoiceData])

    const uploadFile = () => {
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('fileName', selectedFile.name)

        axios.post(`${apiUrl}/Facture/Verification/Envoi/${searchParams.get('id')}/document`,
            formData,
            {
                headers: {
                    'accept': '*/*',
                    'content-type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((response) => {
                setShowConfirmationMessageFile(true)
                setSelectedFile(null)
                setSelectedFileName("")
                fetchInvoiceData(true)
            })
            .catch(error => {
                if (error?.response?.data?.message) {
                    setErrorMessageFile(error.response.data.message)
                    setShowErrorMessageFile(true)
                }

                if (error && error.response && error.response.status === 401) {
                    window.location.reload()
                }
            })
    }

    const handleSuppr = (id) => {
        axios.delete(`${apiUrl}/Facture/Verification/Envoi/Document/${id}`,
            {
                headers: {
                    'Accept': '*/*',
                },
                withCredentials: true,
            })
            .then(() => fetchInvoiceData(true))
            .catch(error => {
                if (error && error.response.status && error.response.status === 401) {
                    window.location.reload()
                }
            })
    }

    const handleDownload = (documentID, fileName) => {
        axios.get(`${apiUrl}/Facture/Verification/Envoi/Document/${documentID}`,
            {
                withCredentials: true,
                ContentType: 'application/json',
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    return (
        <>
            <SideBar
                openElec
                active="Verifier mes factures" />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">Envoi de mes factures à WattValue</h3>
                    <Link to='/invoice-site-upload'><span className="privatepages--header__return">{`< Retour à la liste des sites`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            <h4 className="elec--title">Facture en vérification{(siteData.raisonSociale) ? ` pour ${siteData.raisonSociale}` : ""}{siteData.fournisseur ? ` fourni par ${siteData.fournisseur}` : ""}{siteData.ville ? ` à ${siteData.ville}` : ""}</h4>
                            <span className="elec--paragraph">{siteData.prm && `PRM : ${siteData.prm}`}</span>
                            <h5 className="elec--upload__subtitle">Ajouter une facture</h5>
                            <span className="elec--upload__info">Factures{siteData.fournisseur ? ` du fournisseur ${siteData.fournisseur}` : ""}{(siteData.dateDebutFourniture && siteData.dateFinNouveauContrat) ? ` en contrat du ${new Date(siteData.dateDebutFourniture).toLocaleDateString()} au ${new Date(siteData.dateFinNouveauContrat).toLocaleDateString()}` : ""} pour le site mentionné ci-dessus.</span>
                            <span className="elec--upload__info">Factures complètes (toutes les pages dans un seul document PDF ou word).</span>
                            <div className="sepa--form__inputcontainer">
                                <FormInput
                                    type="file"
                                    id="invoice-file"
                                    fileClassName="sepa--form__upload"
                                    fileName={selectedFileName ? selectedFileName : ""}
                                    disabled={(selectedFileName && selectedFileName !== "")}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                    onChangeInput={file => {
                                        if (file) {
                                            setShowErrorMessageFile(false)
                                            setShowConfirmationMessageFile(false)
                                            setSelectedFile(file)
                                            setSelectedFileName(file.name)
                                        }
                                    }}
                                    noIcon
                                />
                                <HighlightOffIcon onClick={() => {
                                    setSelectedFile(null)
                                    setSelectedFileName("")
                                    setShowErrorMessageFile(false)
                                    setShowConfirmationMessageFile(false)
                                }}
                                    sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />
                            </div>
                            <Button
                                additionnalClassName="elec--upload__button"
                                cta="Ajouter"
                                handleSubmit={() => uploadFile()}
                                disabled={!selectedFile} />
                            {showConfirmationMessageFile && <span className="homepage--popup__message homepage--popup__messageconfirmation">Le fichier a bien été envoyé</span>}
                            {showErrorMessageFile && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageFile}</span>}
                            <h5 className="elec--upload__subtitle">Liste des factures</h5>
                            {invoices.length > 0
                                ?
                                <InvoiceTable
                                    rows={invoices}
                                    handleSuppr={handleSuppr}
                                    handleDownload={handleDownload}
                                />
                                :
                                <span>Pas encore de facture chargée</span>}
                        </>
                    }
                </div>
            </div >
        </>
    )
}

export default UploadInvoice;