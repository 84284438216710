function LoadingPage() {
    return (
        <div className="homepage--container  homepage--publiccontainer">
            <div className="pageloader--turbine">
                <div className="pageloader--pilot">
                    <div className="pageloader--prop-container">
                        <div className="pageloader--prop"></div>
                        <div className="pageloader--prop"></div>
                        <div className="pageloader--prop"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingPage;