import { useEffect, useState, useCallback } from 'react'
import { useSearchParams, Link } from 'react-router-dom'

import SideBar from '../../../Components/SideBar/SideBar'
import axios from 'axios'
import FormInput from '../../../Components/Form/FormInput'
import Button from '../../../Components/Button'

const apiUrl = process.env.REACT_APP_API_URL

function Chat() {
    const [searchParams] = useSearchParams()
    const [messages, setMessages] = useState([])
    const [subject, setSubject] = useState("")
    const chatID = searchParams.get('id')

    const [isLoading, setIsLoading] = useState(false)
    const [newMessage, setNewMessage] = useState("")

    const handleSubmit = () => {
        const messageJson = JSON.stringify({
            "sujet": `${subject}`,
            "message": `${newMessage}`,
        })
        setIsLoading(true)
        axios.post(`${apiUrl}/message/send/${chatID}`,
            messageJson,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                setNewMessage("")
                getMessages()
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsLoading(false))
    }


    const getMessages = useCallback(() => {
        axios.get(`${apiUrl}/Message/${chatID}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                if (response && response.data) {
                    response.data[0] && setSubject(response.data[0].sujet)
                    setMessages(response.data)
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [chatID])

    const formatMessage = (value) => {
        setNewMessage(value)
    }

    useEffect(() => {
        getMessages()
    }, [getMessages])

    return (
        <>
            <SideBar />
            <div className="homepage--container privatepages--container messages--container">
                <div className="privatepages--header__container">
                    <div className="messages--chattitle">
                        <h3 className="privatepages--title privatepages--text">Discussion</h3>
                        <span className="messages--chatsubtitle">{subject}</span>
                    </div>
                    <Link to='/messages'><span className="privatepages--header__return">{`< Retour à la liste des messages`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="privatepages--columnscontainer messages--chat__columnscontainer">
                    <div className="messages--chatcontainer">
                        <div>
                            {messages.length > 0 ?
                                messages.map((message, index) => {
                                    if (message.isWattValue) {
                                        return (
                                            <div className="messages--chat__line" key={`message-line-${index}`}>
                                                <span className="messages--chatdate">{new Date(message.dateCreation).toLocaleString()}</span>
                                                <div className="messages--chatcontent messages--chat__wattvalue">{message.message}</div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="messages--chat__line messages--chat__lineclient" key={`message-line-${index}`}>
                                                <span className="messages--chatdate">{new Date(message.dateCreation).toLocaleString()}</span>
                                                <div className="messages--chatcontent messages--chat__client">{message.message}</div>
                                            </div>
                                        )
                                    }

                                })
                                :
                                <span>Aucun message trouvé.</span>}
                        </div>
                        <form className="messages--chat__form">
                            <FormInput
                                type="textarea"
                                label="Message"
                                id="message"
                                onChangeInput={formatMessage}
                                placeholder="Votre message"
                                value={newMessage}
                                additionnalClassName="homepage--popup__inputmarginbottom14 messages--input__textarea"
                            />
                            <Button
                                cta="Envoyer"
                                handleSubmit={handleSubmit}
                                disabled={newMessage === "" || !newMessage}
                                isLoading={isLoading}
                                additionnalClassName="homepage--popup__inputmarginbottom33"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Chat;