import { useCallback, useEffect, useState } from "react"
import { Link } from 'react-router-dom'

import SideBar from "../../../Components/SideBar/SideBar"
import axios from "axios"
import { SearchBar } from "../../../Components/SearchBar"
import SitesUploadTable from "./SitesUploadTable"
import { createDataInvoiceSitesVerification } from "../../../Utils/TableUtils/createData"
import { removeAccents } from "../../../Utils/Regex/removeAccents"

const apiUrl = process.env.REACT_APP_API_URL

function InvoiceSiteUpload() {
    const [sitesData, setSitesData] = useState([])
    const [filteredSitesData, setFilteredSitesData] = useState([])
    const [page, setPage] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const [isDataLoading, setIsDataLoading] = useState(false)

    const fetchInvoiceData = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/Facture/Verification/Envoi/`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                const sitesData = response.data.map((element) => createDataInvoiceSitesVerification(element.libelle, element.raisonSociale, element.prm, `${element.adresse1 ? element.adresse1 : ""} ${element.codePostal ? element.codePostal : ""} ${element.ville ? element.ville : ""}`, element.fournisseur, element.dateDebutFourniture, element.dateFinNouveauContrat, element.siteID))
                setSitesData(sitesData)
                setFilteredSitesData(sitesData)
            })
            .catch(error => {
                if (error && error.response.status && error.response.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [])

    useEffect(() => {
        fetchInvoiceData()
    }, [fetchInvoiceData])

    const filterSites = (query) => {
        if (!query || query === "") {
            setFilteredSitesData(sitesData)
            setPage(0)
        }
        else {
            const keys = ['libelle', 'raisonSociale', 'prm', 'adresse', 'fournisseur', 'dateDebutFourniture', 'dateFinNouveauContrat']
            const results = sitesData.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredSitesData(results)
            setPage(0)
        }
    }

    return (
        <>
            <SideBar
                openElec
                active="Verifier mes factures" />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">Envoi de mes factures à WattValue</h3>
                    <Link to='/invoice-verification'><span className="privatepages--header__return">{`< Retour à la liste des sites`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    {sitesData.length > 0 && <div className="table--searchbarcontainer">
                        <SearchBar
                            id="invoicestable"
                            filterData={filterSites}
                            setValue={setSearchValue}
                            value={searchValue}
                            additionnalFormClassName="searchbar--form table--form"
                        />
                    </div>}
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {filteredSitesData.length > 0 ?
                                <SitesUploadTable
                                    rows={filteredSitesData}
                                    page={page}
                                    setPage={setPage}
                                />
                                :
                                <span>Aucun site trouvé.</span>}
                        </>
                    }
                </div>
            </div >
        </>
    )
}

export default InvoiceSiteUpload;