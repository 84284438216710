import { useEffect, useState, useCallback } from "react"
import SideBar from "../../../Components/SideBar/SideBar"
import Button from "../../../Components/Button"
import FormInput from "../../../Components/Form/FormInput"
import { useSearchParams } from 'react-router-dom'
import axios from "axios"
import { optionsFonction } from "../../../Utils/DropDownOptions/optionsFonction"
import { validPhone } from "../../../Utils/Regex/validPhone"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { validEmail } from "../../../Utils/Regex/vallidEmail"
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InformationPopup from "../../../Components/InformationPopup"

const apiUrl = process.env.REACT_APP_API_URL

function UpdateSepa() {
    const [searchParams] = useSearchParams()
    const sepaId = searchParams.get('sepaid')
    const [libelle, setLibelle] = useState("")

    const [company, setCompany] = useState("")
    const [siren, setSiren] = useState("")
    const [villeRCS, setVilleRCS] = useState("")
    const [adresse, setAdresse] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [ville, setVille] = useState("")

    const [lastname, setLastName] = useState("")
    const [firstname, setFirstName] = useState("")
    const [email, setEmail] = useState("")
    const [fonction, setFonction] = useState("")
    const [mobile, setMobile] = useState("")

    const [selectedRib, setSelectedRib] = useState(null)
    const [selectedRibName, setSelectedRibName] = useState("")
    const [showConfirmationMessageRib, setShowConfirmationMessageRib] = useState(false)
    const [showErrorMessageRib, setShowErrorMessageRib] = useState(false)
    const [errorMessageRib, setErrorMessageRib] = useState("")
    const [bic, setBIC] = useState("")
    const [iban, setIBAN] = useState("")

    const [recipientsArray, setRecipientsArray] = useState([])
    const [recipientEmail, setRecipientEmail] = useState("")
    const [errorMessageRecipient, setErrorMessageRecipient] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessageRecipient, setShowErrorMessageRecipient] = useState(false)
    const [showConfirmationMessageRecipient, setShowConfirmationMessageRecipient] = useState(false)
    const [confirmationMessageRecipient, setConfirmationMessageRecipient] = useState("")

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [showConfirmationMessage, setShowConfirmationMessage] = useState(false)

    const [isConfirmed, setIsConfirmed] = useState(false)
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [isConfirmationLoading, setIsConfirmationLoading] = useState(false)
    const [showConfirmationErrorMessage, setShowConfirmationErrorMessage] = useState(false)
    const [popupType, setPopupType] = useState("confirmation-sepa")

    const fetchSepa = useCallback((isImport) => {
        axios.get(`${apiUrl}/mandat/sepa/${sepaId}${isImport ? "/Import" : ""}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                response.data.libelle && setLibelle(response.data.libelle)
                response.data.isConfirmed && setIsConfirmed(response.data.isConfirmed)
                response.data.isConfirmed && setShowConfirmationMessage(true)

                response.data.societe && setCompany(response.data.societe)
                response.data.siren && setSiren(response.data.siren)
                response.data.rcs && setVilleRCS(response.data.rcs)
                response.data.villeRCS && setVilleRCS(response.data.villeRCS)
                response.data.adresse && setAdresse(response.data.adresse)
                response.data.codePostal && setCodePostal(response.data.codePostal)
                response.data.ville && setVille(response.data.ville)

                if (response.data.contact) {
                    response.data.contact.prenom && setFirstName(response.data.contact.prenom)
                    response.data.contact.nom && setLastName(response.data.contact.nom)
                    response.data.contact.email && setEmail(response.data.contact.email)
                    response.data.contact.fonction && setFonction(response.data.contact.fonction)
                    response.data.contact.mobile && setMobile(response.data.contact.mobile)
                }

                (response.data.destinataires && response.data.destinataires.length > 0) && setRecipientsArray(response.data.destinataires)

                response.data.bic && setBIC(response.data.bic)
                response.data.iban && setIBAN(response.data.iban)
                response.data.ribFichier && setSelectedRibName(response.data.ribFichier)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [sepaId])

    useEffect(() => {
        fetchSepa()
    }, [fetchSepa])

    const handleSaveSepa = () => {
        if (mobile && !validPhone.test(mobile) && mobile !== "") {
            setErrorMessage("Veuillez renseigner un numéro de téléphone valide à 10 chiffres")
            setShowErrorMessage(true)
        }
        else {
            setIsLoading(true)
            handleFileSubmit()
            const formData = JSON.stringify({
                "societe": `${company}`,
                "siren": `${siren}`,
                "villeRCS": `${villeRCS}`,
                "adresse": `${adresse}`,
                "codePostal": `${codePostal}`,
                "ville": `${ville}`,
                "bic": `${bic}`,
                "iban": `${iban}`,
                "contact": {
                    "email": `${email}`,
                    "nom": `${lastname}`,
                    "prenom": `${firstname}`,
                    "fonction": `${fonction}`,
                    "mobile": `${mobile}`,
                },
            })
            axios.post(`${apiUrl}/mandat/sepa/${sepaId}`,
                formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then(() => {
                    setShowConfirmationPopup(true)
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
                .finally(() => setIsLoading(false))
        }
    }

    const handleFileSubmit = async () => {
        if (selectedRib) {
            const formData = new FormData()
            formData.append('file', selectedRib)
            formData.append('fileName', selectedRib.name)

            axios.post(`${apiUrl}/mandat/sepa/${sepaId}/rib`,
                formData,
                {
                    headers: {
                        'accept': '*/*',
                        'content-type': 'multipart/form-data',
                    },
                    withCredentials: true,
                })
                .then()
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
        }
    }

    const addRecipient = () => {
        setShowErrorMessageRecipient(false)
        setShowConfirmationMessageRecipient(false)
        if (!validEmail.test(recipientEmail)) {
            setErrorMessageRecipient("Veuillez renseigner une adresse email valide")
            setShowErrorMessageRecipient(true)
        }
        else {
            axios.post(`${apiUrl}/mandat/sepa/${sepaId}/mail`,
                recipientEmail,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then(() => {
                    setConfirmationMessageRecipient("L'adresse a bien été enregistrée")
                    setShowConfirmationMessageRecipient(true)
                    setRecipientEmail("")
                    fetchRecipients()
                })
                .catch(error => {
                    if (error?.response?.data?.message) setErrorMessageRecipient(error.response.data.message)
                    setShowErrorMessageRecipient(true)
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
        }
    }

    const fetchRecipients = () => {
        axios.get(`${apiUrl}/mandat/sepa/${sepaId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                (response.data.destinataires) && setRecipientsArray(response.data.destinataires)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const deleteRecipient = (deleteEmail) => {
        setShowErrorMessageRecipient(false)
        setShowConfirmationMessageRecipient(false)
        axios.delete(`${apiUrl}/mandat/sepa/${sepaId}/mail`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: deleteEmail,
                withCredentials: true,
            })
            .then((response) => {
                setConfirmationMessageRecipient("L'adresse a bien été supprimée")
                setShowConfirmationMessageRecipient(true)
                fetchRecipients()
            })
            .catch(error => {
                if (error?.response?.data?.message) setErrorMessageRecipient(error.response.data.message)
                setShowErrorMessageRecipient(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const deleteRib = () => {
        axios.delete(`${apiUrl}/mandat/sepa/${sepaId}/rib`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                setShowConfirmationMessageRib(true)
            })
            .catch(error => {
                if (error?.response?.data?.message) setErrorMessageRib(error.response.data.message)
                setShowErrorMessageRib(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const clearRib = () => {
        deleteRib()
        setSelectedRib(null)
        setSelectedRibName("")
    }

    const confirmSepa = () => {
        setIsConfirmationLoading(true)
        setShowConfirmationErrorMessage(false)
        axios.post(`${apiUrl}/mandat/sepa/${sepaId}/confirm`,
            "",
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                setPopupType("sepa-confirmed")
                fetchSepa()
            })
            .catch(error => {
                setShowConfirmationErrorMessage(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsConfirmationLoading(false))
    }

    return (
        <>
            <SideBar
                active="Sepa"
                openAccount
            />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Mes mandats SEPA</h3>
                <div className="privatepages--separator"></div>
                <div className="sepa--header__container">
                    <h4 className="sepa--header__clientname">{libelle ? `Client - ${libelle}` : "Compléter votre mandat"}</h4>
                    <div className="sepa--buttonscontainer">
                        <Button
                            cta="Revenir aux mandats"
                            link='/sepa'
                            additionnalClassName="global--button__goback" />
                        <Button
                            cta="Enregistrer"
                            handleSubmit={() => handleSaveSepa()}
                            isLoading={isLoading}
                            disabled={(company === "" || !company || siren === "" || !siren || villeRCS === "" || !villeRCS || adresse === "" || !adresse || codePostal === "" || !codePostal || ville === "" || !ville ||
                                lastname === "" || !lastname || firstname === "" || !firstname || email === "" || !email || fonction === "" || !fonction || mobile === "" || !mobile) ||
                                bic === "" || !bic || iban === "" || !iban || !selectedRibName || selectedRibName === "" ||
                                isConfirmed
                            }
                        />
                    </div>
                </div>
                {showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror sepa--messageinfo">{errorMessage}</span>}
                {showConfirmationMessage && <span className="homepage--popup__message homepage--popup__messageconfirmation sepa--messageinfo">Votre mandat SEPA est en attente de signature</span>}
                <div className="privatepages--columnscontainer">
                    <div className="privatepages--twocolumns">
                        <div>
                            <span className="privatepages--asterisk privatepages--text">*champ obligatoire</span>
                            <form className="privatepages--form__block  sepa--form__block">
                                <h4 className="privatepages--form__title">Description du site*</h4>
                                <span className="sepa--form__import" onClick={() => fetchSepa(true)}>Importer les données du site</span>
                                <FormInput
                                    label="Société*"
                                    type="text"
                                    id="societe"
                                    onChangeInput={value => { setCompany(value) }}
                                    placeholder="WattValue"
                                    value={company}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                                <FormInput
                                    label="SIREN*"
                                    type="text"
                                    id="siren"
                                    onChangeInput={value => { setSiren(value) }}
                                    placeholder="553 279 879"
                                    value={siren}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                                <FormInput
                                    label="Ville RCS*"
                                    type="text"
                                    id="villercs"
                                    onChangeInput={value => { setVilleRCS(value) }}
                                    placeholder="Bordeaux"
                                    value={villeRCS}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                                <FormInput
                                    label="Adresse*"
                                    type="text"
                                    id="adresse"
                                    onChangeInput={value => { setAdresse(value) }}
                                    placeholder="2 rue du village"
                                    value={adresse}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                                <FormInput
                                    label="Code postal*"
                                    type="text"
                                    id="codepostal"
                                    onChangeInput={value => { setCodePostal(value) }}
                                    placeholder="31300"
                                    value={codePostal}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                                <FormInput
                                    label="Ville*"
                                    type="text"
                                    id="company"
                                    onChangeInput={value => { setVille(value) }}
                                    placeholder="Bordeaux"
                                    value={ville}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                             
                            </form>
                        </div>
                    </div>
                    <div className="privatepages--twocolumns sepa--middlecolumn">
                        <form className="privatepages--form__block sepa--form__block">
                            <h4 className="privatepages--form__title">Personne habilitée à signer le SEPA*</h4>
                            <FormInput
                                label="Nom*"
                                type="text"
                                id="lastname"
                                hmlFor="lastname"
                                onChangeInput={value => { setLastName(value) }}
                                placeholder="Emilie"
                                value={lastname}
                                maxLength={255}
                                disabled={isConfirmed}
                            />
                            <FormInput
                                label="Prénom*"
                                type="text"
                                id="firstname"
                                onChangeInput={value => { setFirstName(value) }}
                                placeholder="Sardou"
                                value={firstname}
                                maxLength={255}
                                disabled={isConfirmed}
                            />
                            <FormInput
                                label="Email*"
                                type="email"
                                id="email"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setEmail(value)
                                }}
                                placeholder="emilie.sardou@gmail.com"
                                value={email}
                                maxLength={255}
                                disabled={isConfirmed}
                            />
                            <FormInput
                                inputLabel="Fonction*"
                                type="dropdown"
                                id="fonction"
                                onChangeInput={e => { setFonction(e.value) }}
                                placeholder="Choisir..."
                                value={fonction}
                                isSearchable={false}
                                options={optionsFonction}
                            />
                            <FormInput
                                label="Téléphone mobile"
                                type="number"
                                id="mobile"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setMobile(value)
                                }}
                                placeholder="06 01 02 03 04"
                                value={mobile}
                                maxLength={50}
                                disabled={isConfirmed}
                            />
                        </form>
                        <form className="privatepages--form__block sepa--form__block sepa--form__blockbottom">
                            <h4 className="privatepages--form__title">Autre{recipientsArray.length > 1 ? "s" : ""} destinaire{recipientsArray.length > 1 ? "s" : ""} des factures</h4>
                            {recipientsArray.length > 0 ?
                                recipientsArray.map((recipient, index) => {
                                    return (
                                        <div key={`recipient-email-${index}`} className="sepa--recipients__container">
                                            <span className="sepa--recipients">{recipient}</span>
                                            {!isConfirmed && <HighlightOffIcon onClick={() => deleteRecipient(recipient)} sx={{ fontSize: "16px", marginLeft: "5px", cursor: "pointer" }} />}
                                        </div>
                                    )
                                })
                                :
                                <span className="sepa--emptyrecipients">Aucun destinaire trouvé</span>}
                            {!isConfirmed && <>
                                <div className="privatepages--separator"></div>
                                <FormInput
                                    label="Adresse email"
                                    type="text"
                                    id="email-recipient"
                                    onChangeInput={value => {
                                        setShowErrorMessageRecipient(false)
                                        setShowConfirmationMessageRecipient(false)
                                        setRecipientEmail(value)
                                    }}
                                    placeholder="seamus.schmidt@gmail.com"
                                    value={recipientEmail}
                                    maxLength={255}
                                    additionnalClassName="sepa--emailinput"
                                    disabled={isConfirmed}
                                />
                                <AddCircleOutlineIcon onClick={addRecipient} sx={{ color: "#48AB4D", position: "relative", bottom: "-5px", right: "-10px", cursor: "pointer" }} />
                            </>}
                            {showConfirmationMessageRecipient && <span className="homepage--popup__message homepage--popup__messageconfirmation">{confirmationMessageRecipient}</span>}
                            {showErrorMessageRecipient && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageRecipient}</span>}
                        </form>
                    </div>
                    <div className="privatepages--twocolumns sepa--thirdcolumn">
                        <form className="privatepages--form__block  sepa--form__block">
                            <h4 className="privatepages--form__title">Ajouter un RIB*</h4>
                            <div className="sepa--form__inputcontainer">
                                <FormInput
                                    type="file"
                                    id="rib-file"
                                    fileName={selectedRibName ? selectedRibName : ""}
                                    disabled={(selectedRibName && selectedRibName !== "") || (isConfirmed)}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                    onChangeInput={file => {
                                        if (file) {
                                            setShowErrorMessageRib(false)
                                            setShowConfirmationMessageRib(false)
                                            setSelectedRib(file)
                                            setSelectedRibName(file.name)
                                        }
                                    }}
                                />
                                {!isConfirmed && <HighlightOffIcon onClick={() => clearRib()} sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                            </div>
                            {showConfirmationMessageRib && <span className="homepage--popup__message homepage--popup__messageconfirmation">Le rib a bien été supprimé</span>}
                            {showErrorMessageRib && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageRib}</span>}
                            <FormInput
                                label="BIC*"
                                type="text"
                                id="bic"
                                onChangeInput={value => setBIC(value)}
                                placeholder="PST029563"
                                value={bic}
                                maxLength={50}
                                disabled={isConfirmed}
                            />
                            <FormInput
                                label="IBAN*"
                                type="text"
                                id="iban"
                                onChangeInput={value => setIBAN(value)}
                                placeholder="4526 4698 4862 9865 3574"
                                value={iban}
                                maxLength={50}
                                disabled={isConfirmed}
                            />
                        </form>
                    </div>
                </div>
            </div>
            {showConfirmationPopup && <InformationPopup
                type={popupType}
                isLoading={isConfirmationLoading}
                closeInformationPopup={() => {
                    setShowConfirmationErrorMessage(false)
                    setShowConfirmationPopup(false)
                }}
                confirm={() => confirmSepa()}
                showErrorMessage={showConfirmationErrorMessage}
            />
            }
        </>
    )
}

export default UpdateSepa;