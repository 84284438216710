import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import { StyledTableCell } from './StyledTableCell'
import { visuallyHidden } from '@mui/utils'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ToolTip from '../ToolTip'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props
    const createSortHandler = (property) => (event) => { onRequestSort(event, property) }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <StyledTableCell
                        key={`${headCell.id}-${index}`}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <div className={`${(headCell.id === "montantFacHT" || headCell.id === "montantFacTTC") ? "table--flexend" : ""} table--head__container`} id={props.isConso ? `table--head__container-${index}` : ""}>
                            {headCell.isSortable ?
                                <>
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    {headCell.withPlus &&
                                        <>
                                            {props.showPlus ?
                                                <RemoveCircleOutlineIcon
                                                    onClick={() => props.setShowPlus(!props.showPlus)}
                                                    sx={{ width: "18px", cursor: "pointer" }}
                                                />
                                                :
                                                <AddCircleOutlineIcon
                                                    onClick={() => props.setShowPlus(!props.showPlus)}
                                                    sx={{ width: "18px", cursor: "pointer" }}
                                                />
                                            }
                                        </>
                                    }
                                </>
                                :
                                headCell.label
                            }
                            {headCell.id === "car" &&
                                <ToolTip
                                    text={"Consommation annuelle de référence en kWh."}
                                    type="headcell"
                                    marginTop="0"
                                    top={38}
                                    left={-90}>
                                    <InfoOutlinedIcon sx={{ fontSize: "18px", padding: "0 0 0 5px" }} />
                                </ToolTip>}
                        </div>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;