import { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router'

import DarkMagnifierIcon from '../../img/icons/magnifier-darkgrey.svg'
import MagnifierIcon from '../../img/icons/magnifier.svg'
import EnveloppeIcon from '../../img/icons/enveloppe.svg'
import NotificationsIcon from '../../img/icons/notifications.svg'
import AccountIcon from '../../img/icons/my-account.svg'
import ProfileIcon from '../../img/icons/my-account.svg'
import LogoutIcon from '../../img/icons/logout.svg'
import axios from 'axios'
import MiniLogo from '../../img/Mini-logo-wattunity.png'
import OpenMobileMenu from '../../img/icons/openmobilemenu.png'
import CloseMobileMenu from '../../img/icons/closemobilemenu.svg'
import Logo from '../../img/Logo-wattunity.png'
import MobileSideBar from './MobileSideBar'
import SearchTable from './SearchTable'
import CloseIcon from '@mui/icons-material/Close'

const apiUrl = process.env.REACT_APP_API_URL

function TopBar(props) {
    const [showAccountMenu, setShowAccountMenu] = useState(false)
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    const [showSearchBlock, setShowSearchBlock] = useState(false)
    const [showAppelOffres, setShowAppelOffres] = useState(false)
    const [showSites, setShowSites] = useState(false)
    const [showContracts, setShowContracts] = useState(false)
    const [showConso, setShowConso] = useState(false)

    const [isSearchLoading, setIsSearchLoading] = useState(false)
    const [appelOffres, setAppelOffres] = useState([])
    const [sites, setSites] = useState([])
    const [contracts, setContracts] = useState([])
    const [conso, setConso] = useState([])

    const [activeTab, setActiveTab] = useState([])
    const [page, setPage] = useState(0)

    const [showMobileSearchInput, setShowMobileSearchInput] = useState(false)
    const [searchValue, setSearchValue] = useState("")

    let cancelToken

    const handleLogout = async () => {
        try {
            await axios.post(
                `${apiUrl}/auth/logout`,
                "",
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
            navigate('/')
        }
        catch (error) {
            if (error && error.response.status && error.response.status === 401) {
                window.location.reload()
            }
        }
    }

    const globalSearch = useCallback((value) => {
        if (value.length === 0) {
            setShowSearchBlock(false)
            setShowAppelOffres(false)
            setShowSites(false)
            setShowContracts(false)
            setShowConso(false)
            setSearchValue(value)
            setAppelOffres([])
            setSites([])
            setContracts([])
            setConso([])
        }
        else {
            setSearchValue(value)
            setIsSearchLoading(true)
            setShowSearchBlock(true)
            setShowMobileSearchInput(true)

            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel("Operation canceled due to new request.")
            }

            cancelToken = axios.CancelToken.source()

            axios.get(`${apiUrl}/Recherche/${value}`,
                {
                    headers: {
                        'Accept': '*/*',
                    },
                    withCredentials: true,
                    cancelToken: cancelToken.token
                })
                .then((response) => {
                    setAppelOffres(response.data.appelsOffres)
                    setSites(response.data.sites)
                    setContracts(response.data.contrats)
                    setConso(response.data.consommations)

                    if (response.data?.appelsOffres?.length > 0) {
                        setShowAppelOffres(true)
                        setShowSites(false)
                        setShowContracts(false)
                        setShowConso(false)
                        setActiveTab(response.data.appelsOffres)
                    }
                    else if (response.data?.sites?.length > 0) {
                        setShowSites(true)
                        setShowAppelOffres(false)
                        setShowContracts(false)
                        setShowConso(false)
                        setActiveTab(response.data.sites)
                    }
                    else if (response.data?.contrats?.length > 0) {
                        setShowContracts(true)
                        setShowAppelOffres(false)
                        setShowSites(false)
                        setShowConso(false)
                        setActiveTab(response.data.contrats)
                    }
                    else if (response.data?.consommations?.length > 0) {
                        setShowConso(true)
                        setShowAppelOffres(false)
                        setShowSites(false)
                        setShowContracts(false)
                        setActiveTab(response.data.consommations)
                    }
                    else {
                        setShowAppelOffres(false)
                        setShowSites(false)
                        setShowContracts(false)
                        setShowConso(false)
                    }
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
                .finally(() => setIsSearchLoading(false))
        }
    }, [])

    useEffect(() => {
        globalSearch("")
        setShowMobileMenu(false)
    }, [location.pathname, globalSearch])

    return (
        <>
            {showMobileMenu && <MobileSideBar isClient={props.isClient} setShowMobileMenu={setShowMobileMenu} />}
            <div className="topbar--container">
                <Link to={'/dashboard'}><div className="topbar--leftpart">
                    <img src={Logo} alt="Logo WattValue" className="topbar--logo" />
                    <span className="topbar--baseline">Groupement d'achat & Services à l'énergie</span>
                </div></Link>
                <Link to={'/dashboard'}><img src={MiniLogo} alt="Logo WattValue" className="topbar--minilogo" /></Link>
                {showMobileMenu ?
                    <div className="topbar--menu__iconcontainer"
                        onClick={() => {
                            setShowMobileMenu(false)
                        }}>
                        <img src={CloseMobileMenu} alt="Fermer menu" id="close" className="topbar--openmobilemenuicon" />
                        <span className="topbar--menu__title">Menu</span>
                    </div>
                    :
                    <div className="topbar--menu__iconcontainer"
                        onClick={() => {
                            setShowMobileMenu(true)
                            setShowMobileSearchInput(false)
                            globalSearch("")
                        }}>
                        <img src={OpenMobileMenu} alt="Ouvrir menu" className="topbar--openmobilemenuicon" />
                        <span className="topbar--menu__title">Menu</span>
                    </div>
                }
                <img
                    src={MagnifierIcon}
                    alt="rechercher"
                    className="topbar--magnifiericon" />
                {showMobileSearchInput ?
                    <img
                        src={CloseMobileMenu}
                        alt="Fermer la barre de recherche"
                        className="topbar--magnifiericonbig"
                        onClick={() => {
                            setShowMobileSearchInput(false)
                            globalSearch("")
                        }} />
                    : <img
                        src={DarkMagnifierIcon}
                        alt="rechercher"
                        className="topbar--magnifiericonbig"
                        onClick={() => {
                            setShowMobileSearchInput(true)
                            setShowMobileMenu(false)
                        }} />}
                <div className="topbar--fakesearchbar__container">
                    <input
                        type="text"
                        className="topbar--fakesearchbar"
                        placeholder="Rechercher"
                        value={searchValue}
                        onChange={(e) => {
                            globalSearch(e.target.value)
                        }} />
                    {searchValue.length > 0 && <CloseIcon
                        sx={{ color: "#A1A1AA", position: "absolute", top: "6px", right: "-25px", cursor: "pointer" }}
                        onClick={() => {
                            setShowMobileSearchInput(false)
                            globalSearch("")
                        }}
                    />}
                </div>
                <div className="topbar--rightpart">
                    <Link to={'/messages'}><img
                        src={EnveloppeIcon}
                        alt="messages"
                        className="topbar--notificon"
                    /></Link>
                    <img
                        src={NotificationsIcon}
                        alt="notifications"
                        className="topbar--notificon" />
                    <div
                        className="topbar--myaccount"
                        onMouseEnter={() => setShowAccountMenu(true)}
                        onMouseLeave={() => setShowAccountMenu(false)}
                    >
                        <div className="topbar--myaccount__text">Mon compte</div>
                        <img
                            src={AccountIcon}
                            alt="notifications"
                            className="topbar--accounticon" />
                    </div>
                </div>
                {showAccountMenu &&
                    <ul
                        className="topbar--accountmenu"
                        onMouseEnter={() => setShowAccountMenu(true)}
                        onMouseLeave={() => setShowAccountMenu(false)}
                    >
                        <Link to={'/account'}><li className="topbar--accountmenu__myprofile topbar--accountmenu__item">
                            <img
                                src={ProfileIcon}
                                alt="Mon profil"
                                className="topbar--accountmenu__icon" />
                            Mon profil
                        </li></Link>
                        <li className="topbar--accountmenu__item" onClick={() => handleLogout()}>
                            <img
                                src={LogoutIcon}
                                alt="Mon profil"
                                className="topbar--accountmenu__icon" />
                            Déconnexion
                        </li>
                    </ul>}
            </div >
            {showMobileSearchInput &&
                <div className="topbar--mobilesearchbar__container">
                    <input
                        type="text"
                        className="topbar--mobilesearchbar topbar--fakesearchbar"
                        placeholder="Rechercher"
                        value={searchValue}
                        onChange={(e) => {
                            globalSearch(e.target.value)
                        }} />
                </div>
            }
            {
                showSearchBlock &&
                <div className="topbar--search__container">
                    <div className="topbar--search__header">
                        <div className={`topbar--search__title ${appelOffres.length > 0 ? "topbar--search__titlewithdata" : ""} ${showAppelOffres ? "topbar--search__titleselected" : ""}`}
                            onClick={appelOffres.length > 0 ? () => {
                                setShowAppelOffres(true)
                                setActiveTab(appelOffres)
                                setShowSites(false)
                                setShowContracts(false)
                                setShowConso(false)
                                setPage(0)
                            } : null}
                        >
                            Mes appels d'offres
                            {appelOffres.length > 0 ? <div className={`topbar--search__pastille ${showAppelOffres ? "topbar--search__pastilleselected" : ""}`}>{appelOffres.length}</div> : ""}
                        </div>
                        <div className={`topbar--search__title ${sites.length > 0 ? "topbar--search__titlewithdata" : ""} ${showSites ? "topbar--search__titleselected" : ""}`}
                            onClick={sites.length > 0 ? () => {
                                setShowSites(true)
                                setActiveTab(sites)
                                setShowContracts(false)
                                setShowConso(false)
                                setShowAppelOffres(false)
                                setPage(0)
                            } : null}
                        >
                            Mes sites
                            {sites.length > 0 ? <div className={`topbar--search__pastille ${showSites ? "topbar--search__pastilleselected" : ""}`}>{sites.length}</div> : ""}
                        </div>
                        <div className={`topbar--search__title ${contracts.length > 0 ? "topbar--search__titlewithdata" : ""} ${showContracts ? "topbar--search__titleselected" : ""}`}
                            onClick={contracts.length > 0 ? () => {
                                setShowContracts(true)
                                setActiveTab(contracts)
                                setShowConso(false)
                                setShowAppelOffres(false)
                                setShowSites(false)
                                setPage(0)
                            } : null}
                        >
                            Mes contrats
                            {contracts.length > 0 ? <div className={`topbar--search__pastille ${showContracts ? "topbar--search__pastilleselected" : ""}`}>{contracts.length}</div> : ""}
                        </div>
                        <div className={`topbar--search__title ${conso.length > 0 ? "topbar--search__titlewithdata" : ""} ${showConso ? "topbar--search__titleselected" : ""}`}
                            onClick={conso.length > 0 ? () => {
                                setShowConso(true)
                                setActiveTab(conso)
                                setShowAppelOffres(false)
                                setShowSites(false)
                                setShowContracts(false)
                                setPage(0)
                            } : null}
                        >
                            Mes consommations
                            {conso.length > 0 ? <div className={`topbar--search__pastille ${showConso ? "topbar--search__pastilleselected" : ""}`}>{conso.length}</div> : ""}
                        </div>
                    </div>
                    <div className="topbar--search__searchcontainer">
                        {isSearchLoading ?
                            <span className="global--loader"></span>
                            :
                            (appelOffres.length === 0 && sites.length === 0 && contracts.length === 0 && conso.length === 0) ?
                                <span>Aucun résultat trouvé</span>
                                :
                                <>
                                    <SearchTable
                                        rows={activeTab}
                                        setPage={setPage}
                                        page={page}
                                        isAppelOffres={showAppelOffres}
                                        isSites={showSites}
                                        isContracts={showContracts}
                                        isConso={showConso}
                                    />
                                </>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default TopBar;