import { useState, useEffect, useCallback } from "react"
import { useNavigate, useSearchParams, Link } from 'react-router-dom'

import SideBar from "../../Components/SideBar/SideBar"
import axios from "axios"
import Button from "../../Components/Button"
import FormInput from '../../Components/Form/FormInput'
import InformationPopup from "../../Components/InformationPopup"
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

const apiUrl = process.env.REACT_APP_API_URL

function AddSitesStep3() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [siteID] = useState(searchParams.get('id') || "")
    const [isElec] = useState(searchParams.get('type') === "elec")
    const [isGaz] = useState(searchParams.get('type') === "gaz")
    const [fromAppelOffre] = useState(searchParams.get('from') === "appels-offre")
    const isReengagement = window.location.search.includes('reengagement')
    const isAdd = window.location.pathname.includes('add')

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [dateDebut, setDateDebut] = useState("")
    const [dateFin, setDateFin] = useState("")
    const [autreFournisseur, setAutreFournisseur] = useState("")
    const [fournisseurs, setFournisseurs] = useState([])

    const [optionGazVert, setOptionGazVert] = useState("oui")
    const [renewableEnergy, setRenewableEnergy] = useState(null)

    const [isConfirmed, setIsConfirmed] = useState(false)

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [isConfirmationLoading, setIsConfirmationLoading] = useState(false)
    const [showConfirmationErrorMessage, setShowConfirmationErrorMessage] = useState(false)
    const [popupType, setPopupType] = useState("confirmation-add-site")

    const [showErrorMessageRenewableEnergy, setShowErrorMessageRenewableEnergy] = useState(false)
    const [showErrorMessageOptionGazVert, setShowErrorMessageOptionGazVert] = useState(false)

    const getSiteData = useCallback(() => {
        axios.get(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Site/${siteID}/3`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                if (response && response.data) {
                    response.data.dateDebutFourniture && setDateDebut(new Date(response.data.dateDebutFourniture).toLocaleDateString())
                    response.data.dateFinNouveauContrat && setDateFin(new Date(response.data.dateFinNouveauContrat).toLocaleDateString())
                    response.data.fournisseurAutre && setAutreFournisseur(response.data.fournisseurAutre)
                    response.data.fournisseurs && setFournisseurs(response.data.fournisseurs)

                    response.data.isConfirmed && setIsConfirmed(response.data.isConfirmed)

                    response.data.optionGazVert && setOptionGazVert(response.data.optionGazVert)

                    setRenewableEnergy(response.data.partRenouvelable)
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [siteID, isGaz])

    useEffect(() => {
        getSiteData()
    }, [getSiteData])

    const handleSubmitStep3 = () => {
        if (isConfirmed && siteID && !isGaz) {
            navigate(`/${isAdd ? "add" : "modify"}-sites-4?type=elec&id=${siteID}${fromAppelOffre ? "&from=appels-offre" : ""}`)
        }
        else if (!isGaz && (!renewableEnergy && renewableEnergy !== 0)) {
            setShowErrorMessageRenewableEnergy(true)
        }
        else if (isGaz && (!optionGazVert || optionGazVert === "")) {
            setShowErrorMessageOptionGazVert(true)
        }
        else {
            setErrorMessage("Une erreur est survenue, veuillez réessayer plus tard")
            setShowErrorMessage(false)
            setIsLoading(true)
            const formData = JSON.stringify({
                "optionGazVert": `${optionGazVert}`,
                "fournisseurs": fournisseurs,
                "fournisseurAutre": `${autreFournisseur}`,
                "partRenouvelable": renewableEnergy,
            })
            axios.post(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Site/${siteID}/3`,
                formData,
                {
                    headers: {
                        'Accept': 'text/plain',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then(() => {
                    if (isGaz) {
                        setShowConfirmationPopup(true)
                    }

                    else {
                        navigate(`/${isAdd ? "add" : "modify"}-sites-4?type=elec&id=${siteID}${fromAppelOffre ? "&from=appels-offre" : ""}`)
                    }
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                    if (error?.response?.data?.message) {
                        setErrorMessage(error.response.data.message)
                    }
                    setShowErrorMessage(true)
                })
                .finally(() => setIsLoading(false))
        }
    }

    const selectBox = (index) => {
        const tempFournisseurs = [...fournisseurs]
        tempFournisseurs[index]['isSelected'] = !tempFournisseurs[index]['isSelected']
        setFournisseurs(tempFournisseurs)
    }

    const confirmAddSite = () => {
        setIsConfirmationLoading(true)
        setShowConfirmationErrorMessage(false)
        axios.post(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Site/${siteID}/3/Confirm`,
            "",
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                setPopupType("add-site-confirmed")
                getSiteData()
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsConfirmationLoading(false))
    }

    return (
        <>
            <SideBar />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">{isAdd ? "Ajouter un nouveau site à votre compte" : isConfirmed ? "Visualiser le site" : "Modifier le site"}</h3>
                    <Link to={fromAppelOffre ? `/appels-offre-${isElec ? "elec" : "gaz"}` : `/${isElec ? "elec" : "gaz"}-sites`}><span className="privatepages--header__return">{`< Retour à la liste des sites ${isGaz ? "Gaz" : "Électricité"}`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="addsites--step__titlecontainer">
                    <h4 className="addsites--step__title">Étape {isReengagement ? "2" : "3"}/{isReengagement ? "2" : "3"} : <span className="addsites--step__titleright">Choix de l'offre de marché</span></h4>
                    <div className="sepa--buttonscontainer">
                        <Button
                            cta="Revenir à l'étape précédente"
                            link={`/${isAdd ? "add" : "modify"}-sites-${isReengagement ? "1" : "2"}?type=${isGaz ? "gaz" : "elec"}&id=${siteID}${fromAppelOffre ? "&from=appels-offre" : ""}`}
                            additionnalClassName="global--button__goback" />
                        <Button
                            cta="Étape suivante"
                            additionnalClassName="addsites--step__button"
                            handleSubmit={() => handleSubmitStep3()}
                            isLoading={isLoading}
                            disabled={isGaz && isConfirmed}
                        />
                    </div>
                </div>
                {showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror sepa--messageinfo">{errorMessage}</span>}
                {isConfirmed && <span className="homepage--popup__message homepage--popup__messageconfirmation sepa--messageinfo">Votre site est confirmé. Si vous souhaitez le modifier, merci de contacter l'équipe WattValue.</span>}
                <div className="privatepages--columnscontainer">
                    <div className="privatepages--twocolumns privatepages--twocolumns__left">
                        <div>
                            <span className="privatepages--asterisk privatepages--text">*champ obligatoire</span>
                            <form className="privatepages--form__block">
                                <h4 className="privatepages--form__title">Date de fourniture</h4>
                                <span className="addsites--notabene">NB : Wattvalue se réserve le droit de sélectionner une date d'échéance commune aux différents sites participants à l'appel d'offres afin d'obtenir une cotation plus intéressante de la part des fournisseurs.</span>
                                <span className="addsites--enddate__label">Date de début de fourniture souhaitée*</span>
                                <span className="addsites--enddate__subtitle">Date d'effet sous réserve des conditions de contrat en cours et de négociation avec le fournisseur. Pour les offres en TRV, la date proposée est susceptible d'évoluer mais sera dans tous les cas le plus tôt possible.</span>
                                <span className="addsites--enddate__disabled">{(dateDebut)}</span>
                                <span className="addsites--enddate__label">Date de fin du nouveau contrat*</span>
                                <span className="addsites--enddate__disabled">{(dateFin)}</span>
                            </form>
                        </div>
                    </div>
                    <div className="privatepages--twocolumns privatepages--twocolumns__right">
                        <form className="privatepages--form__block">
                            <h4 className="privatepages--form__title">Les options demandées*</h4>
                            {isGaz ?
                                <>
                                    <span className="addsites--enddate__label">Souhaitez-vous être contacté(e) pour une option Gaz Vert ? *</span>
                                    <div className="homepage--popup__multipleinputcontainer homepage--popup__multipleinputcontainermarginbottom homepage--popup__multipleinputcontainermargintop">
                                        <label className="homepage--popup__radioinputcontainer" htmlFor="oui">Oui**
                                            <input type="radio" defaultChecked="checked" id="oui" name="greenenergy" value="oui" disabled={isConfirmed} onChange={e => {
                                                setShowErrorMessageOptionGazVert(false)
                                                setOptionGazVert(e.target.value)
                                            }} />
                                            <span className="homepage--popup__checkmark"></span>
                                        </label>
                                        <label className="homepage--popup__radioinputcontainer" htmlFor="non">Non
                                            <input type="radio" name="greenenergy" id="non" value="non" disabled={isConfirmed} onChange={e => {
                                                setShowErrorMessageOptionGazVert(false)
                                                setOptionGazVert(e.target.value)
                                            }} />
                                            <span className="homepage--popup__checkmark"></span>
                                        </label>
                                    </div>
                                    {showErrorMessageOptionGazVert && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez répondre à la questions</span>}
                                </>
                                :
                                <>
                                    <FormControl>
                                        <FormLabel
                                            id="contract-radio-button"
                                            sx={{
                                                fontFamily: "Raleway",
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                lineHeight: "14px",
                                                textAlign: "left",
                                                color: "#566F8F !important",
                                                marginTop: "12px",
                                                marginBottom: "7px",
                                            }}
                                        >Part d'électricité renouvelable certifiée*
                                        </FormLabel>
                                        <span className="elec--add__invoicesubtitle">Pour tout autre pourcentage d'électricité renouvelable certifiée, nous contacter.</span>
                                        <RadioGroup
                                            aria-labelledby="contract-radio-button"
                                            name="contract-radio-button"
                                            value={renewableEnergy}
                                            onChange={(e) => {
                                                setRenewableEnergy(e.target.value)
                                                setShowErrorMessageRenewableEnergy(false)
                                            }}

                                        >
                                            <FormControlLabel
                                                value={25}
                                                sx={{
                                                    ".MuiFormControlLabel-label": {
                                                        fontFamily: "Raleway",
                                                        fontSize: "12px",
                                                        fontWeight: 400,
                                                        lineHeight: "14px",
                                                        textAlign: "left",
                                                        color: "#566F8F",
                                                    },
                                                }}
                                                control={
                                                    <Radio sx={{
                                                        color: "#566F8F",
                                                        padding: "2px 9px",
                                                        '&.Mui-checked': {
                                                            color: "#566F8F",
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 15,
                                                        },
                                                        ".MuiFormControlLabel-label": {
                                                            fontFamily: "Raleway",
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                            lineHeight: "14px",
                                                            textAlign: "left",

                                                        },
                                                    }}
                                                        disabled={isConfirmed} />
                                                } label="25%" />
                                            <FormControlLabel
                                                value={50}
                                                sx={{
                                                    ".MuiFormControlLabel-label": {
                                                        fontFamily: "Raleway",
                                                        fontSize: "12px",
                                                        fontWeight: 400,
                                                        lineHeight: "14px",
                                                        textAlign: "left",
                                                        color: "#566F8F",
                                                    },
                                                }}
                                                control={
                                                    <Radio sx={{
                                                        color: "#566F8F",
                                                        padding: "2px 9px",
                                                        '&.Mui-checked': {
                                                            color: "#566F8F",
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 15,
                                                        },
                                                        ".MuiFormControlLabel-label": {
                                                            fontFamily: "Raleway",
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                            lineHeight: "14px",
                                                            textAlign: "left",

                                                        },
                                                    }}
                                                        disabled={isConfirmed} />
                                                } label="50%" />
                                            <FormControlLabel
                                                value={100}
                                                sx={{
                                                    ".MuiFormControlLabel-label": {
                                                        fontFamily: "Raleway",
                                                        fontSize: "12px",
                                                        fontWeight: 400,
                                                        lineHeight: "14px",
                                                        textAlign: "left",
                                                        color: "#566F8F",
                                                    },
                                                }}
                                                control={
                                                    <Radio sx={{
                                                        color: "#566F8F",
                                                        padding: "2px 9px",
                                                        '&.Mui-checked': {
                                                            color: "#566F8F",
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 15,
                                                        },
                                                        ".MuiFormControlLabel-label": {
                                                            fontFamily: "Raleway",
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                            lineHeight: "14px",
                                                            textAlign: "left",

                                                        },
                                                    }}
                                                        disabled={isConfirmed} />
                                                } label="100%" />
                                            <FormControlLabel
                                                value={0}
                                                sx={{
                                                    ".MuiFormControlLabel-label": {
                                                        fontFamily: "Raleway",
                                                        fontSize: "12px",
                                                        fontWeight: 400,
                                                        lineHeight: "14px",
                                                        textAlign: "left",
                                                        color: "#566F8F",
                                                    },
                                                }}
                                                control={
                                                    <Radio sx={{
                                                        color: "#566F8F",
                                                        padding: "2px 9px",
                                                        '&.Mui-checked': {
                                                            color: "#566F8F",
                                                        },
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 15,
                                                        },
                                                        ".MuiFormControlLabel-label": {
                                                            fontFamily: "Raleway",
                                                            fontSize: "10px",
                                                            fontWeight: 400,
                                                            lineHeight: "14px",
                                                            textAlign: "left",

                                                        },
                                                    }}
                                                        disabled={isConfirmed} />
                                                } label="Aucune garantie d'origine" />
                                        </RadioGroup>
                                    </FormControl>
                                    {showErrorMessageRenewableEnergy && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez répondre à la questions</span>}
                                </>
                            }
                            <span className="addsites--enddate__label">Si vous souhaitez exclure un ou plusieurs fournisseurs merci de cocher les cases correspondantes :</span>
                            {!isGaz && <span className="elec--add__invoicesubtitle">Liste des fournisseurs : <span onClick={() => window.open('http://www.energie-info.fr/')} className="elec--add__link">http://www.energie-info.fr/</span></span>}
                            {fournisseurs.map((element, index) => {
                                return <FormInput
                                    type="checkbox"
                                    key={`checkbox-greengaz-${index}`}
                                    nom={element.nom}
                                    isSelected={element.isSelected}
                                    selectBox={() => selectBox(index)}
                                    additionnalLabelClassName="homepage--checkbox__label"
                                    disabled={isConfirmed} />
                            })}
                            <FormInput
                                type="text"
                                label="Autre"
                                id="other"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setAutreFournisseur(value)
                                }}
                                placeholder="Nom du fournisseur"
                                value={autreFournisseur}
                                disabled={isConfirmed}
                            />
                        </form>
                        {isGaz && <span className="privatepages--asterisk privatepages--text addsites--bottomtext">** En fonction des contraintes réglementaires et de la disponibilité des sources d'approvisionnement, WattValue pourra étudier vos demandes de fourniture en gaz issu du renouvelable. Cette option peut engendrer un surcoût.</span>}
                    </div>
                </div>
            </div>
            {showConfirmationPopup && <InformationPopup
                type={popupType}
                isLoading={isConfirmationLoading}
                closeInformationPopup={() => {
                    setShowConfirmationErrorMessage(false)
                    setShowConfirmationPopup(false)
                }}
                confirm={() => confirmAddSite()}
                showErrorMessage={showConfirmationErrorMessage}
            />
            }
        </>
    )
}

export default AddSitesStep3;