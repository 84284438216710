import Button from "./Button"
import Close from "../img/icons/close.png"
import { loadCaptchaEnginge, LoadCanvasTemplate } from 'react-simple-captcha'
import { useEffect } from "react"

function InformationPopup(props) {

    useEffect(() => {
        if (props.type === "newsletter-subscription") loadCaptchaEnginge(6, "#48AB4D", "white")
    }, [props.type])

    if (props.type === "newsletter-subscription") {
        return (
            <div className="informationpopup--background">
                <div className="informationpopup--container informationpopup--containernewsletter">
                    <img src={Close} alt="Close popup" className="informationpopup--close" onClick={() => props.closeInformationPopup()} />
                    <h4 className="informationpopup--title">Inscription à la newsletter</h4>
                    <p className="informationpopup--subtitle informationpopup--subtitle__margintop">Merci de renseigner vos informations ci-dessous pour vous inscrire à notre newsletter.</p>
                    <form className="informationpopup--formcontainer">
                        <span className="informationpopup--input__title">Nom</span>
                        <input
                            type="text"
                            id="name"
                            onChange={e => {
                                props.setShowErrorMessage(false)
                                props.setName(e.target.value)
                            }}
                            placeholder="Votre nom..."
                            defaultValue={props.name || ""}
                            className="informationpopup--input"
                        />
                        <span className="informationpopup--input__title">Email</span>
                        <input
                            type="email"
                            id="email"
                            onChange={e => {
                                props.setShowErrorMessage(false)
                                props.setEmail(e.target.value)
                            }
                            }
                            placeholder="Votre email..."
                            defaultValue={props.email || ""}
                            className="informationpopup--input informationpopup--lastinput"
                        />
                        <LoadCanvasTemplate
                            reloadText="Régénérer le captcha"
                            reloadColor="black" />
                        <input
                            type="text"
                            id="captcha_input"
                            placeholder="Entrer les caractères ci-dessus"
                            className="informationpopup--input informationpopup--margintop15 informationpopup--lastinput"
                            onChange={() => props.setShowErrorMessage(false)} />
                        {props.showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror">{props.errorMessage}</span>}
                        <Button
                            isLoading={props.isLoading}
                            disabled={
                                props.name === "" ||
                                props.email === "" ||
                                props.name === undefined ||
                                props.email === undefined
                            }
                            handleSubmit={props.handleNewsletterSubscription}
                            cta="Confirmer l'inscription" />
                    </form>
                </div>
            </div>
        )
    }
    else if (props.type === "newsletter-confirmation") {
        return (
            <div className="informationpopup--background">
                <div className="informationpopup--container informationpopup--containernewsletter informationpopup--containernewsletter__confirmation">
                    <img src={Close} alt="Close popup" className="informationpopup--close" onClick={() => props.closeInformationPopup()} />
                    <h4 className="informationpopup--title">Inscription à la newsletter</h4>
                    <p className="informationpopup--subtitle">Votre inscription à la newsletter a bien été prise en compte.</p>
                </div>
            </div>
        )
    }
    else if (props.type === "suppr") {
        return (
            <div className="informationpopup--background">
                <div className="informationpopup--container informationpopup--containernewsletter__suppr">
                    <img src={Close} alt="Close popup" className="informationpopup--close" onClick={() => props.closeInformationPopup()} />
                    <h4 className="informationpopup--title informationpopup--title__center">{props.title}</h4>
                    <p className="informationpopup--title informationpopup--title__center">Toute suppression est définitive.</p>
                    <div className="informationpopup--buttoncontainer">
                        <Button
                            cta="Revenir en arrière"
                            handleSubmit={() => props.closeInformationPopup(false)}
                            additionnalClassName="global--button__goback" />
                        <Button
                            cta="Confirmer la suppression"
                            handleSubmit={() => props.handleSuppr()}
                        />
                    </div>
                </div>
            </div>
        )
    }
    else if (props.type === "confirmation-sepa" || props.type === "confirmation-add-site") {
        return (
            <div className="informationpopup--background">
                <div className="informationpopup--container informationpopup--containernewsletter__suppr">
                    <img src={Close} alt="Close popup" className="informationpopup--close" onClick={() => props.closeInformationPopup()} />
                    <h4 className="informationpopup--title informationpopup--title__center">Les informations ont été enregistrées</h4>
                    <p className="informationpopup--subtitle informationpopup--subtitle__margintop informationpopup--title__center">Souhaitez-vous valider les informations de votre {props.type === "confirmation-sepa" ? "mandat SEPA" : "site"} ?<br />La validation est définitive.</p>
                    {props.type === "confirmation-add-site" && <p className="informationpopup--subtitle informationpopup--subtitle__margintop informationpopup--title__center">Seule l'équipe WattValue aura la possibilité de les modifier si vous lui en faites la demande.</p>}
                    <div className="informationpopup--buttoncontainer">
                        <Button
                            cta="Retour"
                            handleSubmit={() => props.closeInformationPopup(false)}
                            additionnalClassName="global--button__goback" />
                        <Button
                            cta="Confirmer la validation"
                            isLoading={props.isLoading}
                            handleSubmit={() => props.confirm()}
                        />
                    </div>
                    {props.showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror homepage--popup__messagecenter">{props.errorMessage}</span>}
                </div>
            </div>
        )
    }
    else if (props.type === "sepa-confirmed" || props.type === "add-site-confirmed") {
        return (
            <div className="informationpopup--background">
                <div className="informationpopup--container informationpopup--containernewsletter informationpopup--containernewsletter__confirmation informationpopup--containernewsletter__sepa">
                    <img src={Close} alt="Close popup" className="informationpopup--close" onClick={() => props.closeInformationPopup()} />
                    <h4 className="informationpopup--title">Votre {props.type === "sepa-confirmed" ? "mandat" : "site"} a bien été validé</h4>
                    {props.type === "sepa-confirmed" && <p className="informationpopup--subtitle">Vous allez recevoir une demande de signature du mandat par email.</p>}
                    {props.type === "add-site-confirmed" && <Button
                        cta="Retour à la liste des sites"
                        additionnalClassName="global--button__margintop8 global--button__secondary"
                        link={`/${props.isElec ? "elec" : "gaz"}-sites`}
                    />}
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="informationpopup--background">
                <div className="informationpopup--container informationpopup--containernewsletter__information">
                    {!props.hideClose && <img src={Close} alt="Close popup" className="informationpopup--close" onClick={() => props.closeInformationPopup()} />}
                    {props.title && <h4 className="informationpopup--title">{props.title}</h4>}
                    {props.subtitle && <p className="informationpopup--subtitle informationpopup--subtitle__margintop">{props.subtitle}</p>}
                    {props.subtitle2 && <p className="informationpopup--subtitle informationpopup--subtitle__margintop">{props.subtitle2}</p>}
                    {props.text && <p className="informationpopup--text">{props.text}</p>}
                    {props.cta && <Button
                        cta={props.cta}
                        additionnalClassName="global--button__margintop8"
                        link={props.link}
                        handleSubmit={props.handleSubmit}
                    />}
                </div>
            </div>
        )
    }
}

export default InformationPopup;