import { SearchBar } from "../../../Components/SearchBar"
import SideBar from "../../../Components/SideBar/SideBar"
import ConsoSiteTable from "./ConsoSiteTable"
import { useState, useCallback, useEffect } from "react"
import { createDataSitesConso } from "../../../Utils/TableUtils/createData"
import { removeAccents } from "../../../Utils/Regex/removeAccents"
import axios from "axios"
import CloseIcon from '@mui/icons-material/Close'
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DateCalendar } from "@mui/x-date-pickers"
import dayjs from 'dayjs'
import "dayjs/locale/fr"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const apiUrl = process.env.REACT_APP_API_URL

function Conso() {
    const [page, setPage] = useState(0)
    const [isDataLoading, setIsDataLoading] = useState(false)

    const [sites, setSites] = useState([])
    const [filteredSites, setFilteredSites] = useState([])

    const [siteIdArray, setSiteIdArray] = useState([])
    const [exportType, setExportType] = useState("Somme")
    const [isDataLoadingExport, setIsDataLoadingExport] = useState(false)
    const [showCalendar, setShowCalendar] = useState(false)
    const [dateDebut, setateDebut] = useState(new Date())
    const [formatedDateDebut, setFormatedateDebut] = useState(new Date().toLocaleDateString())
    const [dateFin, setDateFin] = useState(new Date())
    const [formatedDateFin, setFormatedDateFin] = useState(new Date().toLocaleDateString())
    const [dateType, setDateType] = useState("")
    const [searchValue, setSearchValue] = useState("")

    const getSitesData = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/Conso/Site`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                const sites = response.data.map(element => createDataSitesConso(element.siteID, element.libelle, element.raisonSociale, `${element.adresse ? element.adresse : ""}${element.codePostal ? ` ${element.codePostal}` : ""}${element.ville ? ` ${element.ville}` : ""}`, element.prm, (element.dateMiseEnLigne && element.dateFinAbonnement) ? `Du ${new Date(element.dateMiseEnLigne).toLocaleDateString()} au ${new Date(element.dateFinAbonnement).toLocaleDateString()}` : "", element.releveType, element.souscriptionType))
                setFilteredSites(sites)
                setSites(sites)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [])

    useEffect(() => {
        getSitesData()
        setPage(0)
    }, [getSitesData, setPage])

    const filterSites = (query) => {
        if (!query || query === "") {
            setFilteredSites(sites)
            setPage(0)
        }
        else {
            const keys = ['siteID', 'libelle', 'adresse', 'raisonSociale', 'prm', 'periode']
            const results = sites.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredSites(results)
            setPage(0)
        }
    }

    const CheckAll = () => {
        if (sites.length === siteIdArray.length) {
            setSiteIdArray([])
        }
        else {
            let siteIdArrayCopy = []
            sites.forEach((site) => siteIdArrayCopy.push(site.siteID))
            setSiteIdArray(siteIdArrayCopy)
        }
    }

    const CheckSite = (siteId) => {
        let siteIdArrayCopy = [...siteIdArray]
        const index = siteIdArrayCopy.indexOf(siteId)
        if (index > -1) {
            siteIdArrayCopy.splice(index, 1)
        }
        else {
            siteIdArrayCopy.push(siteId)
        }
        setSiteIdArray(siteIdArrayCopy)
    }

    const handleDataExport = () => {
        setIsDataLoadingExport(true)
        const idString = siteIdArray.join('&ids=')

        axios.get(`${apiUrl}/Conso/Site/Export/${exportType}?ids=${idString}&dateDebut=${new Date(dateDebut).toISOString()}&dateFin=${new Date(dateFin).toISOString()}`,
            {
                withCredentials: true,
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', `Consommations-${formatedDateDebut}-${formatedDateFin}`)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoadingExport(false))
    }

    useEffect(() => {
        let date = new Date()
        date.setMonth(date.getMonth() - 1)
        setFormatedateDebut(date.toLocaleDateString())
    }, [])

    return (
        <>
            <SideBar
                active="Conso"
                openElec />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Mes consommations d'électricité</h3>
                <div className="privatepages--separator"></div>
                <div className="conso--nonresponsivecontainer table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {sites.length > 0 ?
                                <>
                                    <div className="conso--containers">
                                        <div className="conso--containers__leftcolumn">
                                            <SearchBar
                                                id="conso"
                                                filterData={filterSites}
                                                setValue={setSearchValue}
                                                value={searchValue}
                                                additionnalFormClassName="searchbar--siteslist table--form"
                                            />
                                        </div>
                                        {filteredSites.length > 0 &&
                                            <>
                                                <div className="conso--containers__rightcolumns">
                                                    <span className="privatepages--title">Visualiser vos données</span>
                                                    <div className="conso--header__right">
                                                        <span className="conso--header__subtitle conso--header__subtitleleft">Cliquer ci-dessous pour voir vos données.</span>
                                                        <span className="conso--header__subtitle">Abonnez-vous ci-dessous pour avoir un suivi heure par heure</span>
                                                    </div>
                                                </div>
                                                <div className="conso--containers__rightcolumns">
                                                    <span className="privatepages--title">Exporter vos données</span>
                                                    <span className="sepa--form__import conso--choice">Choisissez une date de début et de fin</span>
                                                    <div className="conso--header__right">
                                                        <span className="conso--header__subtitle conso--header__periode">
                                                            Du <span className="conso--dateinput" onClick={() => {
                                                                setDateType("début")
                                                                setShowCalendar(true)
                                                            }}>
                                                                {formatedDateDebut}
                                                            </span> au <span className="conso--dateinput" onClick={() => {
                                                                setDateType("fin")
                                                                setShowCalendar(true)
                                                            }}>
                                                                {formatedDateFin}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {filteredSites.length > 0 ?
                                        <ConsoSiteTable
                                            setPage={setPage}
                                            page={page}
                                            rows={filteredSites}
                                            CheckAll={CheckAll}
                                            siteIdArray={siteIdArray}
                                            CheckSite={CheckSite}
                                            exportType={exportType}
                                            setExportType={setExportType}
                                            handleDataExport={handleDataExport}
                                            isDataLoadingExport={isDataLoadingExport}
                                        />
                                        :
                                        <span>Aucun site trouvé</span>
                                    }
                                </>
                                :
                                <span>Aucun site trouvé</span>
                            }
                        </>}
                </div>
            </div>
            {showCalendar &&
                <div className="informationpopup--background">
                    <div className="addsites--calendar__container conso--calendar__container">
                        <CloseIcon sx={{ cursor: "pointer", fontSize: "medium" }} onClick={() => {
                            setShowCalendar(false)
                            setDateType("")
                        }} />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                            <DateCalendar value={dateType === "début" ? dayjs(dateDebut) : dayjs(dateFin)}
                                sx={{
                                    backgroundColor: "white",
                                    zIndex: "100",
                                    position: "relative",
                                    width: "280px",
                                    "& .MuiPickersDay-root": {
                                        "&.Mui-selected": {
                                            backgroundColor: "#48AB4D",
                                        },
                                    },
                                    "&.MuiPickersDay-root.Mui-selected": {
                                        backgroundColor: "red",
                                    },
                                }}
                                onChange={(newValue) => {
                                    if (dateType === "début") {
                                        setateDebut(newValue)
                                        setFormatedateDebut(new Date(dayjs(newValue)).toLocaleDateString())
                                    }
                                    else {
                                        setDateFin(newValue)
                                        setFormatedDateFin(new Date(dayjs(newValue)).toLocaleDateString())
                                    }

                                }} />
                        </LocalizationProvider>
                    </div>
                </div>
            }
        </>
    )
}

export default Conso;